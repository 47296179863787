import { Component, OnInit, Input } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

// Redux
import { navigate, setTitle } from 'src/app/actions/core.actions';
import { setBrowserTitle } from 'src/app/actions/core.actions';
import { RootState } from 'src/app/reducers';

// models
import { Playlist } from 'src/app/models/playlist';
import { PlaylistSearchCondition } from 'src/app/models/playlist-search-condition';

// config
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';

// mapper
import { PlaylistSearchQueryParamsMapper } from '../../../mappers/playlist-search-query-params-mapper';

@Component({
  selector: 'app-playlists-search-result',
  templateUrl: './playlists-search-result.component.html',
  styleUrls: ['./playlists-search-result.component.scss']
})
export class PlaylistsSearchResultComponent implements OnInit {
  private LOG_SOURCE = this.constructor.name;
  private title = 'おすすめ問題セット検索結果';

  @Input() playlists$: Observable<Playlist[]>;
  @Input() searchCondition: PlaylistSearchCondition;

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
  }

  goPlaylistDetail(playlistId: string) {
    const queryParams = PlaylistSearchQueryParamsMapper.encodePlaylistSearchCondition(this.searchCondition);

    this.store.dispatch(
      navigate({
        url: RoutingPathResolver.resolvePlaylistDetail(playlistId),
        extras: { queryParams }
      })
    );
  }
}
