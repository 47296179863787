<app-wrapper size="lg">
  <app-content-row>
    <div>
      <app-alert-box [closeable]="false"
        >本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文</app-alert-box
      >
      <app-alert-box type="success"
        >本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文</app-alert-box
      >
      <app-alert-box type="info"
        >本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文</app-alert-box
      >
      <app-alert-box type="warning"
        >本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文</app-alert-box
      >
      <app-alert-box type="danger"
        >本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文</app-alert-box
      >
      <app-alert-box type="tips"
        >本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文</app-alert-box
      >
      <app-alert-box type="premium"
        >本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文</app-alert-box
      >
    </div>
  </app-content-row>
</app-wrapper>

<app-wrapper size="lg" isFitWithSmallScreen="true">
  <app-content-row>
    <app-card-content [showSeparator]="false">
      <app-wrapper size="fluid">
        <div #searchByCategoriesResult>
          <app-common-id-problem-list-item
            problemId="220100010101"
            [isPremiumMemberOnly]="false"
            subjectLabelStyleName="eigo"
            subjectName="英語"
            [level]="2"
            levelName="普通"
            universityName="テスト大学"
            year="2022"
            daimonNumber="01"
            problemSummary="ここに分野が入ります"
            [isAnswered]="true"
          ></app-common-id-problem-list-item>
          <app-common-id-problem-list-item
            problemId="220100010101"
            [isPremiumMemberOnly]="true"
            subjectLabelStyleName="sugaku"
            subjectName="数学"
            [level]="4"
            levelName="普通"
            universityName="テスト大学"
            year="2022"
            daimonNumber="01"
            problemSummary="ここに分野が入ります"
            [isAnswered]="false"
          ></app-common-id-problem-list-item>
        </div>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>

<div>
  <app-card-content>
    <app-wrapper size="lg">
      <app-content-row>
        <div class="text-sm flex gap-1">
          <app-label-level-with-stars [level]="1" levelLabelName="基礎"></app-label-level-with-stars>
          <app-label-level-with-stars [level]="2" levelLabelName="標準"></app-label-level-with-stars>
          <app-label-level-with-stars [level]="3" levelLabelName="やや難"></app-label-level-with-stars>
          <app-label-level-with-stars [level]="4" levelLabelName="難"></app-label-level-with-stars>
          <app-label-level-with-stars [level]="5" levelLabelName="難易度混在"></app-label-level-with-stars>
          <app-label-subject subjectLabelStyle="sugaku" subjectLabelName="数学"></app-label-subject>
          <app-label-subject subjectLabelStyle="kokugo" subjectLabelName="国語"></app-label-subject>
          <app-label-subject subjectLabelStyle="butsuri" subjectLabelName="物理"></app-label-subject>
          <app-label-subject subjectLabelStyle="kagaku" subjectLabelName="化学"></app-label-subject>
          <app-label-subject subjectLabelStyle="seibutsu" subjectLabelName="生物"></app-label-subject>
          <app-label-subject subjectLabelStyle="nihonshi" subjectLabelName="日本史"></app-label-subject>
          <app-label-subject subjectLabelStyle="sekaishi" subjectLabelName="世界史"></app-label-subject>
          <app-label-subject subjectLabelStyle="eigo" subjectLabelName="英語"></app-label-subject>
        </div>
      </app-content-row>
    </app-wrapper>
  </app-card-content>
</div>

<div class="p-6 flex flex-wrap gap-6">
  <button mat-flat-button (click)="showPurchaseConfirmModal()">購入確認モーダル</button>
  <button mat-flat-button (click)="showManabiIdModal()">学びIDモーダル</button>
  <button mat-flat-button (click)="gotoGoogleForm()">Googleフォームへ</button>
</div>

<div class="bg-white flex gap-4 p-6">
  <b>進捗</b>
  <div class="w-64" style="width:250px;"><app-progress-bar></app-progress-bar></div>
  <b class="text-primary">5 / 40問</b>
</div>

<div class="p-6 flex flex-wrap gap-6 bg-white">
  <app-button-toggle-saved-problem></app-button-toggle-saved-problem>
  <app-button-toggle-saved-problem showSpinner="true" isAnswerd="true"></app-button-toggle-saved-problem>
  <app-button-toggle-saved-problem isAnswerd="true"></app-button-toggle-saved-problem>
</div>
<div class="p-6 flex flex-wrap gap-6">
  <mat-slide-toggle [color]="'primary'">Slide me!</mat-slide-toggle>
  <app-slide-toggle [checked]="false" [disabled]="false">チェックなしで活性</app-slide-toggle>
  <app-slide-toggle [checked]="true" [disabled]="false">チェックありで活性</app-slide-toggle>
  <app-slide-toggle [checked]="false" [disabled]="true">チェックなしで非活性</app-slide-toggle>
  <app-slide-toggle [checked]="true" [disabled]="true">チェックありで活性</app-slide-toggle>
</div>
<div class="p-6 flex flex-wrap gap-6">
  <app-slide-toggle [particles]="true" [checked]="false" [disabled]="false"></app-slide-toggle>
  <app-slide-toggle [particles]="true" [checked]="false" [disabled]="false">パーティクルONでチェックなしで活性</app-slide-toggle>
  <app-slide-toggle [particles]="true" [checked]="true" [disabled]="false">パーティクルONでチェックありで活性</app-slide-toggle>
  <app-slide-toggle [particles]="true" [checked]="false" [disabled]="true">パーティクルONでチェックなしで非活性</app-slide-toggle>
  <app-slide-toggle [particles]="true" [checked]="true" [disabled]="true">パーティクルONでチェックありで活性</app-slide-toggle>
</div>

<button mat-flat-button (click)="showModal()">プレミアムモーダル</button>

<app-content-row>
  <app-wrapper size="lg" isFitWithSmallScreen="true">
    <app-button-more canSpinner="true"></app-button-more>
  </app-wrapper>
</app-content-row>

<app-content-row>
  <app-wrapper size="lg">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-6">
      <app-playlist-card-item
        title="GMARCH直前対策演習 英文読解ああああああああああああああ"
        subTitle="2022年版"
        playlistId="0100101"
        subjectId="02"
        [tags]="mockPlaylistTags"
      ></app-playlist-card-item>
      <app-playlist-card-item
        title="GMARCH直前対策演習 英文読解"
        subTitle="2022年版"
        playlistId="0100101"
        subjectId="04"
        [tags]="mockPlaylistTags"
        disabledAsNotPremium="true"
      ></app-playlist-card-item>
      <app-playlist-card-item
        title="GMARCH直前対策演習 英文読解"
        subTitle="2022年版"
        playlistId="0100101"
        subjectId="07"
        [tags]="mockPlaylistTags"
      ></app-playlist-card-item>
      <app-playlist-card-item
        title="GMARCH直前対策演習 英文読解"
        subTitle="2022年版"
        playlistId="0100101"
        subjectId="07"
        [tags]="mockPlaylistTags"
        disabledAsNotPremium="true"
      ></app-playlist-card-item>
      <app-playlist-card-item
        title="GMARCH直前対策演習 英文読解"
        subTitle="2022年版"
        playlistId="0100101"
        subjectId="07"
        [tags]="mockPlaylistTags"
      ></app-playlist-card-item>
    </div>
  </app-wrapper>
</app-content-row>

<app-content-row>
  <app-wrapper size="lg" isFitWithSmallScreen="true">
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <div class="flex items-center gap-x-2">
          <app-univ-cat-mark univCatType="国立" univCatPref="千葉" univType="1"></app-univ-cat-mark>
          <div role="heading" class="text-xl md:text-3xl"><a href="#" class="text-primary hover:underline">北海道大学</a></div>
        </div>
        <div class="card-content-head-util">
          <div class="pl-2">
            <app-button-toggle-univ-bookmark canSpinner="true" disabled></app-button-toggle-univ-bookmark>
            <app-button-toggle-univ-bookmark canSpinner="true"></app-button-toggle-univ-bookmark>
          </div>
        </div>
      </app-card-content-head>

      <app-wrapper size="fluid">
        <app-content-row>
          <app-header-with-listmark>
            <span class="text-base md:text-2xl">2019年度の過去問</span
            ><app-button-subject-name
              subject="プレミアム会員限定"
              isPremium="true"
              isArrow="true"
              class="text-xs ml-4 align-middle md:align-bottom"
            ></app-button-subject-name>
          </app-header-with-listmark>
        </app-content-row>
        <app-content-row>
          <div class="flex flex-col gap-4">
            <app-accordion-list-item [isExpanded]="true" [openableInPc]="false" [questionTitle]="'中が複数個のケース'">
              <div class="md:flex items-center">
                <div class="md:w-1/3 mb-4 md:mb-0">
                  <p class="mb-0 md:mr-4">
                    前期日程 試験日：2月25日
                  </p>
                </div>
                <div class="md:w-2/3">
                  <div class="grid gap-3 grid-cols-2 md:flex md:flex-wrap md:grid-cols-none">
                    <app-button-subject-name subject="英語" [isPremium]="true"></app-button-subject-name>
                    <app-button-subject-name subject="数学" [isPremium]="false"></app-button-subject-name>
                    <app-button-subject-name subject="国語" [isPremium]="true" [isVisited]="true"></app-button-subject-name>
                    <app-button-subject-name subject="物理" [isPremium]="false" [isVisited]="true"></app-button-subject-name>
                  </div>
                </div>
              </div>
              <hr class="my-4 border-t-2 border-gray-50" />
              <div class="md:flex items-center">
                <div class="md:w-1/3 mb-4 md:mb-0">
                  <p class="mb-0 md:mr-4">
                    前期日程 試験日：2月25日
                  </p>
                </div>
                <div class="md:w-2/3">
                  <div class="grid gap-3 grid-cols-2 md:flex md:flex-wrap md:grid-cols-none">
                    <app-button-subject-name subject="英語" isPremium="true"></app-button-subject-name>
                    <app-button-subject-name subject="国語" isPremium="true"></app-button-subject-name>
                  </div>
                </div>
              </div>
              <hr class="my-4 border-t-2 border-gray-50" />
              <div class="md:flex items-center">
                <div class="md:w-1/3 mb-4 md:mb-0">
                  <p class="mb-0 md:mr-4">
                    長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合長い場合
                  </p>
                </div>
                <div class="md:w-2/3">
                  <div class="grid gap-3 grid-cols-2 md:flex md:flex-wrap md:grid-cols-none">
                    <app-button-subject-name subject="英語" isPremium="true"></app-button-subject-name>
                    <app-button-subject-name subject="国語" isPremium="true"></app-button-subject-name>
                  </div>
                </div>
              </div>
            </app-accordion-list-item>
            <app-accordion-list-item [isExpanded]="true" [openableInPc]="false" [questionTitle]="'文学部'">
              <div class="md:flex items-center">
                <div class="md:w-1/3 mb-4 md:mb-0">
                  <p class="mb-0 md:mr-4">
                    前期日程 試験日：2月25日
                  </p>
                </div>
                <div class="grid gap-3 grid-cols-2 md:flex md:flex-wrap md:grid-cols-none">
                  <app-button-subject-name subject="英語" isPremium="true"></app-button-subject-name>
                  <app-button-subject-name subject="国語" isPremium="true"></app-button-subject-name>
                  <app-button-subject-name subject="数学" isPremium="true"></app-button-subject-name>
                  <app-button-subject-name subject="世界史" isPremium="true"></app-button-subject-name>
                  <app-button-subject-name subject="日本史" isPremium="true"></app-button-subject-name>
                  <app-button-subject-name subject="地理" isPremium="true"></app-button-subject-name>
                </div>
              </div>
            </app-accordion-list-item>
            <app-accordion-list-item [isExpanded]="true" [openableInPc]="false" [questionTitle]="'教育学部'">
              <div class="md:flex items-center">
                <div class="md:w-1/3 mb-4 md:mb-0">
                  前期日程 試験日：2月25日
                </div>
                <div class="grid gap-3 grid-cols-2 md:flex md:flex-wrap md:grid-cols-none">
                  <app-button-subject-name subject="英語" isPremium="true"></app-button-subject-name>
                  <app-button-subject-name subject="国語" isPremium="true"></app-button-subject-name>
                  <app-button-subject-name subject="数学" isPremium="true"></app-button-subject-name>
                </div>
              </div>
            </app-accordion-list-item>
          </div>
          <app-content-row>
            <div class="text-right md:text-base pb-2">
              <a href="#" class="text-primary decoration-solid font-bold hover:underline">他の年度・学部系統を詳しく</a>
              <mat-icon class="icon text-primary relative inset-y-1.5" aria-hidden="false" aria-label="">keyboard_arrow_right</mat-icon>
            </div>
          </app-content-row>
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-wrapper>
</app-content-row>

<app-content-row>
  <app-wrapper size="lg" isFitWithSmallScreen="true">
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <div class="flex items-center gap-x-2">
          <app-univ-cat-mark univCatType="国立" univCatPref="千葉" univType="1"></app-univ-cat-mark>
          <div role="heading" class="text-xl md:text-3xl"><a href="#" class="text-primary hover:underline">北海道大学</a></div>
        </div>
        <div class="card-content-head-util">
          <app-button-toggle-univ-bookmark canSpinner="true" isBookmarked="true" disabled></app-button-toggle-univ-bookmark>
          <app-button-toggle-univ-bookmark canSpinner="true" isBookmarked="true"></app-button-toggle-univ-bookmark>
        </div>
      </app-card-content-head>
      <app-wrapper size="fluid">
        <app-content-row>
          <app-header-with-listmark>
            <span class="text-base md:text-2xl">2019年度の過去問</span>
          </app-header-with-listmark>
        </app-content-row>
        <app-content-row>
          <app-content-no-data>
            <p>この年度に問題はありません</p>
          </app-content-no-data>
          <app-content-row>
            <div class="text-right md:text-base pb-2">
              <a href="#" class="text-primary decoration-solid font-bold hover:underline">他の年度・学部系統を詳しく</a>
              <mat-icon class="icon text-primary relative inset-y-1.5" aria-hidden="false" aria-label="">keyboard_arrow_right</mat-icon>
            </div>
          </app-content-row>
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-wrapper>
</app-content-row>

<app-button-subject-name subject="英語"></app-button-subject-name>
<app-button-subject-name subject="国語" isVisited="true"></app-button-subject-name>
<app-button-subject-name subject="数学" isPremium="true"></app-button-subject-name>

<app-content-row>
  <app-wrapper size="lg" isFitWithSmallScreen="true">
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <div class="flex items-center gap-x-2">
          <app-univ-cat-mark univCatType="国立" univCatPref="千葉" univType="1"></app-univ-cat-mark>
          <div role="heading" class="text-xl md:text-3xl">北海道大学</div>
        </div>
        <div class="card-content-head-util">
          <app-button-toggle-univ-bookmark canSpinner="true"></app-button-toggle-univ-bookmark>
        </div>
      </app-card-content-head>
      <app-wrapper size="fluid">
        <app-content-row>
          コンテンツ
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-wrapper>
</app-content-row>

<app-button-toggle-univ-bookmark canSpinner="true"></app-button-toggle-univ-bookmark>
<app-button-toggle-univ-bookmark canSpinner="true" isBookmarked="true"></app-button-toggle-univ-bookmark>

<app-wrapper size="lg">
  <app-content-row>
    <app-card-content>
      <app-wrapper size="fluid">
        <app-content-row>
          <app-accordion-list-item [isExpanded]="true" [openableInPc]="true" [questionTitle]="'isExpanded:true,openableInPc:trueの場合'">
            ロード時にコンテンツは見えている。いつでも開閉できる。
          </app-accordion-list-item>
        </app-content-row>
        <app-content-row>
          <app-accordion-list-item [isExpanded]="false" [openableInPc]="true" [questionTitle]="'isExpanded:false,openableInPc:trueの場合'">
            ロード時にコンテンツは隠れている。いつでも開閉できる。
          </app-accordion-list-item>
        </app-content-row>
        <app-content-row>
          <app-accordion-list-item [isExpanded]="true" [openableInPc]="false" [questionTitle]="'isExpanded:true,openableInPc:falseの場合'">
            ロード時にコンテンツは見えている。PCサイズでは閉じることができない。SPサイズでは開閉ができる。SPサイズで閉じた状態でPCサイズまで幅を広げたらコンテンツが現れる。
          </app-accordion-list-item>
        </app-content-row>
        <app-content-row>
          <app-accordion-list-item
            [isExpanded]="false"
            [openableInPc]="false"
            [questionTitle]="'isExpanded:false,openableInPc:falseの場合'"
          >
            PCサイズでロード時にコンテンツは見えているが、SPサイズでロードした際には隠れている。PCサイズでは閉じることができない。SPサイズでは開閉ができる。SPサイズで閉じた状態でPCサイズまで幅を広げたらコンテンツが現れる。
          </app-accordion-list-item>
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>

<!--app-dialog-basic>ダイアログ</app-dialog-basic-->
<app-provider-accordion [isExpanded]="true">中身中身中身中身中身中身中身</app-provider-accordion>
<app-header-with-listmark>ああああああ</app-header-with-listmark>

<div class="text-center">■■■■■■■■■■■■■■■■■■■■■■■■ 権限エラー ■■■■■■■■■■■■■■■■■■■■■■■■</div>

<app-wrapper size="xl">
  <app-card-content [showSeparator]="false">
    <app-wrapper size="fluid">
      <app-content-row>
        <div class="text-center py-36">
          <mat-icon
            class="text-primary"
            aria-hidden="true"
            fontSet="material-icons-outlined"
            style="width:80px;height:80px;font-size: 80px;"
            >sentiment_dissatisfied</mat-icon
          >
          <div class="mt-4">
            現在ご利用いただける契約プランがないか、<br />
            あなたがメンバー割当されていないため、この機能は使えません。<br />
            詳しくは所属の団体管理ユーザーに確認してください。
          </div>
          <div class="mt-4">
            現在ご利用いただける契約プランがないか、<br />
            あなたがメンバー割当されていないため、この機能は使えません。
          </div>
          <button class="mt-6" mat-flat-button color="primary">プラン・アカウント管理へ</button>
        </div>
      </app-content-row>
    </app-wrapper>
  </app-card-content>
</app-wrapper>

<div class="text-center">■■■■■■■■■■■■■■■■■■■■■■■■ プラン詳細 - メンバー割当管理 (全教科プラン) ■■■■■■■■■■■■■■■■■■■■■■■■</div>
<app-wrapper size="xl">
  <app-content-row>
    <app-button-location-back>●●●●● へ戻る</app-button-location-back>
  </app-content-row>
  <app-content-row>
    <app-breadcrumbs
      [breadcrumbs]="[
        { key: 'home', label: 'ホーム' },
        { key: 'second', label: 'セカンド' },
        { key: 'third', label: 'サード' }
      ]"
    ></app-breadcrumbs>
  </app-content-row>
  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon">outlined_flag</mat-icon>
        全教科プラン
        <div class="card-content-head-util">
          <div class="text-primary font-bold">2 / 20人</div>
        </div>
      </app-card-content-head>
      <app-card-content-nav
        [menus]="[
          {
            key: 'fact_check_black',
            icon: 'fact_check_black',
            fontSet: 'material-icons-outlined',
            label: 'プラン契約状況・履歴',
            isActive: false
          },
          {
            key: 'person_add',
            icon: 'person_add',
            label: 'メンバー割当管理',
            isActive: true
          }
        ]"
      ></app-card-content-nav>
      <app-wrapper size="fluid">
        <app-content-row>
          <div>
            残り <span class="font-bold text-primary">8メンバー</span> の割当が可能です。割り当てを行うと自動で招待メールが送信されます。
          </div>
          <div>
            メンバーが上限に達しました。これ以上はメンバーを割当することは出来ません。上限を増やす場合は、<a
              href="#"
              class="text-primary underline hover:no-underline"
              >お問い合わせフォーム</a
            >よりご連絡ください。
          </div>
          <div>
            ご契約のプランは終了しています。
          </div>
          <div>
            ご契約のプランは終了しています。 このプランの再開をご希望の場合は
            <a href="#" class="text-primary underline hover:no-underline">お問い合せフォーム</a>
            よりご連絡ください。
          </div>
        </app-content-row>

        <app-content-row>
          <app-section-basic>
            <app-section-basic-head>
              <mat-icon class="section-basic-head-icon">outlined_flag</mat-icon>
              英語 のメンバー割当
              <div class="section-basic-head-util">
                <div class="text-primary font-bold">2人</div>
              </div>
            </app-section-basic-head>
            <app-wrapper size="fluid">
              <app-content-row>
                <app-plan-members>
                  <app-plan-members-item [isDeletable]="true">山田 太郎</app-plan-members-item>
                  <app-plan-members-item [isDeletable]="true">田中 次郎</app-plan-members-item>
                  <app-plan-members-item [isDeletable]="false">消せない 名前</app-plan-members-item>
                  <app-plan-members-item [isDeletable]="false">鈴木 花子</app-plan-members-item>
                  <app-plan-members-add></app-plan-members-add>
                </app-plan-members>
              </app-content-row>
            </app-wrapper>
          </app-section-basic>
        </app-content-row>

        <app-content-row>
          <app-section-basic>
            <app-section-basic-head>
              <mat-icon class="section-basic-head-icon">outlined_flag</mat-icon>
              数学 のメンバー割当
              <div class="section-basic-head-util">
                <div class="text-primary font-bold">0人</div>
              </div>
            </app-section-basic-head>
            <app-wrapper size="fluid">
              <app-content-row>
                <app-plan-members>
                  <app-plan-members-add></app-plan-members-add>
                </app-plan-members>
              </app-content-row>
            </app-wrapper>
          </app-section-basic>
        </app-content-row>

        <app-content-row>
          <app-section-basic>
            <app-section-basic-head>
              <mat-icon class="section-basic-head-icon">outlined_flag</mat-icon>
              物理 のメンバー割当
              <div class="section-basic-head-util">
                <div class="text-primary font-bold">0人</div>
              </div>
            </app-section-basic-head>
            <app-wrapper size="fluid">
              <app-content-row>
                <app-plan-members>
                  <div class="pb-4 pr-4 text-sub">割り当てられたメンバーは居ません</div>
                </app-plan-members>
              </app-content-row>
            </app-wrapper>
          </app-section-basic>
        </app-content-row>

        <app-content-row>
          <div class="mt-4">
            <app-tips-content>
              <div>
                割当可能メンバーが表示されない場合は、
                <a href="#">プラン・アカウント管理-所属メンバー一覧</a>
                よりメンバーの登録を行ってください。
              </div>
              <div>
                メンバー割当は1ユーザー毎に1つのプランまでです。割当したいメンバーが表示されない場合は、そのメンバーに割り当てれているプランを解除してください。
              </div>
              <div>
                プラン登録に関する 「<a href="#">よくある質問</a>」 または、 「<a href="#">手順のマニュアル</a>」 もご参考ください。
              </div>
            </app-tips-content>
          </div>
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>

<div class="text-center">■■■■■■■■■■■■■■■■■■■■■■■■ プラン詳細 - メンバー割当管理 (英語プラン) ■■■■■■■■■■■■■■■■■■■■■■■■</div>
<app-wrapper size="xl">
  <app-content-row>
    <app-breadcrumbs
      [breadcrumbs]="[
        { key: 'home', label: 'ホーム' },
        { key: 'second', label: 'セカンド' },
        { key: 'third', label: 'サード' }
      ]"
    ></app-breadcrumbs>
  </app-content-row>
  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon">outlined_flag</mat-icon>
        英語プラン
        <div class="card-content-head-util">
          <div class="text-primary font-bold">2 / 10人</div>
        </div>
      </app-card-content-head>
      <app-card-content-nav
        [menus]="[
          {
            key: 'fact_check_black',
            icon: 'fact_check_black',
            fontSet: 'material-icons-outlined',
            label: 'プラン契約状況・履歴',
            isActive: false
          },
          {
            key: 'person_add',
            icon: 'person_add',
            label: 'メンバー割当管理',
            isActive: true
          }
        ]"
      ></app-card-content-nav>
      <app-wrapper size="fluid">
        <app-content-row>
          <div>
            残り <span class="font-bold text-primary">8メンバー</span> の割当が可能です。割り当てを行うと自動で招待メールが送信されます。
          </div>
          <div>
            メンバーが上限に達しました。これ以上はメンバーを割当することは出来ません。上限を増やす場合は、<a
              href="#"
              class="text-primary underline hover:no-underline"
              >お問い合わせフォーム</a
            >よりご連絡ください。
          </div>
          <div>
            ご契約のプランは終了しています。
          </div>
          <div>
            ご契約のプランは終了しています。 このプランの再開をご希望の場合は
            <a href="#" class="text-primary underline hover:no-underline">お問い合せフォーム</a>
            よりご連絡ください。
          </div>
        </app-content-row>

        <app-content-row>
          <app-content-no-data>
            <span>メンバーは未割当です</span>
            <button mat-flat-button color="primary" class="mt-4">
              <mat-icon class="">add_circle_outline</mat-icon>
              メンバーを割当
            </button>
          </app-content-no-data>

          <app-content-row>
            <app-section-basic>
              <app-section-basic-head>
                メンバー割当
                <div class="section-basic-head-util">
                  <div class="text-primary font-bold">0人</div>
                </div>
              </app-section-basic-head>
              <app-wrapper size="fluid">
                <app-content-row>
                  <app-plan-members>
                    <app-plan-members-item [isDeletable]="true">山田 太郎</app-plan-members-item>
                    <app-plan-members-item [isDeletable]="true">田中 次郎</app-plan-members-item>
                    <app-plan-members-item [isDeletable]="false">消せない 名前</app-plan-members-item>
                    <app-plan-members-item [isDeletable]="false">鈴木 花子</app-plan-members-item>
                    <app-plan-members-add></app-plan-members-add>
                    <div class="pb-4 pr-4 text-sub">割り当てられたメンバーは居ません</div>
                  </app-plan-members>
                </app-content-row>
              </app-wrapper>
            </app-section-basic>
          </app-content-row>

          <div class="mt-4">
            <app-tips-content>
              <div>
                割当可能メンバーが表示されない場合は、
                <a href="#">プラン・アカウント管理-所属メンバー一覧</a>
                よりメンバーの登録を行ってください。
              </div>
              <div>
                メンバー割当は1ユーザー毎に1つのプランまでです。割当したいメンバーが表示されない場合は、そのメンバーに割り当てられているプランを解除してください。
              </div>
              <div>
                プラン登録に関する 「<a href="#">よくある質問</a>」 または、 「<a href="#">手順のマニュアル</a>」 もご参考ください。
              </div>
            </app-tips-content>
          </div>
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>

<div class="text-center">■■■■■■■■■■■■■■■■■■■■■■■■ プラン詳細 - プラン契約状況・履歴 ■■■■■■■■■■■■■■■■■■■■■■■■</div>
<app-wrapper size="xl">
  <app-content-row>
    <app-breadcrumbs
      [breadcrumbs]="[
        { key: 'home', label: 'ホーム' },
        { key: 'second', label: 'セカンド' },
        { key: 'third', label: 'サード' }
      ]"
    ></app-breadcrumbs>
  </app-content-row>
  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon">outlined_flag</mat-icon>
        英語プラン
        <div class="card-content-head-util">
          <div class="text-primary font-bold">2 / 10人</div>
        </div>
      </app-card-content-head>
      <app-card-content-nav
        [menus]="[
          {
            key: 'fact_check_black',
            icon: 'fact_check_black',
            fontSet: 'material-icons-outlined',
            label: 'プラン契約状況・履歴',
            isActive: true
          },
          {
            key: 'person_add',
            icon: 'person_add',
            label: 'メンバー割当管理',
            isActive: false
          }
        ]"
      ></app-card-content-nav>
      <app-wrapper size="fluid">
        <app-content-row>
          <div class="flex">
            <div>
              <!-- テキストがあればここに -->
            </div>
            <span class="mx-auto"></span>
            <div>
              <button mat-flat-button color="primary">
                <mat-icon class="">add_circle_outline</mat-icon>
                プランの予約登録
              </button>
            </div>
          </div>
        </app-content-row>

        <app-content-row>
          <app-content-no-data>
            <span>プランがありません</span>
          </app-content-no-data>
          <div>
            <table mat-table [dataSource]="mockDataSource" class="w-full">
              <ng-container matColumnDef="planStatus">
                <th mat-header-cell *matHeaderCellDef>状況</th>
                <td mat-cell *matCellDef="let element">
                  <div>予約中</div>
                  <div class="font-bold text-primary">アクティブ</div>
                  <div class="text-nega">終了</div>
                </td>
              </ng-container>
              <ng-container matColumnDef="validityPeriod">
                <th mat-header-cell *matHeaderCellDef>有効期間</th>
                <td mat-cell *matCellDef="let element">
                  <div>2022/06/13 〜 2023/06/12 (開始まで364日)</div>
                  <div class="font-bold text-primary">2021/06/13 〜 2022/06/12 (終了まで364日)</div>
                  <div class="text-nega">2020/06/13 〜 2021/06/12</div>
                </td>
              </ng-container>
              <ng-container matColumnDef="memberNum">
                <th mat-header-cell *matHeaderCellDef>プラン人数</th>
                <td mat-cell *matCellDef="let element">
                  5人
                </td>
              </ng-container>
              <ng-container matColumnDef="memberList">
                <th mat-header-cell *matHeaderCellDef>終了時点での割当メンバー</th>
                <td mat-cell *matCellDef="let element" class="py-2 text-sm">
                  <!-- アクティブ時 -->
                  <div>
                    <span class="inline-block mr-2">山田 太郎,</span>
                    <span class="inline-block mr-2">田中 次郎</span>
                  </div>
                  <!-- 終了時 -->
                  <div class="text-nega">
                    <span class="inline-block mr-2">山田 太郎,</span>
                    <span class="inline-block mr-2">田中 次郎,</span>
                    <span class="inline-block mr-2">山田 太郎,</span>
                    <span class="inline-block mr-2">田中 次郎,</span>
                    <span class="inline-block mr-2">山田 太郎,</span>
                    <span class="inline-block mr-2">田中 次郎,</span>
                    <span class="inline-block mr-2">山田 太郎,</span>
                    <span class="inline-block mr-2">田中 次郎</span>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="text-right"></th>
                <td mat-cell *matCellDef="let element" class="text-right">
                  <button mat-flat-button class="mr-2 bg-gray-50 text-primary font-normal">予約取消</button>
                  <button mat-flat-button color="primary">編集</button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="mockDisplayedColumnsPlanHistory"></tr>
              <tr mat-row *matRowDef="let row; columns: mockDisplayedColumnsPlanHistory"></tr>
            </table>
          </div>
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>

<div class="text-center">■■■■■■■■■■■■■■■■■■■■■■■■ 所属メンバー一覧 ■■■■■■■■■■■■■■■■■■■■■■■■</div>
<app-wrapper size="xl">
  <app-content-row>
    <app-breadcrumbs
      [breadcrumbs]="[
        { key: 'home', label: 'ホーム' },
        { key: 'second', label: 'セカンド' },
        { key: 'third', label: 'サード' }
      ]"
    ></app-breadcrumbs>
  </app-content-row>
  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon">group</mat-icon>
        東京都立 旺文高等学校
      </app-card-content-head>
      <app-card-content-nav
        [menus]="[
          {
            key: 'outlined_flag',
            icon: 'outlined_flag',
            fontSet: 'material-icons-outlined',
            label: 'プラン一覧',
            isActive: false
          },
          {
            key: 'group',
            icon: 'group',
            label: '所属メンバー一覧',
            isActive: true
          }
        ]"
      ></app-card-content-nav>
      <app-wrapper size="fluid">
        <app-content-row>
          <div class="flex">
            <div>
              <p class="my-2 text-md">
                東京都立 旺文高等学校 に所属するメンバーの管理を行います
              </p>
            </div>
            <span class="mx-auto"></span>
            <div>
              <button mat-flat-button color="primary">
                <mat-icon class="">add_circle_outline</mat-icon>
                メンバーの登録
              </button>
            </div>
          </div>
        </app-content-row>

        <app-content-row>
          <app-content-no-data>
            <span>所属メンバーの登録がありません</span>
            <button mat-flat-button color="primary" class="mt-4">
              <mat-icon class="">add_circle_outline</mat-icon>
              メンバーの登録
            </button>
          </app-content-no-data>
          <div>
            <table mat-table [dataSource]="mockDataSource" class="w-full">
              <ng-container matColumnDef="memberName">
                <th mat-header-cell *matHeaderCellDef>氏名</th>
                <td mat-cell *matCellDef="let element" class="font-bold">
                  山田 太郎
                </td>
              </ng-container>
              <ng-container matColumnDef="memberNameKana">
                <th mat-header-cell *matHeaderCellDef>ふりがな</th>
                <td mat-cell *matCellDef="let element" class="text-sm">
                  やまだ たろう
                </td>
              </ng-container>
              <ng-container matColumnDef="mail">
                <th mat-header-cell *matHeaderCellDef>メールアドレス</th>
                <td mat-cell *matCellDef="let element" class="text-xs">
                  mailadredd@mail.com
                </td>
              </ng-container>
              <ng-container matColumnDef="memberType">
                <th mat-header-cell *matHeaderCellDef>メンバーの権限</th>
                <td mat-cell *matCellDef="let element">
                  <div class="flex items-center">
                    <div class="text-sm">
                      <div><app-label-with-icon iconName="check_circle">団体管理ユーザー</app-label-with-icon></div>
                      <div>一般ユーザー</div>
                    </div>
                    <div class="ml-2">
                      <button mat-flat-button color="primary" class="mat-button-mini">変更</button>
                    </div>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="assignedPlan">
                <th mat-header-cell *matHeaderCellDef>割当中のプラン</th>
                <td mat-cell *matCellDef="let element">
                  <a href="#">全教科プラン</a>
                  - 化学
                </td>
              </ng-container>
              <ng-container matColumnDef="exclusion">
                <th mat-header-cell *matHeaderCellDef>除外</th>
                <td mat-cell *matCellDef="let element" class="text-sm">
                  <a href="#">このメンバーを団体から除外</a>
                </td>
              </ng-container>
              <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef class="text-center">削除</th>
                <td mat-cell *matCellDef="let element" class="text-center">
                  <button mat-icon-button class="text-nega">
                    <mat-icon aria-hidden="false" aria-label="削除">delete_forever</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="mockDisplayedColumnsAffiliatedMember"></tr>
              <tr mat-row *matRowDef="let row; columns: mockDisplayedColumnsAffiliatedMember"></tr>
            </table>
          </div>
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>

<div class="text-center">■■■■■■■■■■■■■■■■■■■■■■■■ プラン一覧 ■■■■■■■■■■■■■■■■■■■■■■■■</div>
<app-wrapper size="xl">
  <app-content-row>
    <app-breadcrumbs
      [breadcrumbs]="[
        { key: 'home', label: 'ホーム' },
        { key: 'second', label: 'セカンド' },
        { key: 'third', label: 'サード' }
      ]"
    ></app-breadcrumbs>
  </app-content-row>
  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon">group</mat-icon>
        東京都立 旺文高等学校
      </app-card-content-head>
      <app-card-content-nav
        [menus]="[
          {
            key: 'outlined_flag',
            icon: 'outlined_flag',
            fontSet: 'material-icons-outlined',
            label: 'プラン一覧',
            isActive: true
          },
          {
            key: 'group',
            icon: 'group',
            label: '所属メンバー一覧',
            isActive: false
          }
        ]"
      ></app-card-content-nav>
      <app-wrapper size="fluid">
        <app-content-row>
          <div class="flex">
            <div>
              <p class="my-2 text-md">
                東京都立 旺文高等学校 のご契約プランの管理を行います
              </p>
            </div>
            <span class="mx-auto"></span>
            <div>
              <button mat-flat-button color="primary">
                <mat-icon class="">add_circle_outline</mat-icon>
                プランを登録
              </button>
            </div>
          </div>
        </app-content-row>

        <app-content-row>
          <app-content-no-data>
            <span>契約プランがまだありません</span>
            <button mat-flat-button color="primary" class="mt-4">
              <mat-icon class="">add_circle_outline</mat-icon>
              プランを登録
            </button>
          </app-content-no-data>
          <div>
            <table mat-table [dataSource]="mockDataSource" class="w-full">
              <ng-container matColumnDef="planName">
                <th mat-header-cell *matHeaderCellDef>プラン名</th>
                <td mat-cell *matCellDef="let element">
                  <app-label-with-icon iconName="outlined_flag" class="text-primary">
                    <span class="text-black font-bold">全教科プラン</span>
                  </app-label-with-icon>
                </td>
              </ng-container>
              <ng-container matColumnDef="memberNum">
                <th mat-header-cell *matHeaderCellDef>メンバー数</th>
                <td mat-cell *matCellDef="let element">2/10</td>
              </ng-container>
              <ng-container matColumnDef="memberNames">
                <th mat-header-cell *matHeaderCellDef>メンバー</th>
                <td mat-cell *matCellDef="let element" class="text-sm">
                  <div>
                    <span class="inline-block mr-2">山田 太郎,</span>
                    <span class="inline-block mr-2">田中 次郎,</span>
                    <span class="inline-block mr-2">鈴木 花子</span>
                    <span class="inline-block mr-2">... 他</span>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="contractStatus">
                <th mat-header-cell *matHeaderCellDef>契約状況</th>
                <td mat-cell *matCellDef="let element" class="text-sm">
                  <div class="text-primary">【アクティブ】 2021/06/13 〜 2022/06/12 (終了まで364日)</div>
                  <div class="text-black">【予約中】2022/06/13 〜 2023/06/12 (開始まで364日)</div>
                  <div class="text-gray-300">【終了】2020/06/13 〜 2021/06/12</div>
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="text-right whitespace-nowrap">
                  <button mat-flat-button class="mr-2 bg-gray-50 text-primary font-normal">契約履歴</button>
                  <button mat-flat-button color="primary">メンバー割当</button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="mockDisplayedColumnsPlan"></tr>
              <tr mat-row *matRowDef="let row; columns: mockDisplayedColumnsPlan"></tr>
            </table>
          </div>
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>

<div class="text-center">■■■■■■■■■■■■■■■■■■■■■■■■ ↓↓↓↓↓↓↓ パーツ集 ↓↓↓↓↓↓↓ ■■■■■■■■■■■■■■■■■■■■■■■■</div>
<app-univ-cat-mark univCatType="国立" univCatPref="千葉" univType="1"></app-univ-cat-mark>
<app-univ-cat-mark univCatType="公立" univCatPref="神奈川" univType="2"></app-univ-cat-mark>
<app-univ-cat-mark univCatType="私立" univCatPref="北海道" univType="3"></app-univ-cat-mark>
<app-univ-cat-mark univCatType="その他" univCatPref="千葉" univType="9"></app-univ-cat-mark>
<app-univ-cat-mark univCatType="その他" univCatPref="共テ" univType="9"></app-univ-cat-mark>

<app-wrapper size="xs"><div class="mt-6" style="background:gray;">size="xs"</div></app-wrapper>
<app-wrapper size="sm"><div class="mt-6" style="background:gray;">size="sm"</div></app-wrapper>
<app-wrapper size="md"><div class="mt-6" style="background:gray;">size="md"</div></app-wrapper>
<app-wrapper size="lg"><div class="mt-6" style="background:gray;">size="lg"</div></app-wrapper>
<app-wrapper size="xl"><div class="mt-6" style="background:gray;">size="xl"</div></app-wrapper>
<app-wrapper size="fluid"><div class="mt-6" style="background:gray;">size="fluid"</div></app-wrapper>
<app-wrapper size="lg">
  <app-content-row>
    <app-card-content [showSeparator]="false">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon">group</mat-icon>
        アカウント管理
      </app-card-content-head>

      <app-wrapper size="fluid">
        <app-content-row>
          コンテンツ
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>
<app-wrapper size="fluid">
  <app-content-row>
    <app-breadcrumbs
      [breadcrumbs]="[
        { key: 'home', label: 'ホーム' },
        { key: 'second', label: 'セカンド' },
        { key: 'third', label: 'サード' }
      ]"
    ></app-breadcrumbs>
  </app-content-row>
  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon">group</mat-icon>
        アカウント管理
        <div class="card-content-head-util">
          <app-link-with-arrow>リンクリンクリンク</app-link-with-arrow>
        </div>
      </app-card-content-head>
      <app-wrapper size="fluid">
        <app-content-row>
          <app-content-no-data>
            <span>◯◯◯◯がまだありません</span>
            <button mat-flat-button color="primary" class="mt-4">
              <mat-icon class="">add_circle_outline</mat-icon>
              プランを登録
            </button>
          </app-content-no-data>

          <app-content-no-data>
            <span>◯◯◯◯はありません</span>
          </app-content-no-data>

          <app-tips-content>注意やヒントを表示する</app-tips-content>
          <app-tips-content>
            <div class="font-bold">大見出し</div>
            <div>
              注意やヒントを表示する注意やヒントを表示する注意やヒントを表示する
              <a href="#">テキストリンク</a>
              注意やヒントを表示する注意やヒントを表示する注意やヒントを表示する
            </div>
          </app-tips-content>
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>
<app-wrapper size="lg">
  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon">group</mat-icon>
        アカウント管理
        <div class="card-content-head-util">
          <app-link-with-arrow>リンクリンクリンク</app-link-with-arrow>
        </div>
      </app-card-content-head>
      <app-card-content-nav
        [menus]="[
          {
            key: 'fact_check_black',
            icon: 'fact_check_black',
            fontSet: 'material-icons-outlined',
            label: 'プラン契約状況・履歴',
            isActive: false
          },
          {
            key: 'person_add',
            icon: 'person_add',
            label: 'メンバー割当管理',
            isActive: true
          }
        ]"
      ></app-card-content-nav>
      <app-wrapper size="fluid">
        <app-content-row>
          コンテンツ
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>
