<div @enter *ngIf="(loaded$ | async) === true">
  <!-- 戻るボタン -->
  <app-wrapper size="xl">
    <app-content-row>
      <app-button-location-back (backClick)="backToPreviousView()">一覧に戻る</app-button-location-back>
    </app-content-row>
  </app-wrapper>

  <app-wrapper size="xl" isFitWithSmallScreen="true">
    <!-- 問題概要 -->
    <app-card-content>
      <div *ngIf="playlist$ | async as playlist">
        <app-content-row>
          <app-wrapper size="fluid">
            <div class="flex flex-col md:flex-row items-start w-full gap-4 md:gap-6">
              <div class="md:mb-0 grow">
                <div class="py-2">
                  <div role="heading" class="flex flex-col gap-1 mb-6">
                    <div class="flex playlist-detail-title">
                      {{ playlist.seriesName }}
                    </div>
                    <div class="flex playlist-detail-title-sub">
                      {{ playlist.name }}
                    </div>
                  </div>
                  <div class="flex flex-wrap items-center gap-4 mb-4">
                    <div class="playlist-detail-subject-label">
                      <app-label-subject [subjectLabelStyle]="subjectLabelStyle" [subjectLabelName]="subjectLabelName"></app-label-subject>
                    </div>
                    <p class="playlist-detail-num">
                      <span
                        >問題数：<b>{{ playlist.problemCount }}問</b></span
                      >
                      <span *ngIf="playlist.duration !== ''"
                        >目安時間：<b>{{ playlist.duration }}</b></span
                      >
                    </p>
                  </div>
                  <p class="leading-6 playlist-detail-description" [innerHTML]="(playlist$ | async).description"></p>
                  <div class="mt-4 flex flex-wrap gap-2">
                    <app-playlist-tag-button *ngFor="let tag of (playlist$ | async).tags" (click)="clickTag(tag)">{{
                      tag
                    }}</app-playlist-tag-button>
                  </div>
                </div>
              </div>
              <div class="playlist-detail-image">
                <img
                  src="/assets/images/playlists-images/{{ playlist.playlistId }}/{{ playlist.playlistId }}@2x.jpg"
                  width="1280"
                  height="720"
                />
              </div>
            </div>

            <div class="text-right text-md mt-4">
              <div class="inline-block ml-4">公開日: {{ playlist.publishedDate || '-' }}</div>
              <div class="inline-block ml-4">更新日: {{ playlist.contentUpdatedDate || '-' }}</div>
              <div class="inline-block ml-4">年度: {{ playlist.yearRange || '-' }}</div>
              <div class="inline-block ml-4">選定者: {{ playlist.creator || '-' }}</div>
            </div>
          </app-wrapper>
        </app-content-row>
      </div>
    </app-card-content>

    <!-- 問題一覧 -->
    <ng-container *ngIf="englishPlaylistThemes$ | async as englishPlaylistThemes">
      <div class="playlist-items" *ngFor="let theme of englishPlaylistThemes">
        <app-content-row>
          <mat-accordion>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                <mat-panel-title>
                  <div class="panel-header">
                    <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }})</div>
                    <span class="spacer"></span>
                    <div class="icon-disabled" *ngIf="!this.isTrial && (playlist$ | async).enablePdfDownload">
                      <button mat-button color="primary" (click)="openThemePdf($event, theme)" [disabled]="theme.pdfDownloading === true">
                        <span class="button-label">まとめて閲覧</span>
                        <mat-icon color="primary" [ngClass]="{ 'icon-disabled': theme.pdfDownloading === true }">picture_as_pdf</mat-icon>
                      </button>
                      <div class="progress-24" *ngIf="theme.pdfDownloading === true">
                        <mat-spinner [diameter]="24"></mat-spinner>
                      </div>
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="-mx-6 md:mx-0">
                <app-english-problems
                  [readableEnglishPlaylistProblems]="theme.problems"
                  [hideLastBottomBorder]="true"
                  [playListId]="theme.id.slice(0, 7)"
                  [themeId]="theme.id"
                  [isTrial]="this.isTrial"
                ></app-english-problems>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </app-content-row>
      </div>
    </ng-container>

    <ng-container *ngIf="mathPlaylistThemes$ | async as mathPlaylistThemes">
      <div class="playlist-items" *ngFor="let theme of mathPlaylistThemes">
        <app-content-row>
          <mat-accordion>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                <div class="panel-header">
                  <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }})</div>
                  <span class="spacer"></span>
                  <div class="download-button" *ngIf="!this.isTrial && (playlist$ | async).enablePdfDownload">
                    <button mat-button color="primary" (click)="openThemePdf($event, theme)" [disabled]="theme.pdfDownloading === true">
                      <span class="button-label">まとめて閲覧</span>
                      <mat-icon color="primary" [ngClass]="{ 'icon-disabled': theme.pdfDownloading === true }">picture_as_pdf</mat-icon>
                    </button>
                    <div class="progress-24" *ngIf="theme.pdfDownloading === true">
                      <mat-spinner [diameter]="24"></mat-spinner>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel-header>
              <div class="-mx-6 md:mx-0">
                <app-science-problems
                  [readableSciencePlaylistProblems]="theme.problems"
                  [hideLastBottomBorder]="true"
                  [playListId]="theme.id.slice(0, 7)"
                  [themeId]="theme.id"
                  [isTrial]="this.isTrial"
                ></app-science-problems>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </app-content-row>
      </div>
    </ng-container>

    <ng-container *ngIf="nationalLanguagePlaylistThemes$ | async as nationalLanguagePlaylistThemes">
      <div class="playlist-items" *ngFor="let theme of nationalLanguagePlaylistThemes">
        <app-content-row>
          <mat-accordion>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                <div class="panel-header">
                  <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }})</div>
                  <span class="spacer"></span>
                  <div class="download-button" *ngIf="!this.isTrial && (playlist$ | async).enablePdfDownload">
                    <button mat-button color="primary" (click)="openThemePdf($event, theme)" [disabled]="theme.pdfDownloading === true">
                      <span class="button-label">まとめて閲覧</span>
                      <mat-icon color="primary" [ngClass]="{ 'icon-disabled': theme.pdfDownloading === true }">picture_as_pdf</mat-icon>
                    </button>
                    <div class="progress-24" *ngIf="theme.pdfDownloading === true">
                      <mat-spinner [diameter]="24"></mat-spinner>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel-header>
              <div class="-mx-6 md:mx-0">
                <app-national-language-problems
                  [readableNationalLanguagePlaylistProblems]="theme.problems"
                  [hideLastBottomBorder]="true"
                  [playListId]="theme.id.slice(0, 7)"
                  [themeId]="theme.id"
                  [isTrial]="this.isTrial"
                ></app-national-language-problems>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </app-content-row>
      </div>
    </ng-container>

    <ng-container *ngIf="physicsPlaylistThemes$ | async as physicsPlaylistThemes">
      <div class="playlist-items" *ngFor="let theme of physicsPlaylistThemes">
        <app-content-row>
          <mat-accordion>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                <div class="panel-header">
                  <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }})</div>
                  <span class="spacer"></span>
                  <div class="download-button" *ngIf="!this.isTrial && (playlist$ | async).enablePdfDownload">
                    <button mat-button color="primary" (click)="openThemePdf($event, theme)" [disabled]="theme.pdfDownloading === true">
                      <span class="button-label">まとめて閲覧</span>
                      <mat-icon color="primary" [ngClass]="{ 'icon-disabled': theme.pdfDownloading === true }">picture_as_pdf</mat-icon>
                    </button>
                    <div class="progress-24" *ngIf="theme.pdfDownloading === true">
                      <mat-spinner [diameter]="24"></mat-spinner>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel-header>

              <div class="-mx-6 md:mx-0">
                <app-science-problems
                  [readableSciencePlaylistProblems]="theme.problems"
                  [hideLastBottomBorder]="true"
                  [playListId]="theme.id.slice(0, 7)"
                  [themeId]="theme.id"
                  [isTrial]="this.isTrial"
                ></app-science-problems>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </app-content-row>
      </div>
    </ng-container>

    <ng-container *ngIf="chemistryPlaylistThemes$ | async as chemistryPlaylistThemes">
      <div class="playlist-items" *ngFor="let theme of chemistryPlaylistThemes">
        <app-content-row>
          <mat-accordion>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                <div class="panel-header">
                  <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }})</div>
                  <span class="spacer"></span>
                  <div class="download-button" *ngIf="!this.isTrial && (playlist$ | async).enablePdfDownload">
                    <button mat-button color="primary" (click)="openThemePdf($event, theme)" [disabled]="theme.pdfDownloading === true">
                      <span class="button-label">まとめて閲覧</span>
                      <mat-icon color="primary" [ngClass]="{ 'icon-disabled': theme.pdfDownloading === true }">picture_as_pdf</mat-icon>
                    </button>
                    <div class="progress-24" *ngIf="theme.pdfDownloading === true">
                      <mat-spinner [diameter]="24"></mat-spinner>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel-header>

              <div class="-mx-6 md:mx-0">
                <app-science-problems
                  [readableSciencePlaylistProblems]="theme.problems"
                  [hideLastBottomBorder]="true"
                  [playListId]="theme.id.slice(0, 7)"
                  [themeId]="theme.id"
                  [isTrial]="this.isTrial"
                ></app-science-problems>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </app-content-row>
      </div>
    </ng-container>

    <ng-container *ngIf="biologyPlaylistThemes$ | async as biologyPlaylistThemes">
      <div class="playlist-items" *ngFor="let theme of biologyPlaylistThemes">
        <app-content-row>
          <mat-accordion>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                <div class="panel-header">
                  <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }})</div>
                  <span class="spacer"></span>
                  <div class="download-button" *ngIf="!this.isTrial && (playlist$ | async).enablePdfDownload">
                    <button mat-button color="primary" (click)="openThemePdf($event, theme)" [disabled]="theme.pdfDownloading === true">
                      <span class="button-label">まとめて閲覧</span>
                      <mat-icon color="primary" [ngClass]="{ 'icon-disabled': theme.pdfDownloading === true }">picture_as_pdf</mat-icon>
                    </button>
                    <div class="progress-24" *ngIf="theme.pdfDownloading === true">
                      <mat-spinner [diameter]="24"></mat-spinner>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel-header>

              <div class="-mx-6 md:mx-0">
                <app-science-problems
                  [readableSciencePlaylistProblems]="theme.problems"
                  [hideLastBottomBorder]="true"
                  [playListId]="theme.id.slice(0, 7)"
                  [themeId]="theme.id"
                  [isTrial]="this.isTrial"
                ></app-science-problems>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </app-content-row>
      </div>
    </ng-container>

    <ng-container *ngIf="japaneseHistoryPlaylistThemes$ | async as japaneseHistoryPlaylistThemes">
      <div class="playlist-items" *ngFor="let theme of japaneseHistoryPlaylistThemes">
        <app-content-row>
          <mat-accordion>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                <div class="panel-header">
                  <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }})</div>
                  <span class="spacer"></span>
                  <div class="download-button" *ngIf="!this.isTrial && (playlist$ | async).enablePdfDownload">
                    <button mat-button color="primary" (click)="openThemePdf($event, theme)" [disabled]="theme.pdfDownloading === true">
                      <span class="button-label">まとめて閲覧</span>
                      <mat-icon color="primary" [ngClass]="{ 'icon-disabled': theme.pdfDownloading === true }">picture_as_pdf</mat-icon>
                    </button>
                    <div class="progress-24" *ngIf="theme.pdfDownloading === true">
                      <mat-spinner [diameter]="24"></mat-spinner>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel-header>
              <div class="-mx-6 md:mx-0">
                <app-history-problems
                  [readableHistoryPlaylistProblems]="theme.problems"
                  [hideLastBottomBorder]="true"
                  [playListId]="theme.id.slice(0, 7)"
                  [themeId]="theme.id"
                  [isTrial]="this.isTrial"
                ></app-history-problems>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </app-content-row>
      </div>
    </ng-container>

    <ng-container *ngIf="worldHistoryPlaylistThemes$ | async as worldHistoryPlaylistThemes">
      <div class="playlist-items" *ngFor="let theme of worldHistoryPlaylistThemes">
        <app-content-row>
          <mat-accordion>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                <div class="panel-header">
                  <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }})</div>
                  <span class="spacer"></span>
                  <div class="download-button" *ngIf="!this.isTrial && (playlist$ | async).enablePdfDownload">
                    <button mat-button color="primary" (click)="openThemePdf($event, theme)" [disabled]="theme.pdfDownloading === true">
                      <span class="button-label">まとめて閲覧</span>
                      <mat-icon color="primary" [ngClass]="{ 'icon-disabled': theme.pdfDownloading === true }">picture_as_pdf</mat-icon>
                    </button>
                    <div class="progress-24" *ngIf="theme.pdfDownloading === true">
                      <mat-spinner [diameter]="24"></mat-spinner>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel-header>
              <div class="-mx-6 md:mx-0">
                <app-history-problems
                  [readableHistoryPlaylistProblems]="theme.problems"
                  [hideLastBottomBorder]="true"
                  [playListId]="theme.id.slice(0, 7)"
                  [themeId]="theme.id"
                  [isTrial]="this.isTrial"
                ></app-history-problems>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </app-content-row>
      </div>
    </ng-container>

    <ng-container *ngIf="geographyPlaylistThemes$ | async as geographyPlaylistThemes">
      <div class="playlist-items" *ngFor="let theme of geographyPlaylistThemes">
        <app-content-row>
          <mat-accordion>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                <div class="panel-header">
                  <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }})</div>
                  <span class="spacer"></span>
                  <div class="download-button" *ngIf="!this.isTrial && (playlist$ | async).enablePdfDownload">
                    <button mat-button color="primary" (click)="openThemePdf($event, theme)" [disabled]="theme.pdfDownloading === true">
                      <span class="button-label">まとめて閲覧</span>
                      <mat-icon color="primary" [ngClass]="{ 'icon-disabled': theme.pdfDownloading === true }">picture_as_pdf</mat-icon>
                    </button>
                    <div class="progress-24" *ngIf="theme.pdfDownloading === true">
                      <mat-spinner [diameter]="24"></mat-spinner>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel-header>

              <div class="-mx-6 md:mx-0">
                <app-science-problems
                  [readableSciencePlaylistProblems]="theme.problems"
                  [hideLastBottomBorder]="true"
                  [playListId]="theme.id.slice(0, 7)"
                  [themeId]="theme.id"
                  [isTrial]="this.isTrial"
                ></app-science-problems>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </app-content-row>
      </div>
    </ng-container>

    <ng-container *ngIf="politicalEconomyPlaylistThemes$ | async as politicalEconomyPlaylistThemes">
      <div class="playlist-items" *ngFor="let theme of politicalEconomyPlaylistThemes">
        <app-content-row>
          <mat-accordion>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                <div class="panel-header">
                  <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }})</div>
                  <span class="spacer"></span>
                  <div class="download-button" *ngIf="!this.isTrial && (playlist$ | async).enablePdfDownload">
                    <button mat-button color="primary" (click)="openThemePdf($event, theme)" [disabled]="theme.pdfDownloading === true">
                      <span class="button-label">まとめて閲覧</span>
                      <mat-icon color="primary" [ngClass]="{ 'icon-disabled': theme.pdfDownloading === true }">picture_as_pdf</mat-icon>
                    </button>
                    <div class="progress-24" *ngIf="theme.pdfDownloading === true">
                      <mat-spinner [diameter]="24"></mat-spinner>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel-header>

              <div class="-mx-6 md:mx-0">
                <app-science-problems
                  [readableSciencePlaylistProblems]="theme.problems"
                  [hideLastBottomBorder]="true"
                  [playListId]="theme.id.slice(0, 7)"
                  [themeId]="theme.id"
                  [isTrial]="this.isTrial"
                ></app-science-problems>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </app-content-row>
      </div>
    </ng-container>
  </app-wrapper>
</div>

<!-- ローディングアイコン -->
<div class="progress-48" *ngIf="(loaded$ | async) === false">
  <mat-spinner [diameter]="48"></mat-spinner>
</div>
