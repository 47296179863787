<!-- ▼ 印刷時のみ表示 ここから -->
<div class="playlist-problem-detail-show-print-only-wrapper" data-print-only *ngIf="(problem$ | async)?.examPaper">
  <app-problem-detail-frame-for-print
    [menuType]="menuType$ | async"
    [readableScienceProblems]="readableScienceProblems$ | async"
    [readableEnglishProblems]="readableEnglishProblems$ | async"
    [readableNationalLanguageProblems]="readableNationalLanguageProblems$ | async"
    [readableJapaneseHistoryProblems]="readableJapaneseHistoryProblems$ | async"
    [readableWorldHistoryProblems]="readableWorldHistoryProblems$ | async"
    [paperMode]="paperMode$ | async"
    [trialMode]="trialMode$ | async"
    [subjectId]="subjectId$ | async"
    [isBToC]="true"
  ></app-problem-detail-frame-for-print>
</div>
<!-- △ 印刷時のみ表示 ここまで -->

<!-- ▼ 印刷時は消す ここから -->
<!-- 存在しない問題の場合は表示できない旨を表示 -->
<div *ngIf="isPreconditionError">
  <div class="text-center text-nega">
    <app-problem-detail-no-external-data></app-problem-detail-no-external-data>
  </div>
</div>

<!-- 存在する問題の場合 -->
<div *ngIf="!isPreconditionError" data-no-print>
  <div *ngIf="readableProblem$ | async">
    <div [@fadeInOut]="(searching$ | async) === false && (loadingReadableProblems$ | async) === false">
      <app-wrapper size="fluid" isFitWithSmallScreen="true" class="playlist-problem-detail-meta">
        <app-content-row>
          <div class="flex items-center gap-x-2" *ngIf="(fromAnsweredProblems$ | async) === false">
            <app-button-location-back (backClick)="goBack()">検索結果へ戻る</app-button-location-back>
          </div>
        </app-content-row>

        <app-card-content>
          <div class="playlist-problem-detail-heading">
            <div class="flex items-center">
              <button
                mat-flat-button
                color="primary"
                [disabled]="(isFirstProblem$ | async) || (this.isSavingAnswered$ | async)"
                (click)="showPreviousProblem()"
                *ngIf="(fromAnsweredProblems$ | async) === false"
              >
                <mat-icon aria-hidden="true">arrow_back</mat-icon> 前の問題
              </button>
              <div class="playlist-problem-detail-title">
                <div role="heading" class=" hidden md:block">
                  {{ (readableProblem$ | async).university }} {{ (readableProblem$ | async).year }}年度
                  {{ (readableProblem$ | async).problemNumber }}
                  <button (click)="showPaperInformation()" [disabled]="(canDisplay$ | async) === false">
                    <mat-icon
                      matTooltip="この問題の情報"
                      matTooltipPosition="above"
                      class="text-primary material-icons-outlined overflow-visible"
                      >info</mat-icon
                    >
                  </button>
                </div>
              </div>
              <button
                mat-flat-button
                color="primary"
                [disabled]="(isLastProblem$ | async) || (this.isSavingAnswered$ | async)"
                (click)="showNextProblem()"
                *ngIf="(fromAnsweredProblems$ | async) === false"
              >
                次の問題 <mat-icon aria-hidden="true">arrow_forward</mat-icon>
              </button>
            </div>
          </div>
          <hr class="block md:hidden" />
          <app-wrapper size="fluid" class="block md:hidden">
            <app-content-row>
              <div class="flex items-center">
                <div role="heading" class="text-base font-bold grow">
                  {{ (readableProblem$ | async).university }} {{ (readableProblem$ | async).year }}年度
                  {{ (readableProblem$ | async).problemNumber }}
                </div>
                <button (click)="showPaperInformation()" [disabled]="(canDisplay$ | async) === false">
                  <mat-icon matTooltip="この問題の情報" matTooltipPosition="above" class="text-primary material-icons-outlined"
                    >info</mat-icon
                  >
                </button>
              </div>
            </app-content-row>
          </app-wrapper>
        </app-card-content>
      </app-wrapper>

      <app-content-row *ngIf="(problem$ | async)?.hasExternalData && (isVisible$ | async); else noExternalData">
        <app-wrapper size="fluid" isFitWithSmallScreen="true">
          <!-- ▼ テスト表示領域 -->
          <app-problem-detail-frame
            [readableScienceProblems]="readableScienceProblems$ | async"
            [readableEnglishProblems]="readableEnglishProblems$ | async"
            [readableNationalLanguageProblems]="readableNationalLanguageProblems$ | async"
            [readableJapaneseHistoryProblems]="readableJapaneseHistoryProblems$ | async"
            [readableWorldHistoryProblems]="readableWorldHistoryProblems$ | async"
            [paperMode]="paperMode$ | async"
            [canDisplay]="canDisplay$ | async"
            [trialMode]="trialMode$ | async"
            [hidePaperProblemBtn]="hidePaperProblemBtn"
            [isAnswered]="isAnswered$ | async"
            [showComment]="false"
            [showSaveAnsweredBtn]="true"
            [disabledSaveAnsweredBtn]="(isPremiumUser$ | async) === false && (canDisplay$ | async) === false"
            [subjectId]="subjectId$ | async"
            (menuTypeChangeHandler)="changeMenuType($event)"
            (printClickHandler)="showPrint()"
            (saveAnsweredClick)="saveAnswered()"
          ></app-problem-detail-frame>
          <!-- △ テスト表示領域 -->
        </app-wrapper>
      </app-content-row>
    </div>

    <div class="progress-48" [@fadeInOut]="(searching$ | async) || (loadingReadableProblems$ | async)">
      <mat-spinner [diameter]="48"></mat-spinner>
    </div>

    <ng-template #noExternalData>
      <div class="text-center text-nega" *ngIf="(searching$ | async) === false && (loadingReadableProblems$ | async) === false">
        <app-problem-detail-no-external-data></app-problem-detail-no-external-data>
      </div>
    </ng-template>
  </div>
</div>

<div *ngIf="(searching$ | async) || (loadingReadableProblems$ | async)">
  <div class="progress-48">
    <mat-spinner [diameter]="48"></mat-spinner>
  </div>
</div>
<!-- △ 印刷時は消す ここまで -->
