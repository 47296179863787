export const PROD_APP_NAME = '';
export const STAGING_APP_NAME = '【STG】';
export const DEBUG_APP_NAME = '【開発】';
export const COMMON_PAGE_TITLE = '旺文社 入試正解デジタル for School';

export const META_VIEWPORT_LP = { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1.0' };
// export const META_VIEWPORT_OTHER = { name: 'viewport', content: 'width=1200px' };
export const META_VIEWPORT_OTHER = { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1.0' };

export const SNACK_BAR_DISPLAY_DURATION = 4000; // ms

export const RESPONSIVE_WIDTH = 900;
export const PASSWORD_RESET_DIALOG_WIDTH = '500px';
export const ADD_ACCOUNT_DIALOG_ADMIN_HEIGHT = '700px';
export const ADD_ACCOUNT_DIALOG_HEIGHT = '740px';
export const ADD_ACCOUNT_DIALOG_WIDTH = '600px';
export const EDIT_ACCOUNT_DIALOG_ADMIN_HEIGHT = '700px';
export const EDIT_ACCOUNT_DIALOG_HEIGHT = '440px';
export const EDIT_ACCOUNT_DIALOG_WIDTH = '600px';
export const EDIT_EMAIL_ACCOUNT_DIALOG_WIDTH = '600px';
export const DELETE_ACCOUNT_DIALOG_WIDTH = '500px';
export const DELETE_BOOKMARK_DIALOG_WIDTH = '500px';
export const CHANGE_ACTIVE_ACCOUNT_DIALOG_WIDTH = '500px';
export const CHANGE_ORGANIZATION_AUTHORITY_DIALOG_WIDTH = '500px';
export const SELECT_CATEGORY_DIALOG_HEIGHT = '600px';
export const SELECT_CATEGORY_DIALOG_WIDTH = '800px';
export const SELECT_DETAILED_SEARCH_DIALOG_HEIGHT = '700px';
export const SELECT_DETAILED_SEARCH_DIALOG_WIDTH = '800px';
export const PRINT_CONFIRM_DIALOG_WIDTH = '400px';
export const ADD_PLAN_DIALOG_HEIGHT = '550px';
export const ADD_PLAN_DIALOG_WIDTH = '600px';
export const EDIT_PLAN_DIALOG_HEIGHT = '550px';
export const EDIT_PLAN_DIALOG_WIDTH = '600px';
export const DELETE_PLAN_DIALOG_WIDTH = '500px';
export const PLAN_ASSIGN_MEMBER_DIALOG_HEIGHT = '530px';
export const PLAN_ASSIGN_MEMBER_DIALOG_WIDTH = '600px';
export const PLAN_UNASSIGN_MEMBER_DIALOG_HEIGHT = '200px';
export const PLAN_UNASSIGN_MEMBER_DIALOG_WIDTH = '600px';
export const ADD_JUKU_DIALOG_HEIGHT = '600px';
export const ADD_JUKU_DIALOG_WIDTH = '600px';
export const EDIT_JUKU_DIALOG_HEIGHT = '600px';
export const EDIT_JUKU_DIALOG_WIDTH = '600px';
export const DELETE_JUKU_DIALOG_WIDTH = '500px';
export const BULK_MAIL_DIALOG_HEIGHT = '350px';
export const BULK_MAIL_DIALOG_WIDTH = '600px';

export const COMMON_ID_PURCHASE_DIALOG_HEIGHT = '400px';
export const COMMON_ID_PURCHASE_DIALOG_WIDTH = '400px';

export const ACCOUNT_DIALOG_TYPE_ACCOUNTS = 'accounts';
export const ACCOUNT_DIALOG_TYPE_MEMBERS = 'members';

/** styles.scss と同期 */
export const SNACK_BAR_ZERO_PADDING_PANEL_CLASS = 'snack-bar-zero-padding-panel-class';
export const DIALOG_ZERO_PADDING_PANEL_CLASS = 'dialog-zero-padding-panel-class';

export const SEARCH_YEARS = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];
export const BIOLOGY_SEARCH_YEARS = [2018, 2019, 2020, 2021, 2022, 2023, 2024];
export const JAPANESE_HISTORY_SEARCH_YEARS = [2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];
export const GEOGRAPHY_SEARCH_YEARS = [2020, 2021, 2022, 2023, 2024];
export const POLITICAL_ECONOMY_SEARCH_YEARS = [2020, 2021, 2022, 2023, 2024];

export const BUNYA_KENSAKU_DEFAULT_YEAR = '指定なし';

export const SEARCH_RESULTS_PER_PAGE = 20;
export const BOOKMARK_RESULTS_PER_PAGE = 20;

/** 奇数で設定してください */
export const PAGINATION_WINDOW_SIZE = 5;

// 印刷できる最大件数
export const MAX_PRINTABLE_COUNT = 300;

export enum SubjectId {
  ENGLISH = '01',
  MATH = '02',
  NATIONAL_LANGUAGE = '03',
  PHYSICS = '04',
  CHEMISTRY = '05',
  BIOLOGY = '06',
  JAPANESE_HISTORY = '07',
  WORLD_HISTORY = '08',
  GEOGRAPHY = '09',
  POLITICAL_ECONOMY = '10'
}

export enum PlanSubjectId {
  ENGLISH = '01',
  MATH = '02',
  NATIONAL_LANGUAGE = '03',
  PHYSICS = '04',
  CHEMISTRY = '05',
  BIOLOGY = '06',
  JAPANESE_HISTORY = '07',
  WORLD_HISTORY = '08',
  GEOGRAPHY = '09',
  POLITICAL_ECONOMY = '10',
  TRIAL = '98',
  ALL = '99'
}

export const SUBJECT_NAMES = {
  [SubjectId.ENGLISH]: '英語',
  [SubjectId.MATH]: '数学',
  [SubjectId.NATIONAL_LANGUAGE]: '国語',
  [SubjectId.PHYSICS]: '物理',
  [SubjectId.CHEMISTRY]: '化学',
  [SubjectId.BIOLOGY]: '生物',
  [SubjectId.JAPANESE_HISTORY]: '日本史',
  [SubjectId.WORLD_HISTORY]: '世界史',
  [SubjectId.GEOGRAPHY]: '地理',
  [SubjectId.POLITICAL_ECONOMY]: '政治・経済'
};

export const PLAN_NAMES = {
  [PlanSubjectId.ENGLISH]: '英語プラン',
  [PlanSubjectId.MATH]: '数学プラン',
  [PlanSubjectId.NATIONAL_LANGUAGE]: '国語プラン',
  [PlanSubjectId.PHYSICS]: '物理プラン',
  [PlanSubjectId.CHEMISTRY]: '化学プラン',
  [PlanSubjectId.BIOLOGY]: '生物プラン',
  [PlanSubjectId.JAPANESE_HISTORY]: '日本史プラン',
  [PlanSubjectId.WORLD_HISTORY]: '世界史プラン',
  [PlanSubjectId.GEOGRAPHY]: '地理プラン',
  [PlanSubjectId.POLITICAL_ECONOMY]: '政治・経済プラン',
  [PlanSubjectId.TRIAL]: '体験プラン',
  [PlanSubjectId.ALL]: '全科目プラン'
};

export const PLANS_SORT: string[] = [
  PlanSubjectId.ALL,
  PlanSubjectId.ENGLISH,
  PlanSubjectId.MATH,
  PlanSubjectId.NATIONAL_LANGUAGE,
  PlanSubjectId.PHYSICS,
  PlanSubjectId.CHEMISTRY,
  PlanSubjectId.BIOLOGY,
  PlanSubjectId.JAPANESE_HISTORY,
  PlanSubjectId.WORLD_HISTORY,
  PlanSubjectId.GEOGRAPHY,
  PlanSubjectId.POLITICAL_ECONOMY,
  PlanSubjectId.TRIAL
];

export const SCIENCE_IDS: string[] = [
  SubjectId.MATH,
  SubjectId.PHYSICS,
  SubjectId.CHEMISTRY,
  SubjectId.BIOLOGY,
  SubjectId.GEOGRAPHY,
  SubjectId.POLITICAL_ECONOMY
];
export const HISTORY_IDS: string[] = [SubjectId.JAPANESE_HISTORY, SubjectId.WORLD_HISTORY];

export enum PlanStatuses {
  UNASSIGNED = '01',
  ENDED = '02',
  ACTIVE = '03',
  RESERVE = '04'
}

export const PLAN_STATUS_NAMES = {
  [PlanStatuses.UNASSIGNED]: '未割当',
  [PlanStatuses.ENDED]: '【終了】',
  [PlanStatuses.ACTIVE]: '【アクティブ】',
  [PlanStatuses.RESERVE]: '【予約】'
};

export const USER_AUTHORITY_OBUNSHA_ID = 1;
export const USER_AUTHORITY_ORGANIZATION_MASTER_ID = 2;
export const USER_AUTHORITY_ORGANIZATION_USER_ID = 3;

export const USER_AUTHORITIES = [
  { 'id': USER_AUTHORITY_OBUNSHA_ID, 'name': 'Admin' },
  { 'id': USER_AUTHORITY_ORGANIZATION_MASTER_ID, 'name': '団体管理ユーザー' },
  { 'id': USER_AUTHORITY_ORGANIZATION_USER_ID, 'name': '一般ユーザー' }
];

export const SCHOOL_ORGANIZATION_ID = 's9999';
export const JUKU_ORGANIZATION_ID = 'j9999';
export const OTHER_ORGANIZATION_ID = 'e9999';
export const OBUNSHA_ORGANIZATION_ID = 'o9999';
export const TRY_ORGANIZATION_ID = 't9999';
export const SCHOOL_ORGANIZATION_NAME = '学校';
export const JUKU_ORGANIZATION_NAME = '塾／予備校';
export const OTHER_ORGANIZATION_NAME = 'その他';
export const OBUNSHA_ORGANIZATION_NAME = '旺文社内';
export const TRY_ORGANIZATION_NAME = 'トライ';

export const OBUNSHA_NAME = '旺文社';
export const OTHER_SCHOOL_ID = '99999';
export const OTHER_JUKU_CODE = 'J-99999';
export const PLAN_AVAILABLE_ORGANIZATION_IDS = [SCHOOL_ORGANIZATION_ID, JUKU_ORGANIZATION_ID];
export const OBUNSHA_DEFAULT_MAIL_SENDER_NAME = '入試正解デジタル担当';
export const DEFAULT_MAIL_RECEIVER_ID = 'DEFAULT-MAIL-RECEIVER-ID';

export const ORGANIZATIONS = [
  {
    id: SCHOOL_ORGANIZATION_ID,
    name: SCHOOL_ORGANIZATION_NAME
  },
  {
    id: JUKU_ORGANIZATION_ID,
    name: JUKU_ORGANIZATION_NAME
  },
  {
    id: OTHER_ORGANIZATION_ID,
    name: OTHER_ORGANIZATION_NAME
  },
  {
    id: OBUNSHA_ORGANIZATION_ID,
    name: OBUNSHA_ORGANIZATION_NAME
  },
  {
    id: TRY_ORGANIZATION_ID,
    name: TRY_ORGANIZATION_NAME
  }
];

export const PREFECTURES = [
  { 'id': '', 'name': '未選択' },
  { 'id': '01', 'name': '北海道' },
  { 'id': '02', 'name': '青森県' },
  { 'id': '03', 'name': '岩手県' },
  { 'id': '04', 'name': '宮城県' },
  { 'id': '05', 'name': '秋田県' },
  { 'id': '06', 'name': '山形県' },
  { 'id': '07', 'name': '福島県' },
  { 'id': '08', 'name': '茨城県' },
  { 'id': '09', 'name': '栃木県' },
  { 'id': '10', 'name': '群馬県' },
  { 'id': '11', 'name': '埼玉県' },
  { 'id': '12', 'name': '千葉県' },
  { 'id': '13', 'name': '東京都' },
  { 'id': '14', 'name': '神奈川県' },
  { 'id': '15', 'name': '新潟県' },
  { 'id': '16', 'name': '富山県' },
  { 'id': '17', 'name': '石川県' },
  { 'id': '18', 'name': '福井県' },
  { 'id': '19', 'name': '山梨県' },
  { 'id': '20', 'name': '長野県' },
  { 'id': '21', 'name': '岐阜県' },
  { 'id': '22', 'name': '静岡県' },
  { 'id': '23', 'name': '愛知県' },
  { 'id': '24', 'name': '三重県' },
  { 'id': '25', 'name': '滋賀県' },
  { 'id': '26', 'name': '京都府' },
  { 'id': '27', 'name': '大阪府' },
  { 'id': '28', 'name': '兵庫県' },
  { 'id': '29', 'name': '奈良県' },
  { 'id': '30', 'name': '和歌山県' },
  { 'id': '31', 'name': '鳥取県' },
  { 'id': '32', 'name': '島根県' },
  { 'id': '33', 'name': '岡山県' },
  { 'id': '34', 'name': '広島県' },
  { 'id': '35', 'name': '山口県' },
  { 'id': '36', 'name': '徳島県' },
  { 'id': '37', 'name': '香川県' },
  { 'id': '38', 'name': '愛媛県' },
  { 'id': '39', 'name': '高知県' },
  { 'id': '40', 'name': '福岡県' },
  { 'id': '41', 'name': '佐賀県' },
  { 'id': '42', 'name': '長崎県' },
  { 'id': '43', 'name': '熊本県' },
  { 'id': '44', 'name': '大分県' },
  { 'id': '45', 'name': '宮崎県' },
  { 'id': '46', 'name': '鹿児島県' },
  { 'id': '47', 'name': '沖縄県' },
  { 'id': '99', 'name': 'その他' }
];

export enum UNIVERSITY_TYPE {
  KOKURITSU = '1',
  KOURITSU = '2',
  SHIRITSU = '3',
  OTHER = '9'
}

/** オリジナル問題用の大学コード */
export const ORIGINAL_UNIVERSITY_IDS = ['D9900'];

/** 日本史、世界史で利用 */
export const THEME_HISTORY = 'テーマ史';
export const FIELD_MIX = '分野融合';

export const STATIC_DATA_CACHE_DAYS = 1;

export const NO_DISPLAY_LEVELS = [5];
export const NO_DISPLAY_DEPARTMENT_CATEGORY_IDS = ['99'];
export const NO_DISPLAY_UNIVERSITY_IDS = [...ORIGINAL_UNIVERSITY_IDS];
export const NO_DISPLAY_MATH_CATEGORY_PREFIX = '7';
export const DISPLAY_UNIVERSITY_TYPES = [
  UNIVERSITY_TYPE.KOKURITSU.toString(),
  UNIVERSITY_TYPE.KOURITSU.toString(),
  UNIVERSITY_TYPE.SHIRITSU.toString(),
  UNIVERSITY_TYPE.OTHER.toString()
];

export const READABLE_DATA_NONE = '-';

export enum UnsubscribeTarget {
  ON_SIGN_OUT = 'on-sign-out',
  WATCH_USERS = 'watch-users'
}

export const KEYBOARD_INPUT_DEBOUNCE_TIME = 1000; // ms

export const ENGLISH_LOWEST_WORD_COUNT = 0;
export const ENGLISH_LOWEST_WORD_COUNT_DISPLAY_NAME = '下限なし';
export const ENGLISH_HIGHEST_WORD_COUNT = 9999;
export const ENGLISH_HIGHEST_WORD_COUNT_DISPLAY_NAME = '上限なし';

export const NATIONAL_LANGUAGE_LOWEST_WORD_COUNT_STRING = '0';
export const NATIONAL_LANGUAGE_LOWEST_WORD_COUNT_DISPLAY_NAME = '下限なし';
export const NATIONAL_LANGUAGE_HIGHEST_WORD_COUNT_STRING = '9999';
export const NATIONAL_LANGUAGE_HIGHEST_WORD_COUNT_DISPLAY_NAME = '上限なし';

export const CATEGORY_DELIMITER = '-';

export enum SortType {
  D_CODE = 'D_CODE',
  UNIVERSITY_NAME = 'UNIVERSITY_NAME',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
  LEVEL_ASC = 'LEVEL_ASC',
  LEVEL_DESC = 'LEVEL_DESC'
}

export enum SortTypeDisplayName {
  D_CODE = '指定なし',
  UNIVERSITY_NAME = '大学名（五十音順）',
  YEAR_ASC = '年度（古い順）',
  YEAR_DESC = '年度（新しい順）',
  LEVEL_ASC = '難易度（基礎が上）',
  LEVEL_DESC = '難易度（難が上）'
}

export enum BookmarkSortType {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export enum BookmarkSortTypeDisplayName {
  CREATED_AT_ASC = '登録日(古い順)',
  CREATED_AT_DESC = '登録日(新しい順)'
}

export enum PlaylistSortType {
  PUBLISHED_DATE = 'PUBLISHED_DATE',
  PLAYLIST_ID_ASC = 'PLAYLIST_ID_ASC',
  PLAYLIST_ID_DESC = 'PLAYLIST_ID_DESC'
}

export enum PlaylistSortTypeDisplayName {
  PUBLISHED_DATE = '更新日時（新しい順）',
  PLAYLIST_ID_ASC = 'シリーズ順（ID昇順）',
  PLAYLIST_ID_DESC = 'シリーズ順（ID降順）'
}

export const ENGLISH_SEARCH_LOG_FILE_PREFIX = 'english-search-log_';
export const MATH_SEARCH_LOG_FILE_PREFIX = 'math-search-log_';
export const NATIONAL_LANGUAGE_SEARCH_LOG_FILE_PREFIX = 'national-language-search-log_';
export const PHYSICS_SEARCH_LOG_FILE_PREFIX = 'physics-search-log_';
export const CHEMISTRY_SEARCH_LOG_FILE_PREFIX = 'chemistry-search-log_';
export const BIOLOGY_SEARCH_LOG_FILE_PREFIX = 'biology-search-log_';
export const JAPANESE_HISTORY_SEARCH_LOG_FILE_PREFIX = 'japanese-history-search-log_';
export const WORLD_HISTORY_SEARCH_LOG_FILE_PREFIX = 'world-history-search-log_';
export const GEOGRAPHY_SEARCH_LOG_FILE_PREFIX = 'geography-search-log_';
export const POLITICAL_ECONOMY_SEARCH_LOG_FILE_PREFIX = 'political-economy-search-log_';
export const ACTIVE_LOG_FILE_SUBJECT_IDS = [
  SubjectId.ENGLISH,
  SubjectId.MATH,
  SubjectId.NATIONAL_LANGUAGE,
  SubjectId.PHYSICS,
  SubjectId.CHEMISTRY,
  SubjectId.BIOLOGY,
  SubjectId.JAPANESE_HISTORY,
  SubjectId.WORLD_HISTORY,
  SubjectId.GEOGRAPHY,
  SubjectId.POLITICAL_ECONOMY
];

export const PROBLEM_ID_LENGTH = 12;
export const PAPER_ID_LENGTH = 10;

export const MEMBER_CONTACT_URL = 'https://reg34.smp.ne.jp/regist/is?SMPFORM=mbo-lhojoc-8eac684e51770d83ecda85deb06c5391';

// export const NEW_INSTALLATION_URL = 'https://reg34.smp.ne.jp/regist/is?SMPFORM=mbo-lhpbkd-9aa680a8cb5701353b15697334468213'; // LP: 新規導入の申し込み
export const NEW_INSTALLATION_URL = 'https://reg34.smp.ne.jp/regist/is?SMPFORM=mbo-litbrc-b40e27ce6798fdfc68947a3b1deb42b0'; // LP: 新規導入の申し込み
export const OTHER_INQUIRY_URL = 'https://reg34.smp.ne.jp/regist/is?SMPFORM=mbo-lhpbkc-99d3612d1ee2c81e491b8065f5de14f9'; // LP: その他の問い合わせ
export const TRIAL_INQUIRY_URL = 'https://reg34.smp.ne.jp/regist/is?SMPFORM=mbo-ljkase-ee4aca33efa9ca2bfee7de4eceafa068'; // LP: 無料体験の申し込み

// アカウント一覧の絞り込み用
export const ACCOUNT_FILTER_ACCOUNT_STATUS = [
  { id: 0, name: 'すべて' },
  { id: 1, name: '有効', isActive: true },
  { id: 2, name: '無効', isActive: false }
];
export const ACCOUNT_FILTER_ORGANIZATION = [
  { id: 0, name: 'すべて' },
  { id: 1, name: '学校', organization: SCHOOL_ORGANIZATION_ID },
  { id: 2, name: '塾／予備校', organization: JUKU_ORGANIZATION_ID },
  { id: 3, name: '旺文社内', organization: OBUNSHA_ORGANIZATION_ID },
  { id: 4, name: 'トライ', organization: TRY_ORGANIZATION_ID },
  { id: 5, name: 'その他', organization: OTHER_ORGANIZATION_ID }
];
export const ACCOUNT_FILTER_PLAN_STATUS = [
  { id: 0, name: 'すべて' },
  { id: 1, name: '割当中', isAssigned: true },
  { id: 2, name: '未割当', isAssigned: false }
];
export const ACCOUNT_FILTER_AUTHORIZATION = [
  { id: 0, name: 'すべて' },
  { id: 1, name: '団体管理ユーザー', isOrganizationAdmin: true },
  { id: 2, name: '一般ユーザー', isOrganizationAdmin: false }
];
export const ACCOUNT_FILTER_PLAN_SUBJECT = [
  { id: 0, name: 'すべて' },
  { id: 1, name: SUBJECT_NAMES[SubjectId.ENGLISH], planSubjectId: PlanSubjectId.ENGLISH },
  { id: 2, name: SUBJECT_NAMES[SubjectId.MATH], planSubjectId: PlanSubjectId.MATH },
  { id: 3, name: SUBJECT_NAMES[SubjectId.NATIONAL_LANGUAGE], planSubjectId: PlanSubjectId.NATIONAL_LANGUAGE },
  { id: 4, name: SUBJECT_NAMES[SubjectId.PHYSICS], planSubjectId: PlanSubjectId.PHYSICS },
  { id: 5, name: SUBJECT_NAMES[SubjectId.CHEMISTRY], planSubjectId: PlanSubjectId.CHEMISTRY },
  { id: 6, name: SUBJECT_NAMES[SubjectId.BIOLOGY], planSubjectId: PlanSubjectId.BIOLOGY },
  { id: 7, name: SUBJECT_NAMES[SubjectId.JAPANESE_HISTORY], planSubjectId: PlanSubjectId.JAPANESE_HISTORY },
  { id: 8, name: SUBJECT_NAMES[SubjectId.WORLD_HISTORY], planSubjectId: PlanSubjectId.WORLD_HISTORY },
  { id: 9, name: SUBJECT_NAMES[SubjectId.GEOGRAPHY], planSubjectId: PlanSubjectId.GEOGRAPHY },
  { id: 10, name: SUBJECT_NAMES[SubjectId.POLITICAL_ECONOMY], planSubjectId: PlanSubjectId.POLITICAL_ECONOMY }
];

export const DEFAULT_EMAIL_PLACEHOLDER = 'メールアドレスで絞り込む';
export const DEFAULT_NAME_PLACEHOLDER = '氏名で絞り込む';

export const ACCOUNTS_PAGE_SIZE_OPTIONS = [50, 100, 150];

export const SELECT_PREFECTURE_DIALOG_HEIGHT = '800px';
export const SELECT_PREFECTURE_DIALOG_WITDH = '700px';

export const MAX_BOOKMARK_COUNT = 2000;
export const MAX_UNIVERSITY_BOOKMARK_COUNT = 500;
export const MAX_PAPER_BOOKMARK_COUNT = 2000;
export const MAX_ANSWERED_PROBLEMS_COUNT = 4000;
export const MAX_PLAYLIST_BOOKMARK_COUNT = 1000;

export const PLAYLISTS_SUBJECT_ALL_ID = '99';
export const PLAYLISTS_SUBJECT_ALL_NAME = 'すべての科目';

export const REQUEST_GOOGLE_FORM_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSfVR9Cabglun2BEq9A5qnljP1tgv7ko6DOod_ETI6WLms8Log/viewform?usp=pp_url&entry.700620666=<email>';

// 大問画像表示画面用のコードを保存
export const KAKOMON_ALLOW_CODE_LIST = ['cMXPky5uGuwd'];

// ご意見箱バナーの表示／非表示切り替え
export const IS_BOTTOM_TOAST_BANNER_VISIBLE = false;
