import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_LEGACY_RADIO_DEFAULT_OPTIONS as MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/legacy-radio';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

import { WINDOW_OBJECT } from './utils/injection-tokens';

// Modules
import { AppAngularFireModule } from './app-angular-fire.module';
import { AppRoutingModule } from './app-routing.module';
import { AppStoreModule } from './app-store.module';
import { AppUiModule } from './app-ui.module';

// Pipes
import { HighlightPipe } from './views/pipes/highlight.pipe';

// Entry Components
import { AddAccountDialogComponent } from './views/components/add-account-dialog/add-account-dialog.component';
import { DeleteAccountDialogComponent } from './views/components/delete-account-dialog/delete-account-dialog.component';
import { ErrorSnackBarComponent } from './views/components/error-snack-bar/error-snack-bar.component';
import { PasswordResetDialogComponent } from './views/components/password-reset-dialog/password-reset-dialog.component';
import { PrintConfirmDialogComponent } from './views/components/print-confirm-dialog/print-confirm-dialog.component';
import { SelectCategoryDialogComponent } from './views/components/select-category-dialog/select-category-dialog.component';

// Widgets
import { AlertBoxComponent } from './views/widgets/alert-box/alert-box.component';
import { AccordionListItemComponent } from './views/widgets/accordion-list-item/accordion-list-item.component';
import { ArticleContentHeadComponent } from './views/widgets/article-content-head/article-content-head.component';
import { ArticleContentComponent } from './views/widgets/article-content/article-content.component';
import { AutocompleteMultiselectComponent } from './views/widgets/autocomplete-multiselect/autocomplete-multiselect.component';
import { BreadcrumbsComponent } from './views/widgets/breadcrumbs/breadcrumbs.component';
import { ButtonDownloadWordFileComponent } from './views/widgets/button-download-word-file/button-download-word-file.component';
import { ButtonLocationBackComponent } from './views/widgets/button-location-back/button-location-back.component';
import { ButtonMoreComponent } from './views/widgets/button-more/button-more.component';
import { ButtonSubjectNameComponent } from './views/widgets/button-subject-name/button-subject-name.component';
import { ButtonToggleBookmarkComponent } from './views/widgets/button-toggle-bookmark/button-toggle-bookmark.component';
import { ButtonTogglePaperBookmarkIconComponent } from './views/widgets/button-toggle-paper-bookmark-icon/button-toggle-paper-bookmark-icon.component';
import { ButtonTogglePaperBookmarkComponent } from './views/widgets/button-toggle-paper-bookmark/button-toggle-paper-bookmark.component';
import { ButtonTogglePlaylistBookmarkComponent } from './views/widgets/button-toggle-playlist-bookmark/button-toggle-playlist-bookmark.component';
import { ButtonToggleSavedProblemComponent } from './views/widgets/button-toggle-saved-problem/button-toggle-saved-problem.component';
import { ButtonToggleUnivBookmarkComponent } from './views/widgets/button-toggle-univ-bookmark/button-toggle-univ-bookmark.component';
import { ButtonToggleComponent } from './views/widgets/button-toggle/button-toggle.component';
import { ButtonWithProgressComponent } from './views/widgets/button-with-progress/button-with-progress.component';
import { CardContentHeadComponent } from './views/widgets/card-content-head/card-content-head.component';
import { CardContentNavComponent } from './views/widgets/card-content-nav/card-content-nav.component';
import { CardContentComponent } from './views/widgets/card-content/card-content.component';
import { CommonIdAccountsTableComponent } from './views/widgets/common-id/accounts-table/accounts-table.components';
import { CommonIdBottomTabUiComponent } from './views/widgets/common-id/bottom-tab-ui/bottom-tab-ui.component';
import { CommonIdFooterComponent } from './views/widgets/common-id/footer/footer.component';
import { CommonIdMylistBannerComponent } from './views/widgets/common-id/mylist-banner/mylist-banner.component';
import { CommonIdPlaylistsSearchConditionComponent } from './views/widgets/common-id/playlists-search-condition/playlists-search-condition.component';
import { CommonIdPlaylistsSearchResultComponent } from './views/widgets/common-id/playlists-search-result/playlists-search-result.component';
import { CommonIdPlaylistsTagButtonsComponent } from './views/widgets/common-id/playlists-tag-buttons/playlists-tag-buttons.component';
import { CommonIdProblemListItemComponent } from './views/widgets/common-id/problem-list-item/problem-list-item.component';
import { CommonIdProblemListItemSmallComponent } from './views/widgets/common-id/problem-list-item-small/problem-list-item-small.component';
import { CommonIdSignInHeaderComponent } from './views/widgets/common-id/sign-in-header/sign-in-header.component';
import { CommonIdSignOutHeaderComponent } from './views/widgets/common-id/sign-out-header/sign-out-header.component';
import { CommonIdTermsContentComponent } from './views/widgets/common-id/terms-content/terms-content.component';
import { CommonIdTopHeaderComponent } from './views/widgets/common-id/top-header/top-header.component';
import { ContentNoDataComponent } from './views/widgets/content-no-data/content-no-data.component';
import { ContentRowComponent } from './views/widgets/content-row/content-row.component';
import { OsusumeCarouselComponent } from './views/widgets/osusume-carousel/osusume-carousel.component';
import { DialogBasicFooterComponent } from './views/widgets/dialog-basic-footer/dialog-basic-footer.component';
import { DialogBasicComponent } from './views/widgets/dialog-basic/dialog-basic.component';
import { DocumentThemeDefaultComponent } from './views/widgets/document-theme-default/document-theme-default.component';
import { FaqListItemComponent } from './views/widgets/faq-list-item/faq-list-item.component';
import { FooterComponent } from './views/widgets/footer/footer.component';
import { HeaderWithListmarkComponent } from './views/widgets/header-with-listmark/header-with-listmark.component';
import { IconPremiumComponent } from './views/widgets/icon-premium/icon-premium.component';
import { IconWordComponent } from './views/widgets/icon-word/icon-word.component';
import { LabelWithIconComponent } from './views/widgets/label-with-icon/label-with-icon.component';
import { LinkWithArrowComponent } from './views/widgets/link-with-arrow/link-with-arrow.component';
import { MainLayoutSpacerComponent } from './views/widgets/main-layout-spacer/main-layout-spacer.component';
import { OverflowWrapperXComponent } from './views/widgets/overflow-wrapper-x/overflow-wrapper-x.component';
import { PaginatorComponent } from './views/widgets/paginator/paginator.component';
import { PlanMembersAddComponent } from './views/widgets/plan-members-add/plan-members-add.component';
import { PlanMembersItemComponent } from './views/widgets/plan-members-item/plan-members-item.component';
import { PlanMembersComponent } from './views/widgets/plan-members/plan-members.component';
import { PlaylistCardItemComponent } from './views/widgets/playlist-card-item/playlist-card-item.component';
import { PlaylistTagLabelComponent } from './views/widgets/playlist-tag-label/playlist-tag-label.component';
import { PlaylistTagButtonComponent } from './views/widgets/playlist-tag-button/playlist-tag-button.component';
import { PrintHeaderComponent } from './views/widgets/print-header/print-header.component';
import { ProblemDetailBackForwardButtonComponent } from './views/widgets/problem-detail-back-forward-button/problem-detail-back-forward-button.component';
import { ProblemDetailFrameForPrintComponent } from './views/widgets/problem-detail-frame-for-print/problem-detail-frame-for-print.component';
import { ProblemDetailFrameComponent } from './views/widgets/problem-detail-frame/problem-detail-frame.component';
import { ProblemDetailNoExternalDataComponent } from './views/widgets/problem-detail-no-external-data/problem-detail-no-external-data.component';
import { ProblemDetailSeparaterComponent } from './views/widgets/problem-detail-separater/problem-detail-separater.component';
import { ProgressBarComponent } from './views/widgets/progress-bar/progress-bar.component';
import { ProviderAccordionComponent } from './views/widgets/provider-accordion/provider-accordion.component';
import { SectionBasicHeadComponent } from './views/widgets/section-basic-head/section-basic-head.component';
import { SectionBasicComponent } from './views/widgets/section-basic/section-basic.component';
import { SearchUnivBaseComponent } from './views/widgets/search-univ-base/search-univ-base.component';
import { SearchUnivDetailBaseComponent } from './views/widgets/search-univ-detail-base/search-univ-detail-base.component';
import { SearchUnivResultBaseComponent } from './views/widgets/search-univ-result-base/search-univ-result-base.component';
import { SearchUnivPrefBaseComponent } from './views/widgets/search-univ-pref-base/search-univ-pref-base.component';
import { SignInHeaderComponent } from './views/widgets/sign-in-header/sign-in-header.component';
import { SignOutHeaderComponent } from './views/widgets/sign-out-header/sign-out-header.component';
import { SingleInputDialogComponent } from './views/widgets/single-input-dialog/single-input-dialog.component';
import { SlideToggleComponent } from './views/widgets/slide-toggle/slide-toggle.component';
import { TableOverflowComponent } from './views/widgets/table-overflow/table-overflow.component';
import { TermsContentComponent } from './views/widgets/terms-content/terms-content.component';
import { ThumbPreviewProblemComponent } from './views/widgets/thumb-preview-problem/thumb-preview-problem.component';
import { TipsContentComponent } from './views/widgets/tips-content/tips-content.component';
import { TopHeaderComponent } from './views/widgets/top-header/top-header.component';
import { TrySignInHeaderComponent } from './views/widgets/try-sign-in-header/try-sign-in-header.component';
import { UnivCatMarkComponent } from './views/widgets/univ-cat-mark/univ-cat-mark.component';
import { UnivChipListComponent } from './views/widgets/univ-chip-list/univ-chip-list-component';
import { WrapperComponent } from './views/widgets/wrapper/wrapper.component';
import { LabelLevelWithStarsComponent } from './views/widgets/label-level-with-stars/label-level-with-stars.component';
import { LabelSubjectComponent } from './views/widgets/label-subject/label-subject.component';
import { LabelAnsweredComponent } from './views/widgets/label-answered/label-answered.component';
import { ButtonPageRefreshComponent } from './views/widgets/button-page-refresh/button-page-refresh.component';
import { ContentLpPricingSectionsComponent } from './views/widgets/content-lp-pricing-sections/content-lp-pricing-sections.component';
import { ContentLpPremiumFeatureCardsComponent } from './views/widgets/content-lp-premium-feature-cards/content-lp-premium-feature-cards.component';
import { ContentLpFunctionalComparisonComponent } from './views/widgets/content-lp-functional-comparison/content-lp-functional-comparison.component';
import { ContentLpPurchaseProcessComponent } from './views/widgets/content-lp-purchase-process/content-lp-purchase-process.component';

// Components
import { AppComponent } from './app.component';
import { AccountsComponent } from './views/components/accounts/accounts.component';
import { AddJukuDialogComponent } from './views/components/add-juku-dialog/add-juku-dialog.component';
import { AddPlanDialogComponent } from './views/components/add-plan-dialog/add-plan-dialog.component';
import { AdminComponent } from './views/components/admin/admin.component';
import { BookmarkProblemsComponent } from './views/components/bookmark-problems/bookmark-problems.component';
import { BulkMailDialogComponent } from './views/components/bulk-mail-dialog/bulk-mail-dialog.component';
import { BulkMailComponent } from './views/components/bulk-mail/bulk-mail.component';
import { ChangeActiveAccountDialogComponent } from './views/components/change-active-account-dialog/change-active-account-dialog.component';
import { ChangeOrganizationAuthorityDialogComponent } from './views/components/change-organization-authority-dialog/change-organization-authority-dialog.component';
import { CheckActivePlanErrorComponent } from './views/components/check-active-plan-error/check-active-plan-error.component';
import { CommonIdAccountsComponent } from './views/components/common-id/accounts/accounts.component';
import { CommonIdAccountSearchComponent } from './views/components/common-id/account-search/account-search.component';
import { CommonIdChangeActiveAccountDialogComponent } from './views/components/common-id/change-active-account-dialog/change-active-account-dialog.component';
import { CommonIdCompleteAnsweredDialogComponent } from './views/components/common-id/complete-answered-dialog/complete-answered-dialog.component';
import { DeleteUniversityBookmarkDialogComponent } from './views/components/common-id/delete-university-bookmark-dialog/delete-university-bookmark-dialog.component';
import { CommonIdDescriptionPremiumDialogComponent } from './views/components/common-id/description-premium-dialog/description-premium-dialog.component';
import { CommonIdGotoManabiIdDialogComponent } from './views/components/common-id/goto-manabi-id-dialog/goto-manabi-id-dialog.component';
import { CommonIdHelpComponent } from './views/components/common-id/help/help.component';
import { CommonIdInformationDetailComponent } from './views/components/common-id/information-detail/information-detail.component';
import { CommonIdInformationComponent } from './views/components/common-id/information/information.component';
import { CommonIdMainComponent } from './views/components/common-id/main/main.component';
import { CommonIdMylistPlaylistsComponent } from './views/components/common-id/mylist-playlists/mylist-playlists.component';
import { CommonIdMylistProblemsComponent } from './views/components/common-id/mylist-problems/mylist-problems.component';
import { CommonIdMylistUniversitiesComponent } from './views/components/common-id/mylist-universities/mylist-universities.component';
import { CommonIdPaperInformationDialogComponent } from './views/components/common-id/paper-information-dialog/paper-information-dialog.component';
import { CommonIdPaymentsComponent } from './views/components/common-id/payments/payments.component';
import { CommonIdPlaylistDetailComponent } from './views/components/common-id/playlist-detail/playlist-detail.component';
import { CommonIdPlaylistProblemDetailComponent } from './views/components/common-id/playlist-problem-detail/playlist-problem-detail.component';
import { CommonIdPlaylistsComponent } from './views/components/common-id/playlists/playlists.component';
import { CommonIdProblemDetailComponent } from './views/components/common-id/problem-detail/problem-detail.component';
import { CommonIdPurchaseCallbackComponent } from './views/components/common-id/purchase-callback/purchase-callback.component';
import { CommonIdPurchaseCompleteComponent } from './views/components/common-id/purchase-complete/purchase-complete.component';
import { CommonIdPurchaseConfirmDialogComponent } from './views/components/common-id/purchase-confirm-dialog/purchase-confirm-dialog.component';
import { CommonIdPurchaseDialogComponent } from './views/components/common-id/purchase-dialog/purchase-dialog.component';
import { CommonIdPurchaseComponent } from './views/components/common-id/purchase/purchase.component';
import { CommonIdSearchByCategoriesBaseComponent } from './views/widgets/common-id/search-by-categories-base/search-by-categories-base.component';
import { CommonIdSearchByCategoriesComponent } from './views/components/common-id/search-by-categories/search-by-categories.component';
import { CommonIdSearchByCategoriesProblemDetailComponent } from './views/components/common-id/search-by-categories-problem-detail/search-by-categories-problem-detail.component';
import { CommonIdSearchByCategoriesResultComponent } from './views/components/common-id/search-by-categories-result/search-by-categories-result.component';
import { CommonIdSearchUnivDetailComponent } from './views/components/common-id/search-univ-detail/search-univ-detail.component';
import { CommonIdSearchUnivPrefComponent } from './views/components/common-id/search-univ-pref/search-univ-pref.component';
import { CommonIdSearchUnivResultComponent } from './views/components/common-id/search-univ-result/search-univ-result.component';
import { CommonIdSearchUnivComponent } from './views/components/common-id/search-univ/search-univ.component';
import { CommonIdSelectAnsweredDialogComponent } from './views/components/common-id/select-answered-dialog/select-answered-dialog.component';
import { CommonIdSelectAnsweredForProblemComponent } from './views/components/common-id/select-answered-for-problem-dialog/select-answered-for-problem-dialog.component';
import { CommonIdSelectSubjectCategoryDialogComponent } from './views/components/common-id/select-subject-category-dialog/select-subject-category-dialog.component';
import { CommonIdSelectDetailedSearchConditionDialogComponent } from './views/components/common-id/select-detailed-search-condition-dialog/select-detailed-search-condition-dialog.component';
import { CommonIdSignInCallbackComponent } from './views/components/common-id/sign-in-callback/sign-in-callback.component';
import { CommonIdSignInErrorComponent } from './views/components/common-id/sign-in-error/sign-in-error.component';
import { CommonIdSignInComponent } from './views/components/common-id/sign-in/sign-in.component';
import { CommonIdSignOutComponent } from './views/components/common-id/sign-out/sign-out.component';
import { CommonIdTermsComponent } from './views/components/common-id/terms/terms.component';
import { CommonIdTokusyouhouComponent } from './views/components/common-id/tokusyouhou/tokusyouhou.component';
import { CommonIdTopComponent } from './views/components/common-id/top/top.component';
import { CommonIdUpdateAccountDialogComponent } from './views/components/common-id/update-account-dialog/update-account-dialog.component';
import { CommonSearchFormComponent } from './views/components/common-search-form/common-search-form.component';
import { DeleteBookmarkDialogComponent } from './views/components/delete-bookmark-dialog/delete-bookmark-dialog.component';
import { DeleteJukuDialogComponent } from './views/components/delete-juku-dialog/delete-juku-dialog.component';
import { DeletePlanDialogComponent } from './views/components/delete-plan-dialog/delete-plan-dialog.component';
import { EditAccountDialogComponent } from './views/components/edit-account-dialog/edit-account-dialog.component';
import { EditEmailDialogComponent } from './views/components/edit-email-dialog/edit-email-dialog.component';
import { EditJukuDialogComponent } from './views/components/edit-juku-dialog/edit-juku-dialog.component';
import { EditPlanDialogComponent } from './views/components/edit-plan-dialog/edit-plan-dialog.component';
import { EnglishProblemOutlineComponent } from './views/components/english-problem-outline/english-problem-outline.component';
import { EnglishProblemsComponent } from './views/components/english-problems/english-problems.component';
import { EnglishSearchFormComponent } from './views/components/english-search-form/english-search-form.component';
import { EntranceComponent } from './views/components/entrance/entrance.component';
import { HelpComponent } from './views/components/help/help.component';
import { HistoryProblemsComponent } from './views/components/history-problems/history-problems.component';
import { HistorySearchFormComponent } from './views/components/history-search-form/history-search-form.component';
import { InformationDetailComponent } from './views/components/information-detail/information-detail.component';
import { InformationComponent } from './views/components/information/information.component';
import { JukusComponent } from './views/components/jukus/jukus.component';
import { MainComponent } from './views/components/main/main.component';
import { MaintenanceComponent } from './views/components/maintenance/maintenance.component';
import { MembersComponent } from './views/components/members/members.component';
import { NationalLanguageProblemsComponent } from './views/components/national-language-problems/national-language-problems.component';
import { NationalLanguageSearchFormComponent } from './views/components/national-language-search-form/national-language-search-form.component';
import { PlanAssignMemberDialogComponent } from './views/components/plan-assign-member-dialog/plan-assign-member-dialog.component';
import { PlanAssignComponent } from './views/components/plan-assign/plan-assign.component';
import { PlanStatusesComponent } from './views/components/plan-statuses/plan-statuses.component';
import { PlanUnassignMemberDialogComponent } from './views/components/plan-unassign-member-dialog/plan-unassign-member-dialog.component';
import { PlansComponent } from './views/components/plans/plans.component';
import { PlaylistDetailComponent } from './views/components/playlist-detail/playlist-detail.component';
import { PlaylistProblemDetailComponent } from './views/components/playlist-problem-detail/playlist-problem-detail.component';
import { PlaylistsComponent } from './views/components/playlists/playlists.component';
import { PrintComponent } from './views/components/print/print.component';
import { ProblemDetailComponent } from './views/components/problem-detail/problem-detail.component';
import { ReportsComponent } from './views/components/reports/reports.component';
import { SchoolsComponent } from './views/components/schools/schools.component';
import { ScienceProblemsComponent } from './views/components/science-problems/science-problems.component';
import { SearchResultComponent } from './views/components/search-result/search-result.component';
import { SearchComponent } from './views/components/search/search.component';
import { SearchUnivComponent } from './views/components/search-univ/search-univ.component';
import { SearchUnivDetailComponent } from './views/components/search-univ-detail/search-univ-detail.component';
import { SearchUnivPrefComponent } from './views/components/search-univ-pref/search-univ-pref.component';
import { SearchUnivResultComponent } from './views/components/search-univ-result/search-univ-result.component';
import { SelectPrefectureDialogComponent } from './views/components/select-prefecture-dialog/select-prefecture-dialog.component';
import { SelectedCategoriesComponent } from './views/components/selected-categories/selected-categories.component';
import { SignInComponent } from './views/components/sign-in/sign-in.component';
import { StyleGuideComponent } from './views/components/style-guide/style-guide.component';
import { TermsAgreeComponent } from './views/components/terms-agree/terms-agree.component';
import { TermsComponent } from './views/components/terms/terms.component';
import { TokusyouhouComponent } from './views/components/tokusyouhou/tokusyouhou.component';
import { ToolsComponent } from './views/components/tools/tools.component';
import { TopComponent } from './views/components/top/top.component';
import { TryKakomonImageComponent } from './views/components/try/kakomon-image/kakomon-image.component';
import { TryProblemDetailDataTableComponent } from './views/components/try/problem-detail-data-table/problem-detail-data-table.component';
import { TryProblemDetailComponent } from './views/components/try/problem-detail/problem-detail.component';
import { CommonIdPaperSourceInformationDialogComponent } from './views/components/common-id/paper-source-information-dialog/paper-source-information-dialog.component';
import { CommonIdMylistAnswerProblemsComponent } from './views/components/common-id/mylist-answer-problems/mylist-answer-problems.component';
import { MembersImportComponent } from './views/components/members-import/members-import.component';
import { MembersImportDialogComponent } from './views/components/members-import-dialog/members-import-dialog.component';
import { ToolsDeleteMembersComponent } from './views/components/tools-delete-members/tools-delete-members.component';
import { ChangeRenewPlanAlertDialogComponent } from './views/components/change-renew-plan-alert-dialog/change-renew-plan-alert-dialog.component';

// ライブラリ
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
  declarations: [
    AppComponent,
    ErrorSnackBarComponent,
    SignInComponent,
    TermsAgreeComponent,
    MainComponent,
    AdminComponent,
    HelpComponent,
    InformationComponent,
    InformationDetailComponent,
    HighlightPipe,
    SearchComponent,
    CommonSearchFormComponent,
    SearchResultComponent,
    ScienceProblemsComponent,
    HistoryProblemsComponent,
    BookmarkProblemsComponent,
    DeleteBookmarkDialogComponent,
    SelectCategoryDialogComponent,
    EnglishSearchFormComponent,
    EnglishProblemsComponent,
    EnglishProblemOutlineComponent,
    HistorySearchFormComponent,
    NationalLanguageSearchFormComponent,
    NationalLanguageProblemsComponent,
    PrintConfirmDialogComponent,
    PrintComponent,
    SelectedCategoriesComponent,
    PlaylistProblemDetailComponent,
    PlaylistDetailComponent,
    PlaylistsComponent,
    PasswordResetDialogComponent,
    AccountsComponent,
    AddAccountDialogComponent,
    EditAccountDialogComponent,
    DeleteAccountDialogComponent,
    EditEmailDialogComponent,
    ChangeActiveAccountDialogComponent,
    ReportsComponent,
    ToolsComponent,
    TermsComponent,
    TokusyouhouComponent,
    ButtonWithProgressComponent,
    SingleInputDialogComponent,
    ProblemDetailComponent,
    PaginatorComponent,
    PrintHeaderComponent,
    FooterComponent,
    SignInHeaderComponent,
    SignOutHeaderComponent,
    CardContentComponent,
    CardContentHeadComponent,
    CardContentNavComponent,
    LinkWithArrowComponent,
    WrapperComponent,
    LabelLevelWithStarsComponent,
    LabelSubjectComponent,
    LabelAnsweredComponent,
    ContentRowComponent,
    OsusumeCarouselComponent,
    BreadcrumbsComponent,
    StyleGuideComponent,
    LabelWithIconComponent,
    TableOverflowComponent,
    ContentNoDataComponent,
    TipsContentComponent,
    PlansComponent,
    AddPlanDialogComponent,
    EditPlanDialogComponent,
    DeletePlanDialogComponent,
    PlanStatusesComponent,
    PlanAssignComponent,
    PlanAssignMemberDialogComponent,
    PlanUnassignMemberDialogComponent,
    PlanMembersComponent,
    PlanMembersItemComponent,
    PlanMembersAddComponent,
    ChangeOrganizationAuthorityDialogComponent,
    MembersComponent,
    SchoolsComponent,
    SectionBasicComponent,
    SectionBasicHeadComponent,
    CheckActivePlanErrorComponent,
    ButtonLocationBackComponent,
    ButtonToggleComponent,
    ButtonToggleBookmarkComponent,
    HeaderWithListmarkComponent,
    ProviderAccordionComponent,
    FaqListItemComponent,
    DocumentThemeDefaultComponent,
    ArticleContentComponent,
    ArticleContentHeadComponent,
    TermsContentComponent,
    MainLayoutSpacerComponent,
    DialogBasicComponent,
    DialogBasicFooterComponent,
    TopComponent,
    TopHeaderComponent,
    JukusComponent,
    AddJukuDialogComponent,
    EditJukuDialogComponent,
    DeleteJukuDialogComponent,
    ProblemDetailSeparaterComponent,
    ProblemDetailFrameComponent,
    ProblemDetailFrameForPrintComponent,
    ProblemDetailBackForwardButtonComponent,
    ProblemDetailNoExternalDataComponent,
    ButtonTogglePaperBookmarkComponent,
    BulkMailComponent,
    BulkMailDialogComponent,
    MaintenanceComponent,
    OverflowWrapperXComponent,
    ButtonDownloadWordFileComponent,
    CommonIdMainComponent,
    CommonIdSignInCallbackComponent,
    CommonIdSignInComponent,
    CommonIdSignOutComponent,
    CommonIdSearchUnivComponent,
    CommonIdSearchUnivDetailComponent,
    CommonIdSearchUnivResultComponent,
    CommonIdProblemDetailComponent,
    CommonIdSearchUnivPrefComponent,
    CommonIdPlaylistsComponent,
    CommonIdPlaylistDetailComponent,
    CommonIdPlaylistProblemDetailComponent,
    CommonIdPlaylistsTagButtonsComponent,
    CommonIdPlaylistsSearchResultComponent,
    CommonIdPlaylistsSearchConditionComponent,
    CommonIdInformationComponent,
    CommonIdInformationDetailComponent,
    SelectPrefectureDialogComponent,
    CommonIdPurchaseComponent,
    CommonIdPurchaseDialogComponent,
    CommonIdPurchaseCompleteComponent,
    CommonIdPurchaseConfirmDialogComponent,
    CommonIdMylistUniversitiesComponent,
    AlertBoxComponent,
    AccordionListItemComponent,
    UnivCatMarkComponent,
    ButtonToggleUnivBookmarkComponent,
    ButtonTogglePlaylistBookmarkComponent,
    UnivCatMarkComponent,
    ButtonSubjectNameComponent,
    IconPremiumComponent,
    IconWordComponent,
    ThumbPreviewProblemComponent,
    PlaylistCardItemComponent,
    PlaylistTagLabelComponent,
    PlaylistTagButtonComponent,
    CommonIdSignInHeaderComponent,
    CommonIdMylistUniversitiesComponent,
    CommonIdPaymentsComponent,
    CommonIdHelpComponent,
    ButtonMoreComponent,
    DeleteUniversityBookmarkDialogComponent,
    CommonIdSelectAnsweredDialogComponent,
    CommonIdDescriptionPremiumDialogComponent,
    SlideToggleComponent,
    CommonIdMylistPlaylistsComponent,
    CommonIdMylistProblemsComponent,
    CommonIdPaperInformationDialogComponent,
    ButtonToggleSavedProblemComponent,
    CommonIdSelectAnsweredForProblemComponent,
    CommonIdAccountsComponent,
    CommonIdChangeActiveAccountDialogComponent,
    CommonIdUpdateAccountDialogComponent,
    ButtonToggleSavedProblemComponent,
    CommonIdTopComponent,
    CommonIdPurchaseCallbackComponent,
    CommonIdCompleteAnsweredDialogComponent,
    CommonIdFooterComponent,
    CommonIdSignOutHeaderComponent,
    CommonIdTermsContentComponent,
    CommonIdTermsComponent,
    CommonIdTokusyouhouComponent,
    ButtonTogglePaperBookmarkIconComponent,
    CommonIdBottomTabUiComponent,
    EntranceComponent,
    CommonIdTopHeaderComponent,
    CommonIdGotoManabiIdDialogComponent,
    CommonIdSignInErrorComponent,
    CommonIdMylistBannerComponent,
    ProgressBarComponent,
    SearchUnivComponent,
    SearchUnivPrefComponent,
    SearchUnivResultComponent,
    SearchUnivDetailComponent,
    SearchUnivResultBaseComponent,
    SearchUnivDetailBaseComponent,
    SearchUnivBaseComponent,
    SearchUnivPrefBaseComponent,
    TryProblemDetailComponent,
    TryProblemDetailDataTableComponent,
    TrySignInHeaderComponent,
    TryKakomonImageComponent,
    CommonIdSearchByCategoriesComponent,
    CommonIdSearchByCategoriesBaseComponent,
    CommonIdSelectDetailedSearchConditionDialogComponent,
    CommonIdSelectSubjectCategoryDialogComponent,
    CommonIdSearchByCategoriesResultComponent,
    CommonIdSearchByCategoriesProblemDetailComponent,
    CommonIdPaperSourceInformationDialogComponent,
    CommonIdProblemListItemComponent,
    CommonIdProblemListItemSmallComponent,
    CommonIdMylistAnswerProblemsComponent,
    ButtonPageRefreshComponent,
    UnivChipListComponent,
    AutocompleteMultiselectComponent,
    CommonIdAccountSearchComponent,
    CommonIdAccountsTableComponent,
    MembersImportComponent,
    MembersImportDialogComponent,
    ToolsDeleteMembersComponent,
    ChangeRenewPlanAlertDialogComponent,
    ContentLpPricingSectionsComponent,
    ContentLpPremiumFeatureCardsComponent,
    ContentLpFunctionalComparisonComponent,
    ContentLpPurchaseProcessComponent
  ],
  imports: [
    CKEditorModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AppAngularFireModule,
    AppUiModule,
    AppStoreModule
  ],
  providers: [
    Title,
    Meta,
    { provide: WINDOW_OBJECT, useValue: window },
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        // This function is intentionally left blank
      },
      deps: [Sentry.TraceService],
      multi: true
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
