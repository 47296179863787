<div class="description-premium-dialog">
  <!-- 通常プランバージョン -->
  <div>
    <!-- パターンA -->
    <div *ngIf="displayOption === NORMAL_A">
      <picture>
        <source srcset="./assets/images/info-premium-required/info-premium-required-v2-a@2x.png" media="(min-width: 780px)" />
        <img
          src="./assets/images/info-premium-required/info-premium-required-v2-a-sp@2x.png"
          alt="入試正解デジタルプレミアムにご登録が必要です"
        />
      </picture>
    </div>
    <!-- パターンB -->
    <div *ngIf="displayOption === NORMAL_B">
      <picture>
        <source srcset="./assets/images/info-premium-required/info-premium-required-v2-b@2x.png" media="(min-width: 780px)" />
        <img
          src="./assets/images/info-premium-required/info-premium-required-v2-b-sp@2x.png"
          alt="入試正解デジタルプレミアムにご登録が必要です"
        />
      </picture>
    </div>
  </div>

  <!-- 3ヶ月プランバージョン -->
  <div>
    <!-- パターンA -->
    <div *ngIf="displayOption === THREE_MONTH_A">
      <picture>
        <source srcset="./assets/images/info-premium-required/info-premium-required-v2-a-3month@2x.png" media="(min-width: 780px)" />
        <img
          src="./assets/images/info-premium-required/info-premium-required-v2-a-3month-sp@2x.png"
          alt="入試正解デジタルプレミアムにご登録が必要です"
        />
      </picture>
    </div>
    <!-- パターンB -->
    <div *ngIf="displayOption === THREE_MONTH_B">
      <picture>
        <source srcset="./assets/images/info-premium-required/info-premium-required-v2-b-3month@2x.png" media="(min-width: 780px)" />
        <img
          src="./assets/images/info-premium-required/info-premium-required-v2-b-3month-sp@2x.png"
          alt="入試正解デジタルプレミアムにご登録が必要です"
        />
      </picture>
    </div>
  </div>

  <app-dialog-basic-footer class="description-premium-dialog-footer [&>.dialog-basic-footer]:justify-center">
    <button mat-stroked-button (click)="close()">閉じる</button>
    <button mat-flat-button color="primary" (click)="gotoPlan()" class="min-w-36">詳しく見る</button>
  </app-dialog-basic-footer>
</div>
