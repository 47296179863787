import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { map, filter, take, shareReplay } from 'rxjs/operators';

// Redux
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';
import { navigate, setTitle } from 'src/app/actions/core.actions';
import { getCurrentDateTime } from 'src/app/actions/current-date-time.actions';
import * as CommonIdAnsweredProblemActions from '../../../../actions/common-id/common-id-answered-problem.actions';
import * as SearchActions from 'src/app/actions/search.actions';
import * as SearchSelectors from 'src/app/selectors/search.selectors';
import * as StaticDataSelectors from '../../../../selectors/static-data.selectors';
import { getCommonIdSignedInUser } from 'src/app/selectors/common-id/common-id-auth.selectors';
import { getCurrentDateTime as getCurrentDateTimeSelector } from 'src/app/selectors/current-date-time.selectors';
import * as CommonIdAnsweredProblemSelectors from '../../../../selectors/common-id/common-id-answered-problem.selectors';
import { getMatchedProblemCount, getProblemCountSearching } from 'src/app/selectors/search.selectors';
import { RootState } from '../../../../reducers';

// models
import { DepartmentCategory, University } from 'src/app/models/common-data';
import { StaticCommonData } from '../../../../models/static-common-data';
import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import { CurrentDateTime } from 'src/app/models/current-date-time';
import { EnglishSearchCondition } from 'src/app/models/english-search-condition';
import { SearchCondition, SearchConditionTypeForAllSubjects, SearchProblemsCondition } from 'src/app/models/search-condition';
import { ScienceSearchCondition } from 'src/app/models/science-search-condition';
import { NationalLanguageSearchCondition } from 'src/app/models/national-language-search-condition';
import { HistorySearchCondition } from 'src/app/models/history-search-condition';
import { EnglishProblem, HistoryProblem, NationalLanguageProblem, ScienceProblem } from 'src/app/models/problem';
import { StaticEnglishData } from 'src/app/models/static-english-data';
import { StaticScienceData } from 'src/app/models/static-science-data';
import { StaticNationalLanguageData } from 'src/app/models/static-national-language-data';
import { StaticHistoryData } from 'src/app/models/static-history-data';
import { CommonIdAnsweredProblems } from 'src/app/models/common-id/common-id-answered-problem';
import {
  EnglishProblemSearchResult,
  ScienceProblemSearchResult,
  NationalLanguageProblemSearchResult,
  HistoryProblemSearchResult
} from 'src/app/models/common-id/common-id-search-result';
import {
  EnglishSearchProblemsConditionQueryParams,
  HistorySearchProblemsConditionQueryParams,
  NationalLanguageSearchProblemsConditionQueryParams,
  ScienceSearchProblemsConditionQueryParams
} from 'src/app/models/query-params';

// components & services
import { CommonIdSearchByCategoriesResultComponent } from '../search-by-categories-result/search-by-categories-result.component';
import { CommonIdSearchByCategoriesRouterService } from './../../../../services/common-id/common-id-search-by-categories-router.service';

// utils
import { Log } from 'src/app/utils/log';
import { SubjectUtil } from 'src/app/utils/subject-util';
import { CommonIdUserUtil } from 'src/app/utils/common-id/common-id-user-util';

// config
import { COMMON_ID_FREE_YEARS, DEFAULT_DEPARTMENT_CATEGORY } from '../../../../resources/common-id-config';
import { CATEGORY_DELIMITER, SortType, SortTypeDisplayName, SubjectId } from 'src/app/resources/config';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';

// mapper
import { QueryParamsMapper } from 'src/app/mappers/query-params-mapper';
import { ReadableDataMapper } from 'src/app/mappers/readable-data-mapper';

interface SelectableSortType {
  type: SortType;
  displayName: string;
}

@Component({
  selector: 'app-common-id-search-by-categories',
  templateUrl: './search-by-categories.component.html',
  styleUrls: ['./search-by-categories.component.scss']
})
export class CommonIdSearchByCategoriesComponent implements OnInit, OnDestroy, AfterViewInit {
  factory: ComponentFactory<CommonIdSearchByCategoriesResultComponent>;
  @ViewChild('searchByCategoriesResult', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;
  searchResultComponent: ComponentRef<CommonIdSearchByCategoriesResultComponent>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store<RootState>,
    private router: Router,
    private commonIdSearchByCategoriesRouterService: CommonIdSearchByCategoriesRouterService
  ) {}

  private LOG_SOURCE = this.constructor.name;
  private title = '分野で探す';

  private englishCategorySelectionEmittedSubsription: Subscription;
  private scienceCategorySelectionEmittedSubsription: Subscription;
  private nationalLanguageCategorySelectionEmittedSubsription: Subscription;
  private historyCategorySelectionEmittedSubsription: Subscription;
  private problemCountSubscription: Subscription;
  private routerSubscription: Subscription;

  defaultDepartmentCategory: DepartmentCategory = DEFAULT_DEPARTMENT_CATEGORY;

  isSearchSummaryDisplayed = false;
  isSearchResultsShown: boolean;
  isProblemsSearching$: Observable<boolean>;
  isShowMoreButtonShown$: Observable<boolean>;
  pageButton: number;
  searchButtonDisabled$: Observable<boolean>;

  currentDateTime$: Observable<CurrentDateTime>;
  signedInUser$: Observable<CommonIdUser>;
  isPremiumUser: boolean;

  matchedProblemCount$: Observable<number>;
  matchedProblemCount: number | undefined;
  problemCountSearching$: Observable<boolean>;

  staticCommonData$: Observable<StaticCommonData>;
  staticCommonData: StaticCommonData;

  staticEnglishData$: Observable<StaticEnglishData>;
  staticMathData$: Observable<StaticScienceData>;
  staticNationalLanguageData$: Observable<StaticNationalLanguageData>;
  staticPhysicsData$: Observable<StaticScienceData>;
  staticChemistryData$: Observable<StaticScienceData>;
  staticBiologyData$: Observable<StaticScienceData>;
  staticJapaneseHistoryData$: Observable<StaticHistoryData>;
  staticWorldHistoryData$: Observable<StaticHistoryData>;
  staticGeographyData$: Observable<StaticScienceData>;
  staticPoliticalEconomyData$: Observable<StaticScienceData>;

  pageToSearch: number;
  currentPage: number;

  currentUrl: string;

  // 選択された条件
  selectedSearchCondition: SearchConditionTypeForAllSubjects;
  selectedUniversities: University[] = [];
  selectedYear: string | null;
  selectedDepartmentCategory: DepartmentCategory;
  selectedSubjectId: string;
  defaultSubjectId: string;

  // 検索結果
  searchResults:
    | EnglishProblemSearchResult[]
    | ScienceProblemSearchResult[]
    | NationalLanguageProblemSearchResult[]
    | HistoryProblemSearchResult[] = [];

  // API findProblemsのレスポンス
  englishProblems$: Observable<EnglishProblem[]>;
  mathProblems$: Observable<ScienceProblem[]>;
  nationalLanguageProblems$: Observable<NationalLanguageProblem[]>;
  physicsProblems$: Observable<ScienceProblem[]>;
  chemistryProblems$: Observable<ScienceProblem[]>;
  biologyProblems$: Observable<ScienceProblem[]>;
  japaneseHistoryProblems$: Observable<HistoryProblem[]>;
  worldHistoryProblems$: Observable<HistoryProblem[]>;
  geographyProblems$: Observable<ScienceProblem[]>;
  politicalEconomyProblems$: Observable<ScienceProblem[]>;

  // 並び順
  selectableSortTypes: SelectableSortType[];
  selectedSortType: SelectableSortType;
  defaultSortType: SelectableSortType;

  // クエリパラメタ
  queryParams:
    | EnglishSearchProblemsConditionQueryParams
    | ScienceSearchProblemsConditionQueryParams
    | NationalLanguageSearchProblemsConditionQueryParams
    | HistorySearchProblemsConditionQueryParams;
  // クエリパラメタより検索条件を設定
  conditionsFromQueryParameters: SearchConditionTypeForAllSubjects;

  // 解答済みフラグを表示するため
  answeredProblems$: Observable<CommonIdAnsweredProblems>;

  // 結果を表示ボタンが押された場合のフラグ
  isShowSearchResultButtonClicked: boolean;
  // 条件をリセットボタンが押された場合のフラグ
  isResetButtonClicked: boolean;
  // 並び順が変更された場合のフラグ
  isSortTypeChanged: boolean;

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));

    // 動的に検索結果コンポーネントをコンパイルできる状態にする
    this.factory = this.resolver.resolveComponentFactory(CommonIdSearchByCategoriesResultComponent);

    this.setUpDefaultSubjectId();
    this.setUpCurrentDateTime();
    this.setUpUser();
    this.findAnsweredProblems();
    this.setUpAnsweredProblems();
    this.setUpStaticCommonData();
    this.setUpStaticSubjectData();
    this.setUpMatchedProblemCount();
    this.initializeSearchResults();
    this.initializeSearchSummaryDisplayed();
    this.setUpIsProblemsSearching();
    // 並び順の設定
    this.setUpSelectableSortTypes();

    // URL全体の変更を監視 - 変更があるたびに、クエリパラメタから検索条件を取得し、その条件で検索結果を表示
    this.routerSubscription = this.router.events.subscribe(() => {
      if (this.currentUrl !== this.router.url) {
        this.currentUrl = this.router.url;
        const sortType = this.commonIdSearchByCategoriesRouterService.getSortTypeFromQueryParams(this.currentUrl);
        this.selectedSearchCondition = this.commonIdSearchByCategoriesRouterService.getConditionFromQueryParams(this.currentUrl);

        // クエリパラメタなしの場合 - 他のメニューから移動してきた場合やブラウザの戻るボタンが押された場合
        if (this.selectedSearchCondition === undefined || Object.keys(this.selectedSearchCondition).length === 0) {
          this.reset(this.selectedSubjectId);
          // 条件がリセットされた場合は結果を表示ボタンを有効化する
          if (this.isResetButtonClicked) {
            this.searchButtonDisabled$ = of(false);
            this.isResetButtonClicked = false;
          }
        } else {
          // クエリパラメタありの場合 - 結果を表示ボタンが押された場合 / 並び順が変更された場合は結果のみをセットする
          if (!this.isShowSearchResultButtonClicked && !this.isSortTypeChanged) {
            this.resetScreen(this.selectedSearchCondition.subjectId);
          }
          this.findProblemsByCondition(this.selectedSearchCondition, sortType);
          this.isShowSearchResultButtonClicked = false;
          this.isSortTypeChanged = false;
        }
      } else {
        // 結果を表示しない状態で条件をリセットボタンが押された場合に、検索結果数をリセットする
        if (this.isResetButtonClicked) this.findProblemCount(this.selectedSubjectId);
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    if (this.problemCountSubscription) this.problemCountSubscription.unsubscribe();
    if (this.englishCategorySelectionEmittedSubsription) this.englishCategorySelectionEmittedSubsription.unsubscribe();
    if (this.scienceCategorySelectionEmittedSubsription) this.scienceCategorySelectionEmittedSubsription.unsubscribe();
    if (this.nationalLanguageCategorySelectionEmittedSubsription) this.nationalLanguageCategorySelectionEmittedSubsription.unsubscribe();
    if (this.historyCategorySelectionEmittedSubsription) this.historyCategorySelectionEmittedSubsription.unsubscribe();
    this.routerSubscription.unsubscribe();

    this.store.dispatch(SearchActions.initializeProblemCountState());
    this.store.dispatch(SearchActions.initializeProblemsState());
    this.store.dispatch(CommonIdAnsweredProblemActions.initializeCommonIdFindAnsweredProblems());

    // 検索結果コンポーネントの破棄
    if (this.searchResultComponent) {
      this.searchResultComponent.destroy();
    }
  }

  onClickReset(subjectId: string) {
    // リセットボタンが押された場合
    this.isResetButtonClicked = true;

    this.selectedSubjectId = subjectId;
    this.selectedSearchCondition = { subjectId };
    const url = RoutingPathResolver.resolveCommonIdSearchByCategories();
    this.store.dispatch(navigate({ url }));
  }

  onChangeSubject(subjectId: string) {
    // 教科の変更時
    this.reset(subjectId);
  }

  showSearchResultView(event: SearchConditionTypeForAllSubjects) {
    this.isShowSearchResultButtonClicked = true;
    this.searchButtonDisabled$ = of(true);
    this.selectedSearchCondition = event;
    this.selectedSubjectId = event.subjectId;

    // 検索結果用の配列を初期化する
    this.initializeSearchResults();

    // SP時、条件サマリー欄を表示する - 通常の条件欄は非表示にする
    this.initializeSearchSummaryDisplayed();

    // クエリパラメタに選択された条件を追加して結果を表示
    this.navigateToCommonIdSearchByCategories();
  }

  onChangeValues(event: SearchConditionTypeForAllSubjects) {
    // 現在の選択条件を取得して保存
    this.selectedSearchCondition = event;
    this.selectedSubjectId = event.subjectId;

    Log.debug(this.LOG_SOURCE, '現在の選択状況: ', this.selectedSearchCondition);

    this.selectedSortType = this.defaultSortType;
    Log.debug(this.LOG_SOURCE, '現在の並び順: ', this.selectedSortType);

    // 検索条件が変わる度に、検索結果欄を非表示にする
    this.initializeResultsComponent();
    this.isSearchResultsShown = false;
    this.isShowMoreButtonShown$ = of(false);

    // 問題数を検索するAPIを呼び出す
    this.findProblemCount(this.selectedSubjectId);

    // 検索結果が０件の場合は検索ボタンをクリック不可にする
    this.setSearchButtonDisabled();

    // 検索結果が０件の場合は現在のURLの保持する変数をクリアする - 検索条件を入れて結果が0件になった場合、一個前の検索条件を入れるとURLが同じになり処理がされないため
    this.clearCurrentUrl();
  }

  findProblemCount(subjectId: string) {
    // 問題画像がある問題のみ検索
    if (!this.selectedSearchCondition.hasExternalData) this.selectedSearchCondition.hasExternalData = true;

    if (subjectId === SubjectId.ENGLISH) {
      this.findEnglishProblemCount(this.selectedSearchCondition as SearchCondition<EnglishSearchCondition>);
    }
    if (subjectId === SubjectId.MATH) this.findMathProblemCount(this.selectedSearchCondition as SearchCondition<ScienceSearchCondition>);
    if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
      this.findNationalLanguageProblemCount(this.selectedSearchCondition as SearchCondition<NationalLanguageSearchCondition>);
    }
    if (subjectId === SubjectId.PHYSICS) {
      this.findPhysicsProblemCount(this.selectedSearchCondition as SearchCondition<ScienceSearchCondition>);
    }
    if (subjectId === SubjectId.CHEMISTRY) {
      this.findChemistryProblemCount(this.selectedSearchCondition as SearchCondition<ScienceSearchCondition>);
    }
    if (subjectId === SubjectId.BIOLOGY) {
      this.findBiologyProblemCount(this.selectedSearchCondition as SearchCondition<ScienceSearchCondition>);
    }
    if (subjectId === SubjectId.JAPANESE_HISTORY) {
      this.findJapaneseHistoryProblemCount(this.selectedSearchCondition as SearchCondition<HistorySearchCondition>);
    }
    if (subjectId === SubjectId.WORLD_HISTORY) {
      this.findWorldHistoryProblemCount(this.selectedSearchCondition as SearchCondition<HistorySearchCondition>);
    }
    if (subjectId === SubjectId.GEOGRAPHY) {
      this.findGeographyProblemCount(this.selectedSearchCondition as SearchCondition<ScienceSearchCondition>);
    }
    if (subjectId === SubjectId.POLITICAL_ECONOMY) {
      this.findPoliticalEconomyProblemCount(this.selectedSearchCondition as SearchCondition<ScienceSearchCondition>);
    }
  }

  showMoreResults() {
    this.pageToSearch = this.currentPage + 1;

    // 検索結果用の配列を初期化する
    this.initializeSearchResults();

    this.findProblems(this.pageToSearch);
  }

  onChangeSortType() {
    this.isSortTypeChanged = true;

    // 並び順の変更後、結果が用意できるまで検索結果欄は表示しない（クエリパラメタがある場合を除く）
    if (!this.conditionsFromQueryParameters) this.isSearchResultsShown = false;

    // もっと見るボタンは表示しない
    this.isShowMoreButtonShown$ = of(false);

    // 検索結果用の配列を初期化する
    this.initializeSearchResults();

    // クエリパラメタの並び順を変更して結果を表示
    this.navigateToCommonIdSearchByCategories();
  }

  private callFindProblems(page: number) {
    // 問題画像がある問題のみ検索
    if (!this.selectedSearchCondition.hasExternalData) this.selectedSearchCondition.hasExternalData = true;

    if (this.selectedSubjectId === SubjectId.ENGLISH) {
      const currentCondition = this.selectedSearchCondition as SearchCondition<EnglishSearchCondition>;
      const condition: SearchProblemsCondition<EnglishSearchCondition> = {
        ...currentCondition,
        sortType: this.selectedSortType.type,
        page
      };
      this.store.dispatch(SearchActions.findEnglishProblems({ condition }));
    }

    if (this.selectedSubjectId === SubjectId.MATH) {
      const condition = this.setScieneceProblemCondition(this.selectedSearchCondition as SearchCondition<ScienceSearchCondition>, page);
      this.store.dispatch(SearchActions.findMathProblems({ condition }));
    }

    if (this.selectedSubjectId === SubjectId.NATIONAL_LANGUAGE) {
      const currentCondition = this.selectedSearchCondition as SearchCondition<NationalLanguageSearchCondition>;
      const condition: SearchProblemsCondition<NationalLanguageSearchCondition> = {
        ...currentCondition,
        sortType: this.selectedSortType.type,
        page
      };
      this.store.dispatch(SearchActions.findNationalLanguageProblems({ condition }));
    }

    if (this.selectedSubjectId === SubjectId.PHYSICS) {
      const condition = this.setScieneceProblemCondition(this.selectedSearchCondition as SearchCondition<ScienceSearchCondition>, page);
      this.store.dispatch(SearchActions.findPhysicsProblems({ condition }));
    }

    if (this.selectedSubjectId === SubjectId.CHEMISTRY) {
      const condition = this.setScieneceProblemCondition(this.selectedSearchCondition as SearchCondition<ScienceSearchCondition>, page);
      this.store.dispatch(SearchActions.findChemistryProblems({ condition }));
    }

    if (this.selectedSubjectId === SubjectId.BIOLOGY) {
      const condition = this.setScieneceProblemCondition(this.selectedSearchCondition as SearchCondition<ScienceSearchCondition>, page);
      this.store.dispatch(SearchActions.findBiologyProblems({ condition }));
    }

    if (this.selectedSubjectId === SubjectId.JAPANESE_HISTORY) {
      const condition = this.setHistoryProblemCondition(this.selectedSearchCondition as SearchCondition<HistorySearchCondition>, page);
      this.store.dispatch(SearchActions.findJapaneseHistoryProblems({ condition }));
    }

    if (this.selectedSubjectId === SubjectId.WORLD_HISTORY) {
      const condition = this.setHistoryProblemCondition(this.selectedSearchCondition as SearchCondition<HistorySearchCondition>, page);
      this.store.dispatch(SearchActions.findWorldHistoryProblems({ condition }));
    }

    if (this.selectedSubjectId === SubjectId.GEOGRAPHY) {
      const condition = this.setScieneceProblemCondition(this.selectedSearchCondition as SearchCondition<ScienceSearchCondition>, page);
      this.store.dispatch(SearchActions.findGeographyProblems({ condition }));
    }

    if (this.selectedSubjectId === SubjectId.POLITICAL_ECONOMY) {
      const condition = this.setScieneceProblemCondition(this.selectedSearchCondition as SearchCondition<ScienceSearchCondition>, page);
      this.store.dispatch(SearchActions.findPoliticalEconomyProblems({ condition }));
    }
  }

  private setScieneceProblemCondition(
    searchCondition: SearchCondition<ScienceSearchCondition>,
    page: number
  ): SearchProblemsCondition<ScienceSearchCondition> {
    const currentCondition = searchCondition;
    const condition: SearchProblemsCondition<ScienceSearchCondition> = {
      ...currentCondition,
      sortType: this.selectedSortType.type,
      page
    };

    return condition;
  }

  private setHistoryProblemCondition(
    searchCondition: SearchCondition<HistorySearchCondition>,
    page: number
  ): SearchProblemsCondition<HistorySearchCondition> {
    const currentCondition = searchCondition;
    const condition: SearchProblemsCondition<HistorySearchCondition> = {
      ...currentCondition,
      sortType: this.selectedSortType.type,
      page
    };

    return condition;
  }

  private setFirstPageSearchResults() {
    // APIから検索結果を取得する
    this.pageToSearch = 1;
    this.findProblems(this.pageToSearch);
  }

  private findProblems(page: number) {
    Log.debug(this.LOG_SOURCE, `選択された page: ${page}`);
    this.currentPage = page;

    this.store.dispatch(SearchActions.initializeProblemsState());
    this.callFindProblems(page);
    this.setUpSearchResults();
  }

  private setUpSearchResults() {
    if (!this.selectedSubjectId) Log.debug(this.LOG_SOURCE, `*** 科目が設定されていません ***`);
    if (this.selectedSubjectId === SubjectId.ENGLISH) this.setUpEnglishSearchResults();
    if (this.selectedSubjectId === SubjectId.MATH) this.setUpMathSearchResults();
    if (this.selectedSubjectId === SubjectId.NATIONAL_LANGUAGE) this.setUpNationalLanguageSearchResults();
    if (this.selectedSubjectId === SubjectId.PHYSICS) this.setUpPhysicsSearchResults();
    if (this.selectedSubjectId === SubjectId.CHEMISTRY) this.setUpChemistrySearchResults();
    if (this.selectedSubjectId === SubjectId.BIOLOGY) this.setUpBiologySearchResults();
    if (this.selectedSubjectId === SubjectId.JAPANESE_HISTORY) this.setUpJapaneseHistorySearchResults();
    if (this.selectedSubjectId === SubjectId.WORLD_HISTORY) this.setUpWorldHistorySearchResults();
    if (this.selectedSubjectId === SubjectId.GEOGRAPHY) this.setUpGeographySearchResults();
    if (this.selectedSubjectId === SubjectId.POLITICAL_ECONOMY) this.setUpPoliticalEconomySearchResults();
  }

  private setUpEnglishSearchResults() {
    this.englishProblems$ = this.store.select(SearchSelectors.getEnglishProblems).pipe(filter(it => it != null));
    combineLatest([this.englishProblems$, this.staticCommonData$, this.staticEnglishData$, this.answeredProblems$])
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(([englishProblems, staticCommonData, staticEnglishData, answeredProblems]) => {
        this.searchResults = englishProblems.map(englishProblem => {
          const subjectId = englishProblem.id.slice(6, 8);
          const subjectName = SubjectUtil.getName(staticCommonData.subjects, subjectId);
          const subjectLabelStyleName = SubjectUtil.getSubjectLabelStyle(subjectId);
          const daimonNumber = parseInt(englishProblem.id.slice(10, 12), 0);
          const unitIds: string[] = englishProblem.categories.map(category => {
            const splitIds = category.split(CATEGORY_DELIMITER);
            return splitIds[2];
          });

          const problemSummary: string = unitIds
            .map(unitId => {
              return ReadableDataMapper.getEnglishBunyaUnitName(unitId, staticEnglishData);
            })
            .join(', ');

          const problemYear = '20' + englishProblem.id.slice(0, 2);
          const isPremiumMemberOnly = this.isPremiumUser || COMMON_ID_FREE_YEARS.includes(problemYear) ? false : true;

          const isAnswered =
            answeredProblems.problems && answeredProblems.problems.find(answeredProblem => answeredProblem.pId === englishProblem.id)
              ? true
              : false;

          return {
            ...englishProblem,
            universityName: staticCommonData.universities.find(university => university.id === englishProblem.universityId).name,
            levelName: staticCommonData.levels.find(level => level.level === englishProblem.level).displayName,
            daimonNumber,
            problemSummary,
            subjectName,
            subjectLabelStyleName,
            isPremiumMemberOnly,
            isAnswered
          };
        });

        this.setUpShowMoreButtonAndResultComponent();
      });
  }

  private setUpMathSearchResults() {
    this.mathProblems$ = this.store.select(SearchSelectors.getMathProblems).pipe(filter(it => it != null));
    combineLatest([this.mathProblems$, this.staticCommonData$, this.staticMathData$, this.answeredProblems$])
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(([mathProblems, staticCommonData, staticMathData, answeredProblems]) => {
        this.searchResults = mathProblems.map(mathProblem => {
          const subjectId = mathProblem.id.slice(6, 8);
          const subjectName = SubjectUtil.getName(staticCommonData.subjects, subjectId);
          const subjectLabelStyleName = SubjectUtil.getSubjectLabelStyle(subjectId);
          const daimonNumber = parseInt(mathProblem.id.slice(10, 12), 0);
          const unitIds: string[] = mathProblem.categories.map(category => {
            const splitIds = category.split(CATEGORY_DELIMITER);
            return splitIds[2];
          });

          const problemSummary: string = unitIds
            .map(unitId => {
              return ReadableDataMapper.getScienceUnitName(unitId, staticMathData);
            })
            .join(', ');

          const problemYear = '20' + mathProblem.id.slice(0, 2);
          const isPremiumMemberOnly = this.isPremiumUser || COMMON_ID_FREE_YEARS.includes(problemYear) ? false : true;

          const isAnswered =
            answeredProblems.problems && answeredProblems.problems.find(answeredProblem => answeredProblem.pId === mathProblem.id)
              ? true
              : false;

          return {
            ...mathProblem,
            universityName: staticCommonData.universities.find(university => university.id === mathProblem.universityId).name,
            levelName: staticCommonData.levels.find(level => level.level === mathProblem.level).displayName,
            daimonNumber,
            problemSummary,
            subjectName,
            subjectLabelStyleName,
            isPremiumMemberOnly,
            isAnswered
          };
        });
        this.setUpShowMoreButtonAndResultComponent();
      });
  }

  private setUpNationalLanguageSearchResults() {
    this.nationalLanguageProblems$ = this.store.select(SearchSelectors.getNationalLanguageProblems).pipe(filter(it => it != null));
    combineLatest([this.nationalLanguageProblems$, this.staticCommonData$, this.staticNationalLanguageData$, this.answeredProblems$])
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(([nationalLanguageProblems, staticCommonData, staticNationalLanguageData, answeredProblems]) => {
        // 同じ問題IDの結果を1件にまとめる
        const aggregatedProblems = nationalLanguageProblems.reduce<NationalLanguageProblem[][]>((acc, it) => {
          const element = acc.find(problems => problems[0].id === it.id);
          if (element) acc[acc.indexOf(element)].push(it);
          else acc.push([it]);
          return acc;
        }, []);

        // 1件にしたデータのカテゴリーの内容を１つにまとめて配列を作成
        const editedProblemsArray = aggregatedProblems.map(aggregatedProblem => {
          if (aggregatedProblem.length > 1) {
            const categories: string[] = [];
            aggregatedProblem.forEach(problem => {
              problem.categories.forEach(category => {
                if (!categories.includes(category)) {
                  categories.push(category);
                }
              });
            });
            return { ...aggregatedProblem[0], categories };
          } else {
            return aggregatedProblem[0];
          }
        });

        // 上記で編集した配列から検索結果を作成
        this.searchResults = editedProblemsArray.map(nationalLanguageProblem => {
          const subjectId = nationalLanguageProblem.id.slice(6, 8);
          const subjectName = SubjectUtil.getName(staticCommonData.subjects, subjectId);
          const subjectLabelStyleName = SubjectUtil.getSubjectLabelStyle(subjectId);
          const daimonNumber = parseInt(nationalLanguageProblem.id.slice(10, 12), 0);
          // 重複している分野は表示しない
          const unitIds: string[] = nationalLanguageProblem.categories
            .filter((item, index) => nationalLanguageProblem.categories.indexOf(item) === index)
            .map(category => {
              const splitIds = category.split(CATEGORY_DELIMITER);
              return splitIds[2];
            });

          const problemSummary: string = unitIds
            // ジャンルのみ表示
            .filter(unitId => {
              return unitId.startsWith('2') ? false : true;
            })
            .map(unitId => {
              return ReadableDataMapper.getNationalLanguageUnitName(unitId, staticNationalLanguageData);
            })
            .join(', ');

          const problemYear = '20' + nationalLanguageProblem.id.slice(0, 2);
          const isPremiumMemberOnly = this.isPremiumUser || COMMON_ID_FREE_YEARS.includes(problemYear) ? false : true;

          const isAnswered =
            answeredProblems.problems &&
            answeredProblems.problems.find(answeredProblem => answeredProblem.pId === nationalLanguageProblem.id)
              ? true
              : false;

          return {
            ...nationalLanguageProblem,
            universityName: staticCommonData.universities.find(university => university.id === nationalLanguageProblem.universityId).name,
            levelName: staticCommonData.levels.find(level => level.level === nationalLanguageProblem.level).displayName,
            daimonNumber,
            problemSummary,
            subjectName,
            subjectLabelStyleName,
            isPremiumMemberOnly,
            isAnswered
          };
        });
        this.setUpShowMoreButtonAndResultComponent();
      });
  }

  private setUpPhysicsSearchResults() {
    this.physicsProblems$ = this.store.select(SearchSelectors.getPhysicsProblems).pipe(filter(it => it != null));
    combineLatest([this.physicsProblems$, this.staticCommonData$, this.staticPhysicsData$, this.answeredProblems$])
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(([physicsProblems, staticCommonData, staticPhysicsData, answeredProblems]) => {
        this.searchResults = physicsProblems.map(physicsProblem => {
          const subjectId = physicsProblem.id.slice(6, 8);
          const subjectName = SubjectUtil.getName(staticCommonData.subjects, subjectId);
          const subjectLabelStyleName = SubjectUtil.getSubjectLabelStyle(subjectId);
          const daimonNumber = parseInt(physicsProblem.id.slice(10, 12), 0);
          const unitIds: string[] = physicsProblem.categories.map(category => {
            const splitIds = category.split(CATEGORY_DELIMITER);
            return splitIds[2];
          });

          const problemSummary: string = unitIds
            .map(unitId => {
              return ReadableDataMapper.getScienceUnitName(unitId, staticPhysicsData);
            })
            .join(', ');

          const problemYear = '20' + physicsProblem.id.slice(0, 2);
          const isPremiumMemberOnly = this.isPremiumUser || COMMON_ID_FREE_YEARS.includes(problemYear) ? false : true;

          const isAnswered =
            answeredProblems.problems && answeredProblems.problems.find(answeredProblem => answeredProblem.pId === physicsProblem.id)
              ? true
              : false;

          return {
            ...physicsProblem,
            universityName: staticCommonData.universities.find(university => university.id === physicsProblem.universityId).name,
            levelName: staticCommonData.levels.find(level => level.level === physicsProblem.level).displayName,
            daimonNumber,
            problemSummary,
            subjectName,
            subjectLabelStyleName,
            isPremiumMemberOnly,
            isAnswered
          };
        });
        this.setUpShowMoreButtonAndResultComponent();
      });
  }

  private setUpChemistrySearchResults() {
    this.chemistryProblems$ = this.store.select(SearchSelectors.getChemistryProblems).pipe(filter(it => it != null));
    combineLatest([this.chemistryProblems$, this.staticCommonData$, this.staticChemistryData$, this.answeredProblems$])
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(([chemistryProblems, staticCommonData, staticChemistryData, answeredProblems]) => {
        this.searchResults = chemistryProblems.map(chemistryProblem => {
          const subjectId = chemistryProblem.id.slice(6, 8);
          const subjectName = SubjectUtil.getName(staticCommonData.subjects, subjectId);
          const subjectLabelStyleName = SubjectUtil.getSubjectLabelStyle(subjectId);
          const daimonNumber = parseInt(chemistryProblem.id.slice(10, 12), 0);
          const unitIds: string[] = chemistryProblem.categories.map(category => {
            const splitIds = category.split(CATEGORY_DELIMITER);
            return splitIds[2];
          });

          const problemSummary: string = unitIds
            .map(unitId => {
              return ReadableDataMapper.getScienceUnitName(unitId, staticChemistryData);
            })
            .join(', ');

          const problemYear = '20' + chemistryProblem.id.slice(0, 2);
          const isPremiumMemberOnly = this.isPremiumUser || COMMON_ID_FREE_YEARS.includes(problemYear) ? false : true;

          const isAnswered =
            answeredProblems.problems && answeredProblems.problems.find(answeredProblem => answeredProblem.pId === chemistryProblem.id)
              ? true
              : false;

          return {
            ...chemistryProblem,
            universityName: staticCommonData.universities.find(university => university.id === chemistryProblem.universityId).name,
            levelName: staticCommonData.levels.find(level => level.level === chemistryProblem.level).displayName,
            daimonNumber,
            problemSummary,
            subjectName,
            subjectLabelStyleName,
            isPremiumMemberOnly,
            isAnswered
          };
        });
        this.setUpShowMoreButtonAndResultComponent();
      });
  }

  private setUpBiologySearchResults() {
    this.biologyProblems$ = this.store.select(SearchSelectors.getBiologyProblems).pipe(filter(it => it != null));
    combineLatest([this.biologyProblems$, this.staticCommonData$, this.staticBiologyData$, this.answeredProblems$])
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(([biologyProblems, staticCommonData, staticBiologyData, answeredProblems]) => {
        this.searchResults = biologyProblems.map(biologyProblem => {
          const subjectId = biologyProblem.id.slice(6, 8);
          const subjectName = SubjectUtil.getName(staticCommonData.subjects, subjectId);
          const subjectLabelStyleName = SubjectUtil.getSubjectLabelStyle(subjectId);
          const daimonNumber = parseInt(biologyProblem.id.slice(10, 12), 0);
          const unitIds: string[] = biologyProblem.categories.map(category => {
            const splitIds = category.split(CATEGORY_DELIMITER);
            return splitIds[2];
          });

          const problemSummary: string = unitIds
            .map(unitId => {
              return ReadableDataMapper.getScienceUnitName(unitId, staticBiologyData);
            })
            .join(', ');

          const problemYear = '20' + biologyProblem.id.slice(0, 2);
          const isPremiumMemberOnly = this.isPremiumUser || COMMON_ID_FREE_YEARS.includes(problemYear) ? false : true;

          const isAnswered =
            answeredProblems.problems && answeredProblems.problems.find(answeredProblem => answeredProblem.pId === biologyProblem.id)
              ? true
              : false;

          return {
            ...biologyProblem,
            universityName: staticCommonData.universities.find(university => university.id === biologyProblem.universityId).name,
            levelName: staticCommonData.levels.find(level => level.level === biologyProblem.level).displayName,
            daimonNumber,
            problemSummary,
            subjectName,
            subjectLabelStyleName,
            isPremiumMemberOnly,
            isAnswered
          };
        });
        this.setUpShowMoreButtonAndResultComponent();
      });
  }

  private setUpJapaneseHistorySearchResults() {
    this.japaneseHistoryProblems$ = this.store.select(SearchSelectors.getJapaneseHistoryProblems).pipe(filter(it => it != null));
    combineLatest([this.japaneseHistoryProblems$, this.staticCommonData$, this.staticJapaneseHistoryData$, this.answeredProblems$])
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(([japaneseHistoryProblems, staticCommonData, staticJapaneseHistoryData, answeredProblems]) => {
        this.searchResults = japaneseHistoryProblems.map(japaneseHistoryProblem => {
          const subjectId = japaneseHistoryProblem.id.slice(6, 8);
          const subjectName = SubjectUtil.getName(staticCommonData.subjects, subjectId);
          const subjectLabelStyleName = SubjectUtil.getSubjectLabelStyle(subjectId);
          const daimonNumber = parseInt(japaneseHistoryProblem.id.slice(10, 12), 0);
          const unitIds: string[] = japaneseHistoryProblem.categories
            // 出題テーマは省く
            .filter(category => {
              return category.startsWith('6') ? false : true;
            })
            .map(category => {
              const splitIds = category.split(CATEGORY_DELIMITER);
              return splitIds[2];
            });

          const problemSummary: string = unitIds
            .map(unitId => {
              return ReadableDataMapper.getHistoryUnitName(unitId, staticJapaneseHistoryData);
            })
            .join(', ');

          const problemYear = '20' + japaneseHistoryProblem.id.slice(0, 2);
          const isPremiumMemberOnly = this.isPremiumUser || COMMON_ID_FREE_YEARS.includes(problemYear) ? false : true;

          const isAnswered =
            answeredProblems.problems &&
            answeredProblems.problems.find(answeredProblem => answeredProblem.pId === japaneseHistoryProblem.id)
              ? true
              : false;

          return {
            ...japaneseHistoryProblem,
            universityName: staticCommonData.universities.find(university => university.id === japaneseHistoryProblem.universityId).name,
            levelName: staticCommonData.levels.find(level => level.level === japaneseHistoryProblem.level).displayName,
            daimonNumber,
            problemSummary,
            subjectName,
            subjectLabelStyleName,
            isPremiumMemberOnly,
            isAnswered
          };
        });
        this.setUpShowMoreButtonAndResultComponent();
      });
  }

  private setUpWorldHistorySearchResults() {
    this.worldHistoryProblems$ = this.store.select(SearchSelectors.getWorldHistoryProblems).pipe(filter(it => it != null));
    combineLatest([this.worldHistoryProblems$, this.staticCommonData$, this.staticWorldHistoryData$, this.answeredProblems$])
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(([worldHistoryProblems, staticCommonData, staticWorldHistoryData, answeredProblems]) => {
        this.searchResults = worldHistoryProblems.map(worldHistoryProblem => {
          const subjectId = worldHistoryProblem.id.slice(6, 8);
          const subjectName = SubjectUtil.getName(staticCommonData.subjects, subjectId);
          const subjectLabelStyleName = SubjectUtil.getSubjectLabelStyle(subjectId);
          const daimonNumber = parseInt(worldHistoryProblem.id.slice(10, 12), 0);
          const unitIds: string[] = worldHistoryProblem.categories
            // 出題テーマは省く
            .filter(category => {
              return category.startsWith('6') ? false : true;
            })
            .map(category => {
              const splitIds = category.split(CATEGORY_DELIMITER);
              return splitIds[2];
            });

          const problemSummary: string = unitIds
            .map(unitId => {
              return ReadableDataMapper.getHistoryUnitName(unitId, staticWorldHistoryData);
            })
            .join(', ');

          const problemYear = '20' + worldHistoryProblem.id.slice(0, 2);
          const isPremiumMemberOnly = this.isPremiumUser || COMMON_ID_FREE_YEARS.includes(problemYear) ? false : true;

          const isAnswered =
            answeredProblems.problems && answeredProblems.problems.find(answeredProblem => answeredProblem.pId === worldHistoryProblem.id)
              ? true
              : false;

          return {
            ...worldHistoryProblem,
            universityName: staticCommonData.universities.find(university => university.id === worldHistoryProblem.universityId).name,
            levelName: staticCommonData.levels.find(level => level.level === worldHistoryProblem.level).displayName,
            daimonNumber,
            problemSummary,
            subjectName,
            subjectLabelStyleName,
            isPremiumMemberOnly,
            isAnswered
          };
        });
        this.setUpShowMoreButtonAndResultComponent();
      });
  }

  private setUpGeographySearchResults() {
    this.geographyProblems$ = this.store.select(SearchSelectors.getGeographyProblems).pipe(filter(it => it != null));
    combineLatest([this.geographyProblems$, this.staticCommonData$, this.staticGeographyData$, this.answeredProblems$])
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(([geographyProblems, staticCommonData, staticGeographyData, answeredProblems]) => {
        this.searchResults = geographyProblems.map(geographyProblem => {
          const subjectId = geographyProblem.id.slice(6, 8);
          const subjectName = SubjectUtil.getName(staticCommonData.subjects, subjectId);
          const subjectLabelStyleName = SubjectUtil.getSubjectLabelStyle(subjectId);
          const daimonNumber = parseInt(geographyProblem.id.slice(10, 12), 0);
          const unitIds: string[] = geographyProblem.categories.map(category => {
            const splitIds = category.split(CATEGORY_DELIMITER);
            return splitIds[2];
          });

          const problemSummary: string = unitIds
            .map(unitId => {
              return ReadableDataMapper.getScienceUnitName(unitId, staticGeographyData);
            })
            .join(', ');

          const problemYear = '20' + geographyProblem.id.slice(0, 2);
          const isPremiumMemberOnly = this.isPremiumUser || COMMON_ID_FREE_YEARS.includes(problemYear) ? false : true;

          const isAnswered =
            answeredProblems.problems && answeredProblems.problems.find(answeredProblem => answeredProblem.pId === geographyProblem.id)
              ? true
              : false;

          return {
            ...geographyProblem,
            universityName: staticCommonData.universities.find(university => university.id === geographyProblem.universityId).name,
            levelName: staticCommonData.levels.find(level => level.level === geographyProblem.level).displayName,
            daimonNumber,
            problemSummary,
            subjectName,
            subjectLabelStyleName,
            isPremiumMemberOnly,
            isAnswered
          };
        });
        this.setUpShowMoreButtonAndResultComponent();
      });
  }

  private setUpPoliticalEconomySearchResults() {
    this.politicalEconomyProblems$ = this.store.select(SearchSelectors.getPoliticalEconomyProblems).pipe(filter(it => it != null));
    combineLatest([this.politicalEconomyProblems$, this.staticCommonData$, this.staticPoliticalEconomyData$, this.answeredProblems$])
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(([politicalEconomyProblems, staticCommonData, staticPoliticalEconomyData, answeredProblems]) => {
        this.searchResults = politicalEconomyProblems.map(politicalEconomyProblem => {
          const subjectId = politicalEconomyProblem.id.slice(6, 8);
          const subjectName = SubjectUtil.getName(staticCommonData.subjects, subjectId);
          const subjectLabelStyleName = SubjectUtil.getSubjectLabelStyle(subjectId);
          const daimonNumber = parseInt(politicalEconomyProblem.id.slice(10, 12), 0);
          const unitIds: string[] = politicalEconomyProblem.categories.map(category => {
            const splitIds = category.split(CATEGORY_DELIMITER);
            return splitIds[2];
          });

          const problemSummary: string = unitIds
            .map(unitId => {
              return ReadableDataMapper.getScienceUnitName(unitId, staticPoliticalEconomyData);
            })
            .join(', ');

          const problemYear = '20' + politicalEconomyProblem.id.slice(0, 2);
          const isPremiumMemberOnly = this.isPremiumUser || COMMON_ID_FREE_YEARS.includes(problemYear) ? false : true;

          const isAnswered =
            answeredProblems.problems &&
            answeredProblems.problems.find(answeredProblem => answeredProblem.pId === politicalEconomyProblem.id)
              ? true
              : false;

          return {
            ...politicalEconomyProblem,
            universityName: staticCommonData.universities.find(university => university.id === politicalEconomyProblem.universityId).name,
            levelName: staticCommonData.levels.find(level => level.level === politicalEconomyProblem.level).displayName,
            daimonNumber,
            problemSummary,
            subjectName,
            subjectLabelStyleName,
            isPremiumMemberOnly,
            isAnswered
          };
        });
        this.setUpShowMoreButtonAndResultComponent();
      });
  }

  private setUpShowMoreButtonAndResultComponent() {
    // 検索結果が１ページに21件以上存在する場合は、もっと見るボタンを表示する
    this.setUpIsShowMoreButton();

    // SP時は、検索条件の縮小版を表示する
    this.isSearchSummaryDisplayed = true;

    // 検索結果コンポーネントを表示する
    if (this.searchResults.length > 0) {
      this.isSearchResultsShown = true;
      this.changeDetectorRef.detectChanges(); // viewContainerRefを強制的に生成する

      this.setUpSearchResultComponent();
    }

    // クエリパラメタの検索条件がある状態で並び順を変更した場合は、クエリパラメタの検索条件を現在の検索条件とし、前回の検索条件をクリアする
    if (this.conditionsFromQueryParameters) {
      this.selectedSubjectId = this.conditionsFromQueryParameters.subjectId;
      this.selectedSearchCondition = this.conditionsFromQueryParameters;
      this.conditionsFromQueryParameters = undefined;
    }
  }

  private setUpUser() {
    this.signedInUser$ = this.store.select(getCommonIdSignedInUser).pipe(
      filter<CommonIdUser>(it => it != null),
      shareReplay(1)
    );

    combineLatest([this.currentDateTime$, this.signedInUser$])
      .pipe(take(1))
      .subscribe(([currentDateTime, signedInUser]) => {
        this.isPremiumUser = CommonIdUserUtil.getIsPremiumUser(currentDateTime, signedInUser);
      });
  }

  private setUpCurrentDateTime() {
    this.store.dispatch(getCurrentDateTime());
    this.currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpStaticCommonData() {
    this.staticCommonData$ = this.store.select(StaticDataSelectors.getStaticCommonData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpStaticSubjectData() {
    this.staticEnglishData$ = this.store.select(StaticDataSelectors.getStaticEnglishData);
    this.staticMathData$ = this.store.select(StaticDataSelectors.getStaticMathData);
    this.staticNationalLanguageData$ = this.store.select(StaticDataSelectors.getStaticNationalLanguageData);
    this.staticPhysicsData$ = this.store.select(StaticDataSelectors.getStaticPhysicsData);
    this.staticChemistryData$ = this.store.select(StaticDataSelectors.getStaticChemistryData);
    this.staticBiologyData$ = this.store.select(StaticDataSelectors.getStaticBiologyData);
    this.staticJapaneseHistoryData$ = this.store.select(StaticDataSelectors.getStaticJapaneseHistoryData);
    this.staticWorldHistoryData$ = this.store.select(StaticDataSelectors.getStaticWorldHistoryData);
    this.staticGeographyData$ = this.store.select(StaticDataSelectors.getStaticGeographyData);
    this.staticPoliticalEconomyData$ = this.store.select(StaticDataSelectors.getStaticPoliticalEconomyData);
  }

  private setUpDefaultSubjectId() {
    this.defaultSubjectId = SubjectId.ENGLISH;
    this.selectedSubjectId = this.defaultSubjectId;
  }

  private setUpMatchedProblemCount() {
    this.matchedProblemCount$ = this.store.select(getMatchedProblemCount).pipe(shareReplay(1));
    this.problemCountSubscription = this.matchedProblemCount$.subscribe(matchedProblemCount => {
      this.matchedProblemCount = matchedProblemCount;
    });

    this.setSearchButtonDisabled();

    this.problemCountSearching$ = this.store.select(getProblemCountSearching);
  }

  private setSearchButtonDisabled() {
    this.searchButtonDisabled$ = combineLatest([this.matchedProblemCount$]).pipe(
      map(([matchedProblemCount]) => matchedProblemCount == null || matchedProblemCount === 0)
    );
  }

  private initializeSearchResults() {
    this.searchResults = [];
  }

  private initializeSearchSummaryDisplayed() {
    this.isSearchSummaryDisplayed = false;
  }

  private setUpIsProblemsSearching() {
    this.isProblemsSearching$ = this.store.select(SearchSelectors.getProblemsSearching);
  }

  private setUpIsShowMoreButton() {
    const numOfProblemsShown = this.currentPage * 20;
    this.isShowMoreButtonShown$ = combineLatest([this.matchedProblemCount$]).pipe(
      map(([matchedProblemCount]) => matchedProblemCount > numOfProblemsShown && this.searchResults && this.searchResults.length > 0)
    );
    // もっと見るボタンを表示する度に、ローディングアイコンを非表示にする
    this.pageButton = this.pageToSearch;
  }

  private setUpSelectableSortTypes() {
    this.defaultSortType = { type: SortType.D_CODE, displayName: SortTypeDisplayName.D_CODE };
    this.selectableSortTypes = [
      this.defaultSortType,
      { type: SortType.UNIVERSITY_NAME, displayName: SortTypeDisplayName.UNIVERSITY_NAME },
      { type: SortType.YEAR_ASC, displayName: SortTypeDisplayName.YEAR_ASC },
      { type: SortType.YEAR_DESC, displayName: SortTypeDisplayName.YEAR_DESC },
      { type: SortType.LEVEL_ASC, displayName: SortTypeDisplayName.LEVEL_ASC },
      { type: SortType.LEVEL_DESC, displayName: SortTypeDisplayName.LEVEL_DESC }
    ];
    this.selectedSortType = this.defaultSortType;
  }

  private findAnsweredProblems() {
    this.signedInUser$.pipe(take(1)).subscribe(user => {
      this.store.dispatch(CommonIdAnsweredProblemActions.commonIdFindAnsweredProblems({ userId: user.id }));
    });
  }

  private setUpAnsweredProblems() {
    this.answeredProblems$ = this.store
      .select(CommonIdAnsweredProblemSelectors.getCommonIdAnsweredProblem)
      .pipe(filter<CommonIdAnsweredProblems>(it => it !== null));
  }

  private setUpSearchResultComponent() {
    // 検索結果コンポーネントの生成を実行
    this.searchResultComponent = this.viewContainerRef.createComponent(this.factory);

    // データバインディング
    this.searchResultComponent.instance.searchResults = this.searchResults;
    this.searchResultComponent.instance.queryParams = this.queryParams;
  }

  private findProblemsByCondition(condition: SearchConditionTypeForAllSubjects, sortType: string) {
    // SP時、クエリパラメタに条件がある場合は、条件サマリー欄を表示する - 通常の条件欄は非表示にする
    this.isSearchSummaryDisplayed = true;

    Log.debug(this.LOG_SOURCE, `検索条件: `, condition);
    this.selectedSubjectId = condition.subjectId;
    this.selectedSearchCondition = condition;

    // 並び順を設定
    this.selectedSortType = this.selectableSortTypes.find(selectableSortType => {
      return selectableSortType.type === (SortType[sortType] as keyof typeof SortType);
    });
    Log.debug(this.LOG_SOURCE, `並び順: `, this.selectedSortType);

    // 検索結果数を取得
    if (!this.isShowSearchResultButtonClicked && !this.isSortTypeChanged) this.findProblemCount(condition.subjectId);

    // 結果を表示ボタンは無効にする
    this.searchButtonDisabled$ = of(true);

    // クエリパラメタを設定
    this.setQueryParams();

    // 検索結果を取得
    this.setFirstPageSearchResults();
  }

  private findEnglishProblemCount(condition: SearchCondition<EnglishSearchCondition>) {
    setTimeout(() => {
      this.store.dispatch(SearchActions.findEnglishProblemCount({ condition, logging: true }));
    });
  }

  private findMathProblemCount(condition: SearchCondition<ScienceSearchCondition>) {
    setTimeout(() => {
      this.store.dispatch(SearchActions.findMathProblemCount({ condition, logging: true }));
    });
  }

  private findNationalLanguageProblemCount(condition: SearchCondition<NationalLanguageSearchCondition>) {
    setTimeout(() => {
      this.store.dispatch(SearchActions.findNationalLanguageProblemCount({ condition, logging: true }));
    });
  }

  private findPhysicsProblemCount(condition: SearchCondition<ScienceSearchCondition>) {
    setTimeout(() => {
      this.store.dispatch(SearchActions.findPhysicsProblemCount({ condition, logging: true }));
    });
  }

  private findChemistryProblemCount(condition: SearchCondition<ScienceSearchCondition>) {
    setTimeout(() => {
      this.store.dispatch(SearchActions.findChemistryProblemCount({ condition, logging: true }));
    });
  }

  private findBiologyProblemCount(condition: SearchCondition<ScienceSearchCondition>) {
    setTimeout(() => {
      this.store.dispatch(SearchActions.findBiologyProblemCount({ condition, logging: true }));
    });
  }

  private findJapaneseHistoryProblemCount(condition: SearchCondition<HistorySearchCondition>) {
    setTimeout(() => {
      this.store.dispatch(SearchActions.findJapaneseHistoryProblemCount({ condition, logging: true }));
    });
  }

  private findWorldHistoryProblemCount(condition: SearchCondition<HistorySearchCondition>) {
    setTimeout(() => {
      this.store.dispatch(SearchActions.findWorldHistoryProblemCount({ condition, logging: true }));
    });
  }

  private findGeographyProblemCount(condition: SearchCondition<ScienceSearchCondition>) {
    setTimeout(() => {
      this.store.dispatch(SearchActions.findGeographyProblemCount({ condition, logging: true }));
    });
  }

  private findPoliticalEconomyProblemCount(condition: SearchCondition<ScienceSearchCondition>) {
    setTimeout(() => {
      this.store.dispatch(SearchActions.findPoliticalEconomyProblemCount({ condition, logging: true }));
    });
  }

  private initializeResultsComponent() {
    this.initializeSearchResults();
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }
  }

  private setQueryParams() {
    const condition: SearchProblemsCondition<
      EnglishSearchCondition | ScienceSearchCondition | NationalLanguageSearchCondition | HistorySearchCondition
    > = {
      ...this.selectedSearchCondition,
      sortType: this.selectedSortType.type
    };

    this.queryParams = QueryParamsMapper.encodeSearchProblemsCondition(condition);
  }

  private navigateToCommonIdSearchByCategories() {
    const url = RoutingPathResolver.resolveCommonIdSearchByCategories();
    this.setQueryParams();
    this.store.dispatch(navigate({ url, extras: { queryParams: this.queryParams } }));
  }

  private reset(subjectId: string) {
    this.resetScreen(subjectId);
    this.setProblemsCount(subjectId);
  }

  private resetScreen(subjectId: string) {
    // 前回の検索条件をクリア
    this.conditionsFromQueryParameters = undefined;
    // 並び順を初期値に
    this.selectedSortType = this.defaultSortType;

    // リセットした状態で検索条件を設定
    this.selectedSubjectId = subjectId;

    // 結果を表示ボタンを有効にする
    this.searchButtonDisabled$ = of(false);

    this.store.dispatch(SearchActions.initializeProblemCountState());
    this.store.dispatch(SearchActions.initializeProblemsState());

    this.initializeSearchResults();
    this.isSearchResultsShown = false;

    if (this.viewContainerRef) this.viewContainerRef.clear();
    if (this.searchResultComponent) this.searchResultComponent.destroy();
  }

  private setProblemsCount(subjectId: string) {
    // 指定された教科で問題数の検索結果を取得
    this.selectedSearchCondition = { subjectId };
    this.findProblemCount(this.selectedSubjectId);
  }

  private async clearCurrentUrl() {
    combineLatest([this.matchedProblemCount$])
      .pipe(take(1))
      .subscribe(([matchedProblemCount]) => {
        if (matchedProblemCount === 0 || matchedProblemCount === null) this.currentUrl = '';
      });
  }
}
