import { UAParser } from 'ua-parser-js';
import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import {
  COMMON_ID_PURCHASE_DIALOG_HEIGHT,
  COMMON_ID_PURCHASE_DIALOG_WIDTH,
  DELETE_BOOKMARK_DIALOG_WIDTH,
  DIALOG_ZERO_PADDING_PANEL_CLASS
} from '../../../resources/config';
import { CommonIdDescriptionPremiumDialogComponent } from '../common-id/description-premium-dialog/description-premium-dialog.component';
import { CONTACT_GOOGLE_FORM_URL, DESCRIPTION_PREMIUM_DIALOG_WITDH, PurchasePlan } from '../../../resources/common-id-config';
import { CommonIdGetStripeCheckoutUrlRequest } from '../../../models/common-id/common-id-get-stripe-checkout-url-request';
import { environment } from '../../../../environments/environment';
import { CommonIdPurchaseDialogComponent } from '../common-id/purchase-dialog/purchase-dialog.component';
import { WINDOW_OBJECT } from '../../../utils/injection-tokens';
import { CommonIdGotoManabiIdDialogComponent } from '../common-id/goto-manabi-id-dialog/goto-manabi-id-dialog.component';
import { CommonIdPurchaseConfirmDialogComponent } from '../common-id/purchase-confirm-dialog/purchase-confirm-dialog.component';

@Component({
  selector: 'app-style-guide',
  templateUrl: './style-guide.component.html',
  styleUrls: ['./style-guide.component.scss']
})
export class StyleGuideComponent implements OnInit {
  constructor(private dialog: MatDialog, @Inject(WINDOW_OBJECT) private window: Window) {}

  // モックのテーブル出力用 とりあえず 3個出すだけの配列
  mockDataSource: any[] = [{}, {}, {}];

  //
  mockDisplayedColumnsPlanHistory: string[] = ['planStatus', 'validityPeriod', 'memberNum', 'memberList', 'action'];

  // 所属メンバー一覧
  mockDisplayedColumnsAffiliatedMember: string[] = [
    'memberName',
    'memberNameKana',
    'mail',
    'memberType',
    'assignedPlan',
    'exclusion',
    'delete'
  ];

  // プラン一覧
  mockDisplayedColumnsPlan: string[] = ['planName', 'memberNum', 'memberNames', 'contractStatus', 'action'];

  mockPlaylistTags: string[] = ['タグ１', 'タグ２'];

  ngOnInit() {}

  showModal() {
    const config: MatDialogConfig = {
      width: DESCRIPTION_PREMIUM_DIALOG_WITDH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: false,
      disableClose: true
    };
    this.dialog.open(CommonIdDescriptionPremiumDialogComponent, config);
  }

  showManabiIdModal() {
    const config: MatDialogConfig = {
      width: '500px',
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: false,
      disableClose: true
    };
    this.dialog.open(CommonIdGotoManabiIdDialogComponent, config);
  }

  showPurchaseConfirmModal() {
    const data: CommonIdGetStripeCheckoutUrlRequest = {
      planId: PurchasePlan.ANNUAL,
      isLocal: false
    };
    const config: MatDialogConfig = {
      width: COMMON_ID_PURCHASE_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: true,
      data,
      disableClose: true
    };
    this.dialog.open(CommonIdPurchaseConfirmDialogComponent, config);
  }

  gotoGoogleForm() {
    const userAgent = new UAParser(this.window.navigator.userAgent).getResult();
    const url = CONTACT_GOOGLE_FORM_URL.replace('<user-id>', 'test-user-id')
      .replace('<user-agent-os>', userAgent.os.name)
      .replace('<user-agent-browser>', userAgent.browser.name);
    this.window.open(url, '_blank');
  }
}
