import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';

import { navigate, setTitle } from 'src/app/actions/core.actions';
import { setBrowserTitle } from 'src/app/actions/core.actions';
import { findStaticData, initializeStaticDataState } from 'src/app/actions/static-data.actions';
import { findProblemImages } from 'src/app/actions/kakomon-image-search.actions';
import { RootState } from 'src/app/reducers';
import { getKakomonImage } from 'src/app/selectors/kakomon-image-search.selector';
import { getFetchedDate } from 'src/app/selectors/static-data.selectors';
import { getUniversities } from 'src/app/selectors/static-data.selectors';

import { KAKOMON_ALLOW_CODE_LIST, STATIC_DATA_CACHE_DAYS, SUBJECT_NAMES } from 'src/app/resources/config';
import { environment } from 'src/environments/environment';
import { ImageType } from 'src/app/models/kakomon-image-search';
import { ProblemImageUtil } from 'src/app/utils/problem-image-util';
import { Log } from 'src/app/utils/log';
import { Dates } from 'src/app/utils/dates';
import { WINDOW_OBJECT } from 'src/app/utils/injection-tokens';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';

@Component({
  selector: 'app-kakomon-image',
  templateUrl: './kakomon-image.component.html',
  styleUrls: ['./kakomon-image.component.scss']
})
export class KakomonImageComponent implements OnInit {
  private LOG_SOURCE = this.constructor.name;
  private title = '';

  loaded$: Observable<boolean>;
  paramType$: Observable<ImageType>;
  questionImages$: Observable<string[]>;
  answerImages$: Observable<string[]>;
  isError$: Observable<boolean>;

  constructor(private store: Store<RootState>, private route: ActivatedRoute, @Inject(WINDOW_OBJECT) private window: Window) {}

  ngOnInit() {
    this.setUp();

    const params$ = this.route.queryParams.pipe(filter(it => it != null));
    const universities$ = this.store.select(getUniversities).pipe(filter(it => it != null));
    combineLatest([universities$, params$])
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(([universities, params]) => {
        // コードのチェック
        if (!KAKOMON_ALLOW_CODE_LIST.includes(params.code)) {
          const url = RoutingPathResolver.resolveTop();
          this.store.dispatch(navigate({ url, extras: { replaceUrl: true } }));
          return;
        }

        const problemId = params.paperId + params.daimon_Id;
        this.store.dispatch(findProblemImages({ problemId }));

        const universityId = params.paperId.toString().substring(2, 6);
        const university = universities.find(item => item.id === `D${universityId}`);
        const subject = SUBJECT_NAMES[params.paperId.toString().substring(6, 8)];
        const imageType = params.type === 'a' ? '解答' : '問題';
        this.title = `${university.name} ${subject} ${imageType}`;
        this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
        setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
      });
  }

  /* クリック時の処理は不要なのでコメントアウト
  clickImage(src: string) {
    this.window.parent.postMessage(
      {
        type: 'click',
        target: this.getLambdaImagePath(src)
      },
      '*'
    );
  }
  */

  private setUp() {
    this.findStaticDataIfNeeded();

    this.loaded$ = this.store.select(getKakomonImage).pipe(
      map(it => it != null),
      startWith(false)
    );

    this.paramType$ = this.route.queryParams.pipe(
      filter(it => it != null),
      take(1),
      map(params => (params.type === 'a' ? 'a' : 'q') as ImageType)
    );

    this.questionImages$ = this.store.select(getKakomonImage).pipe(
      filter(it => it != null),
      take(1),
      map(kakomonImages => kakomonImages.questionImages.map(image => this.getImagePath(image)))
    );

    this.answerImages$ = this.store.select(getKakomonImage).pipe(
      filter(it => it != null),
      take(1),
      map(kakomonImages => kakomonImages.answerImages.map(image => this.getImagePath(image)))
    );

    this.isError$ = combineLatest([this.paramType$, this.questionImages$, this.answerImages$]).pipe(
      filter(it => it != null),
      take(1),
      map(
        ([paramType, questionImages, answerImages]) =>
          (paramType === 'q' && questionImages.length === 0) || (paramType === 'a' && answerImages.length === 0)
      )
    );

    // this.isError$.subscribe(isError => {
    //   if (isError) {
    //     this.window.parent.postMessage(
    //       {
    //         type: 'invalid_page'
    //       },
    //       '*'
    //     );
    //   }
    // });

    // const kakomonImages$ = this.store.select(getKakomonImage).pipe(
    //   filter(it => it != null),
    //   take(1)
    // );
    // combineLatest([this.paramType$, kakomonImages$])
    //   .pipe(
    //     filter(it => it != null),
    //     take(1)
    //   )
    //   .subscribe(([paramType, kakomonImages]) => {
    //     const targetImages = paramType === 'q' ? kakomonImages.questionImages : kakomonImages.answerImages;
    //     const images = targetImages.map(image => this.getLambdaImagePath(image));
    //     if (images.length > 0) {
    //       this.window.parent.postMessage(
    //         {
    //           type: 'init',
    //           images
    //         },
    //         '*'
    //       );
    //     }
    //   });
  }

  private getImagePath(image: string): string {
    const path = ProblemImageUtil.getPath(image, false);
    return `${environment.cloudfrontHost}${path}`;
  }

  // private getLambdaImagePath(image: string): string {
  //   const splitedPath = image.split('/');
  //   const fileName = splitedPath[splitedPath.length - 1];
  //   const path = ProblemImageUtil.getPath(fileName, false);
  //   return `${environment.lambdaHost}?key=${path}`;
  // }

  private findStaticDataIfNeeded() {
    this.store
      .select(getFetchedDate)
      .pipe(take(1))
      .subscribe(fetchedDate => {
        if (!fetchedDate) {
          Log.debug(this.LOG_SOURCE, `static data が存在していないため取得します`);
          this.store.dispatch(findStaticData());
          return;
        }
        const cacheExpired = Dates.isCachedDateExpired(fetchedDate, STATIC_DATA_CACHE_DAYS);
        if (cacheExpired) {
          Log.debug(this.LOG_SOURCE, `cache 期間を超過したため再度 static data を取得します. fetchedDate: ${fetchedDate}`);
          this.store.dispatch(initializeStaticDataState());
          this.store.dispatch(findStaticData());
          return;
        }
        Log.debug(this.LOG_SOURCE, 'static data が取得済みのため何もしません');
      });
  }
}
