import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AlertBoxComponent } from '../../../widgets/alert-box/alert-box.component';

@Component({
  selector: 'app-common-id-bookmark-expiration-warning',
  templateUrl: './bookmark-expiration-warning.component.html',
  styleUrls: ['./bookmark-expiration-warning.component.scss']
})
export class CommonIdBookmarkExpirationWarningComponent implements OnInit, AfterViewInit {
  @ViewChild(AlertBoxComponent) alertBox: AlertBoxComponent;
  isWarningVisible = true;

  private readonly storageKey = 'bookmarkExpirationWarningClosedAt-20250327';
  private readonly hideDuration = 99999 * 24 * 60 * 60 * 1000; // 99999日 = 無期限

  ngOnInit() {
    this.checkWarningVisibility();
  }

  ngAfterViewInit() {
    // AlertBoxのcloseAlert()が呼ばれた後に実行される処理を追加
    if (this.alertBox) {
      const originalCloseAlert = this.alertBox.closeAlert;
      this.alertBox.closeAlert = () => {
        originalCloseAlert.call(this.alertBox);
        this.handleWarningClosed();
      };
    }
  }

  private checkWarningVisibility() {
    const closedAt = localStorage.getItem(this.storageKey);
    if (closedAt) {
      const closedTime = parseInt(closedAt, 10);
      const currentTime = Date.now();
      if (currentTime - closedTime < this.hideDuration) {
        this.isWarningVisible = false;
      }
    }
  }

  private handleWarningClosed() {
    const currentTime = Date.now();
    localStorage.setItem(this.storageKey, currentTime.toString());
  }
}
