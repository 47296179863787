<!-- ▼ 印刷時のみ表示 ここから -->
<div class="problem-detail-show-print-only-wrapper" data-print-only *ngIf="(problem$ | async)?.examPaper">
  <app-problem-detail-frame-for-print
    [menuType]="menuType$ | async"
    [readableScienceProblems]="readableScienceProblems$ | async"
    [readableEnglishProblems]="readableEnglishProblems$ | async"
    [readableNationalLanguageProblems]="readableNationalLanguageProblems$ | async"
    [readableJapaneseHistoryProblems]="readableJapaneseHistoryProblems$ | async"
    [readableWorldHistoryProblems]="readableWorldHistoryProblems$ | async"
    [paperMode]="paperMode$ | async"
    [trialMode]="trialMode$ | async"
    [subjectId]="subjectId$ | async"
    [isBToC]="false"
    [signedInUser]="signedInUser$ | async"
  ></app-problem-detail-frame-for-print>
</div>
<!-- △ 印刷時のみ表示 ここまで -->
<!-- ▼ 印刷時は消す ここから -->
<div data-no-print>
  <div *ngIf="!isPreconditionError">
    <div [@fadeInOut]="(searching$ | async) === false">
      <!-- ▼ 前へ/次へボタン 試験単位では消す -->
      <app-content-row>
        <app-wrapper size="xl">
          <app-problem-detail-back-forward-button
            [isFirstProblem]="isFirstProblem$ | async"
            [isLastProblem]="isLastProblem$ | async"
            [navigation]="navigation$ | async"
            [parentComponent]="parentComponent"
            [readableSortType]="readableSortType$ | async"
            [problemId]="problemId$ | async"
            (menuTypeChangeHandler)="changeMenuType($event)"
          ></app-problem-detail-back-forward-button>
        </app-wrapper>
      </app-content-row>
      <!-- △ 前へ/次へボタン 試験単位では消す -->

      <app-content-row *ngIf="daimonMode$ | async">
        <app-wrapper size="xl" isFitWithSmallScreen="true">
          <!-- 理系科目の概要 -->
          <app-card-content [showSeparator]="false" *ngIf="scienceIds.includes(subjectId$ | async)">
            <div class="table-frame">
              <app-science-problems
                [readableScienceProblems]="readableScienceProblems$ | async"
                [problemButtonDisabled]="true"
                [verticalAlignTop]="false"
                (addBookmarkClick)="addBookmark($event)"
                (deleteBookmarkClick)="deleteBookmark($event)"
                [initializedBookmark$]="this.initializedBookmark$"
                [bookmarkProblems]="this.bookmarkSubjectProblems"
                [canBookmarkSpiner]="true"
                [isTrial]="this.user.isTrial"
              ></app-science-problems>
              <!-- △ 大問単位 理系のテーブル -->
            </div>
          </app-card-content>
          <!-- 英語の概要 -->
          <app-card-content [showSeparator]="false" *ngIf="(subjectId$ | async) === ENGLISH_SUBJECT_ID">
            <div class="table-frame">
              <app-english-problems
                [readableEnglishProblems]="readableEnglishProblems$ | async"
                [problemButtonDisabled]="true"
                (addBookmarkClick)="addBookmark($event)"
                (deleteBookmarkClick)="deleteBookmark($event)"
                [initializedBookmark$]="this.initializedBookmark$"
                [bookmarkProblems]="this.bookmarkSubjectProblems"
                [canBookmarkSpiner]="true"
                [isTrial]="this.user.isTrial"
              ></app-english-problems>
            </div>
          </app-card-content>

          <!-- 国語の概要 -->
          <app-card-content [showSeparator]="false" *ngIf="(subjectId$ | async) === NATIONAL_LANGUAGE_SUBJECT_ID">
            <div class="table-frame">
              <app-national-language-problems
                [readableNationalLanguageProblems]="readableNationalLanguageProblems$ | async"
                [problemButtonDisabled]="true"
                (addBookmarkClick)="addBookmark($event)"
                (deleteBookmarkClick)="deleteBookmark($event)"
                [initializedBookmark$]="this.initializedBookmark$"
                [bookmarkProblems]="this.bookmarkSubjectProblems"
                [canBookmarkSpiner]="true"
                [isTrial]="this.user.isTrial"
              ></app-national-language-problems>
            </div>
          </app-card-content>

          <!-- 日本史の概要 -->
          <app-card-content [showSeparator]="false" *ngIf="(subjectId$ | async) === JAPANESE_HISTORY_SUBJECT_ID">
            <div class="table-frame">
              <app-history-problems
                [readableHistoryProblems]="readableJapaneseHistoryProblems$ | async"
                [problemButtonDisabled]="true"
                (addBookmarkClick)="addBookmark($event)"
                (deleteBookmarkClick)="deleteBookmark($event)"
                [initializedBookmark$]="this.initializedBookmark$"
                [bookmarkProblems]="this.bookmarkSubjectProblems"
                [canBookmarkSpiner]="true"
                [isTrial]="this.user.isTrial"
              ></app-history-problems>
            </div>
          </app-card-content>

          <!-- 世界史の概要 -->
          <app-card-content [showSeparator]="false" *ngIf="(subjectId$ | async) === WORLD_HISTORY_SUBJECT_ID">
            <div class="table-frame">
              <app-history-problems
                [readableHistoryProblems]="readableWorldHistoryProblems$ | async"
                [problemButtonDisabled]="true"
                (addBookmarkClick)="addBookmark($event)"
                (deleteBookmarkClick)="deleteBookmark($event)"
                [initializedBookmark$]="this.initializedBookmark$"
                [bookmarkProblems]="this.bookmarkSubjectProblems"
                [canBookmarkSpiner]="true"
                [isTrial]="this.user.isTrial"
              ></app-history-problems>
            </div>
          </app-card-content>
          <!--
          <div class="text-nega text-right text-xs mt-2" [@showHide]="(problem$ | async)?.hasExternalData === true">
            ▼ 旺文社「大学受験パスナビ」の過去問ライブラリーのページを表示しています
          </div>
          -->
        </app-wrapper>
      </app-content-row>

      <app-content-row *ngIf="(problem$ | async)?.hasExternalData; else noExternalData">
        <app-wrapper size="xl" isFitWithSmallScreen="true">
          <!-- ▼ テスト表示領域 -->
          <app-problem-detail-frame
            [readableScienceProblems]="readableScienceProblems$ | async"
            [readableEnglishProblems]="readableEnglishProblems$ | async"
            [readableNationalLanguageProblems]="readableNationalLanguageProblems$ | async"
            [readableJapaneseHistoryProblems]="readableJapaneseHistoryProblems$ | async"
            [readableWorldHistoryProblems]="readableWorldHistoryProblems$ | async"
            [paperMode]="paperMode$ | async"
            [trialMode]="trialMode$ | async"
            [subjectId]="subjectId$ | async"
            (menuTypeChangeHandler)="changeMenuType($event)"
            (paperClickHandler)="showPaper()"
            (printClickHandler)="showPrint()"
          ></app-problem-detail-frame>
          <!-- △ テスト表示領域 -->
        </app-wrapper>
      </app-content-row>
    </div>

    <div class="progress-48" [@fadeInOut]="searching$ | async">
      <mat-spinner [diameter]="48"></mat-spinner>
    </div>
  </div>

  <app-bottom-toast-banner *ngIf="isBottomToastBannerVisible"></app-bottom-toast-banner>

  <ng-template #noExternalData>
    <div class="text-center text-nega">
      <app-problem-detail-no-external-data></app-problem-detail-no-external-data>
    </div>
  </ng-template>
</div>
<!-- △ 印刷時は消す ここまで -->
