<!-- ▼ 印刷時のみ表示 ここから -->
<div class="problem-detail-show-print-only-wrapper" data-print-only *ngIf="(problem$ | async)?.examPaper">
  <app-problem-detail-frame-for-print
    [menuType]="menuType$ | async"
    [readableScienceProblems]="readableScienceProblems$ | async"
    [readableEnglishProblems]="readableEnglishProblems$ | async"
    [readableNationalLanguageProblems]="readableNationalLanguageProblems$ | async"
    [readableJapaneseHistoryProblems]="readableJapaneseHistoryProblems$ | async"
    [readableWorldHistoryProblems]="readableWorldHistoryProblems$ | async"
    [paperMode]="paperMode$ | async"
    [trialMode]="trialMode$ | async"
    [subjectId]="subjectId$ | async"
    [isBToC]="true"
  ></app-problem-detail-frame-for-print>
</div>
<!-- △ 印刷時のみ表示 ここまで -->
<!-- ▼ 印刷時は消す ここから -->
<app-content-row data-no-print>
  <app-wrapper size="xl" data-no-print [@fadeInOut]="(searching$ | async) === false && (headerBannerImageUrl$ | async)">
    <div class="problem-detail-icatch">
      <button (click)="headerBannerClick()"><img src="{{ headerBannerImageUrl$ | async }}" /></button>
    </div>
  </app-wrapper>

  <app-content-row>
    <app-content-row>
      <app-content-row>
        <div data-no-print>
          <div *ngIf="!isPreconditionError">
            <div [@fadeInOut]="(searching$ | async) === false">
              <!-- ▼ 「大学名」の一覧を見るボタン -->
              <app-content-row>
                <app-overflow-wrapper-x>
                  <app-wrapper size="xl" data-no-print>
                    <button mat-flat-button class="button-check-list text-primary" (click)="goUniversityDetail()">
                      {{ universityName$ | async }}({{ year$ | async }}年) の一覧 を見る
                      <!-- 長い大学名長い大学名長い大学名長い大学名長い大学名長い大学名 の一覧を見る -->
                    </button>
                  </app-wrapper>
                </app-overflow-wrapper-x>
              </app-content-row>
              <!-- △ 「大学名」の一覧を見るボタン -->

              <app-content-row *ngIf="(problem$ | async)?.hasExternalData && (isVisible$ | async); else noExternalData">
                <app-wrapper size="xl" isFitWithSmallScreen="true">
                  <!-- ▼ テスト表示領域 -->
                  <app-problem-detail-frame
                    [readableScienceProblems]="readableScienceProblems$ | async"
                    [readableEnglishProblems]="readableEnglishProblems$ | async"
                    [readableNationalLanguageProblems]="readableNationalLanguageProblems$ | async"
                    [readableJapaneseHistoryProblems]="readableJapaneseHistoryProblems$ | async"
                    [readableWorldHistoryProblems]="readableWorldHistoryProblems$ | async"
                    [paperMode]="paperMode$ | async"
                    [trialMode]="trialMode$ | async"
                    [canDisplay]="canDisplay$ | async"
                    [showPaperBookmarkBtn]="showPaperBookmarkBtn"
                    [subjectId]="subjectId$ | async"
                    [canBookmarkSpinner]="true"
                    [isPaperBookmarked]="isPaperBookmarked"
                    (menuTypeChangeHandler)="changeMenuType($event)"
                    (printClickHandler)="showPrint()"
                    (addPaperBookmarkClick)="addPaperBookmark()"
                    (deletePaperBookmarkClick)="deletePaperBookmark()"
                    (openAnsweredDialogClick)="openAnsweredDialog()"
                  ></app-problem-detail-frame>
                  <!-- △ テスト表示領域 -->
                </app-wrapper>
              </app-content-row>
            </div>

            <div class="progress-48" [@fadeInOut]="searching$ | async">
              <mat-spinner [diameter]="48"></mat-spinner>
            </div>
          </div>

          <ng-template #noExternalData>
            <div class="text-center text-nega">
              <app-problem-detail-no-external-data></app-problem-detail-no-external-data>
            </div>
          </ng-template>
        </div>
      </app-content-row>
    </app-content-row>
  </app-content-row>

  <app-wrapper size="xl" data-no-print [@fadeInOut]="(searching$ | async) === false && (footerBannerImageUrl$ | async)">
    <div class="problem-detail-icatch">
      <button (click)="footerBannerClick()"><img src="{{ footerBannerImageUrl$ | async }}" /></button>
    </div>
  </app-wrapper>
</app-content-row>
<!-- △ 印刷時は消す ここまで -->
