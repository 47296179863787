// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyDAVGe9N33NT8cWKVFT34fsTSs4ZmJ3IEE',
  authDomain: 'examweb-relishable-devel-3ecb4.firebaseapp.com',
  measurementId: 'G-GBLX40RXCC',
  projectId: 'examweb-relishable-devel-3ecb4',
  storageBucket: 'examweb-relishable-devel-3ecb4.appspot.com',
  messagingSenderId: '718383050343',
  appId: '1:718383050343:web:bb685fab206bc5af452ec2'
};

export const firebaseLocation = 'asia-northeast1';

export const commonIdConfig = {
  authorizationEndpoint: 'https://id-dev.obunsha.co.jp/v1/authorize',
  redirectUri: 'https://examweb-dev.com/c/sign-in/callback',
  clientId: '5HfgRxgVnpgmFKpiztU7siPPea3bBoh67UkUxePvTftu',
  profileUrl: 'https://id-dev.obunsha.co.jp/settings/profile/',
  faqUrl: 'https://id-dev.obunsha.co.jp/help/',
  ssc: {
    cookieApiUri: 'https://ssc-api-25ttyqgppa-an.a.run.app/v1/cookies'
  }
};

export const passNaviConfig = {
  faqUrl: 'https://passnavi-stg.obunsha.co.jp/faq/'
};

export const gaConfig = ['G-MJHS1XV51L'];

export const redirectDomainConfig = {
  from: 'examweb-relishable-devel-3ecb4.web.app',
  to: 'examweb-dev.com'
};

export const manualPdfFiles = {
  userManual: 'https://storage.googleapis.com/examweb-relishable-devel-3ecb4.appspot.com/help-manual/nyushidigi_standard_manual.pdf',
  adminManual: 'https://storage.googleapis.com/examweb-relishable-devel-3ecb4.appspot.com/help-manual/nyushidigi_dantaikanri_manual.pdf'
};
export const downloadFiles = {
  universitiesListPdf:
    'https://storage.googleapis.com/examweb-relishable-devel-3ecb4.appspot.com/help-manual/nyushidigi_daigakulist_obunsha.pdf',
  membersImportTemplateCsv: 'https://storage.googleapis.com/examweb-relishable-devel-3ecb4.appspot.com/help-manual/template.csv',
  membersImportManualPdf:
    'https://storage.googleapis.com/examweb-relishable-devel-3ecb4.appspot.com/help-manual/nyushidigi_dantaikanri_manual.pdf'
};

export const microCmsConfig = {
  adminUrl: 'https://dev-kakomon.microcms.io/apis/informations'
};

export const stripeConfig = {
  userPaymentsUrl: 'https://dashboard.stripe.com/test/search?query=<user-id>%20is%3Apayment'
};

export const cloudfrontHost = 'https://d3cdrt34317hka.cloudfront.net/';
export const lambdaHost = 'https://us3tjdjzc5aw4pccmf2fcrzbvu0aseoo.lambda-url.ap-northeast-1.on.aws/';

export const sentryDsn = 'https://fd163f3dc12246a8b45216a89f401f11@o1071032.ingest.sentry.io/6067679';
export const release = '20250321-release';
