import { UAParser } from 'ua-parser-js';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, Observable, combineLatest, BehaviorSubject, of, throwError } from 'rxjs';
import { map, switchMap, filter, shareReplay, startWith, take } from 'rxjs/operators';

import { RootState } from 'src/app/reducers';
import { setBrowserTitle, dispatchAppError, dispatchInfoMessage, openWindow, setTitle } from 'src/app/actions/core.actions';
import {
  ScienceProblem,
  ReadableScienceProblem,
  EnglishProblem,
  ReadableEnglishProblem,
  NationalLanguageProblem,
  ReadableNationalLanguageProblem,
  HistoryProblem,
  ReadableHistoryProblem,
  Problem,
  ReadablePlaylistProblem
} from 'src/app/models/problem';
import { GeneralError } from 'src/app/errors/general-error';
import { findStaticData, initializeStaticDataState } from 'src/app/actions/static-data.actions';
import {
  findScienceProblem,
  findEnglishProblem,
  findNationalLanguageProblemsById,
  findHistoryProblem
} from 'src/app/actions/search.actions'; // TODO

import * as PlaylistActions from 'src/app/actions/playlist.actions';
import * as PlaylistSelectors from 'src/app/selectors/playlist.selectors';

import {
  getStaticMathData,
  getFetchedDate,
  getStaticPhysicsData,
  getStaticChemistryData,
  getStaticBiologyData,
  getStaticEnglishData,
  getStaticNationalLanguageData,
  getStaticJapaneseHistoryData,
  getStaticWorldHistoryData,
  getStaticGeographyData,
  getStaticPoliticalEconomyData
} from 'src/app/selectors/static-data.selectors';
import {
  getScienceProblem,
  getEnglishProblem,
  getNationalLanguageProblemsWithSameId,
  getHistoryProblem,
  getReadableMathProblem,
  getReadablePhysicsProblem,
  getReadableChemistryProblem,
  getReadableBiologyProblem,
  getReadableEnglishProblem,
  getReadableNationalLanguageProblemsFromProblems,
  getReadableJapaneseHistoryProblem,
  getReadableWorldHistoryProblem,
  getReadableGeographyProblem,
  getReadablePoliticalEconomyProblem,
  getProblemSearching
} from 'src/app/selectors/search.selectors';
import { Dates } from 'src/app/utils/dates';
import { Log } from 'src/app/utils/log';
import { SubjectUtil } from 'src/app/utils/subject-util';
import { StaticScienceData } from 'src/app/models/static-science-data';
import { StaticEnglishData } from 'src/app/models/static-english-data';
import { StaticNationalLanguageData } from 'src/app/models/static-national-language-data';
import { StaticHistoryData } from 'src/app/models/static-history-data';
import { fadeInOut, showHide } from 'src/app/resources/animations';
import { CustomErrorMessage } from 'src/app/errors/error-info';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import {
  SubjectId,
  STATIC_DATA_CACHE_DAYS,
  META_VIEWPORT_OTHER,
  SCIENCE_IDS,
  IS_BOTTOM_TOAST_BANNER_VISIBLE
} from 'src/app/resources/config';
import { getSignedInUser } from 'src/app/selectors/auth.selectors';
import { User } from 'src/app/models/user';
import { Theme } from 'src/app/models/playlist';
import { ThemeForDisplay } from 'src/app/models/playlist';
import { AddBookmarkData, Bookmark, BookmarkProblem, DeleteBookmarkData } from 'src/app/models/bookmark';
import * as BookmarkSelectors from 'src/app/selectors/bookmark.selectors';
import * as BookmarkActions from 'src/app/actions/bookmark.actions';
import { Subject } from '../../../models/common-data';
import * as StaticDataSelectors from '../../../selectors/static-data.selectors';
import { Playlist } from 'src/app/models/playlist';
import {
  ReadableEnglishPlaylistProblem,
  ReadableSciencePlaylistProblem,
  ReadableNationalLanguagePlaylistProblem,
  ReadableHistoryPlaylistProblem
} from 'src/app/models/problem';
import { getPlaylistProblemIds } from 'src/app/selectors/playlist.selectors';
import { findPlaylistProblemIds } from 'src/app/actions/playlist.actions';
import { WINDOW_OBJECT } from '../../../utils/injection-tokens';

@Component({
  selector: 'app-playlist-problem-detail',
  templateUrl: './playlist-problem-detail.component.html',
  styleUrls: ['./playlist-problem-detail.component.scss'],
  animations: [fadeInOut, showHide]
})
export class PlaylistProblemDetailComponent implements OnInit, OnDestroy {
  private LOG_SOURCE = this.constructor.name;
  private subscriptions: Subscription[] = [];

  protected readonly isBottomToastBannerVisible = IS_BOTTOM_TOAST_BANNER_VISIBLE;

  private problemId$: Observable<string>;
  private problemIds$: Observable<string[]>;
  private initializedBookmarkSubject = new BehaviorSubject(false);

  scienceIds: string[] = SCIENCE_IDS;
  problemId: string;

  playlist$: Observable<Playlist>;
  playList: Playlist;
  playListName: string;
  playlistId: string;
  themeId$: Observable<string>;
  themeId: string;
  theme$: Observable<Theme>;
  playListProblems$: Observable<ReadablePlaylistProblem[]>;
  playListProblem$: Observable<ReadablePlaylistProblem>;
  englishPlaylistTheme$: Observable<ThemeForDisplay<ReadableEnglishPlaylistProblem>>;
  sciencePlaylistTheme$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>>;
  mathPlaylistTheme$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>>;
  nationalLanguagePlaylistTheme$: Observable<ThemeForDisplay<ReadableNationalLanguagePlaylistProblem>>;
  physicsPlaylistTheme$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>>;
  chemistryPlaylistTheme$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>>;
  biologyPlaylistTheme$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>>;
  japaneseHistoryPlaylistTheme$: Observable<ThemeForDisplay<ReadableHistoryPlaylistProblem>>;
  worldHistoryPlaylistTheme$: Observable<ThemeForDisplay<ReadableHistoryPlaylistProblem>>;
  geographyPlaylistTheme$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>>;
  politicalEconomyPlaylistTheme$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>>;

  staticMathData$: Observable<StaticScienceData>;
  staticPhysicsData$: Observable<StaticScienceData>;
  staticChemistryData$: Observable<StaticScienceData>;
  staticBiologyData$: Observable<StaticScienceData>;
  staticEnglishData$: Observable<StaticEnglishData>;
  staticNationalLanguageData$: Observable<StaticNationalLanguageData>;
  staticJapaneseHistoryData$: Observable<StaticHistoryData>;
  staticWorldHistoryData$: Observable<StaticHistoryData>;
  staticGeographyData$: Observable<StaticScienceData>;
  staticPoliticalEconomyData$: Observable<StaticScienceData>;

  /** science-problems.component をそのまま利用するため array で定義 */
  readableScienceProblems$: Observable<ReadableScienceProblem[]>;
  readableEnglishProblems$: Observable<ReadableEnglishProblem[]>;
  readableNationalLanguageProblems$: Observable<ReadableNationalLanguageProblem[]>;
  readableJapaneseHistoryProblems$: Observable<ReadableHistoryProblem[]>;
  readableWorldHistoryProblems$: Observable<ReadableHistoryProblem[]>;

  problem$: Observable<Problem>;
  scienceProblem$: Observable<ScienceProblem>;
  englishProblem$: Observable<EnglishProblem>;
  nationalLanguageProblem$: Observable<NationalLanguageProblem>;
  nationalLanguageProblems$: Observable<NationalLanguageProblem[]>;
  historyProblem$: Observable<HistoryProblem>;
  readableSortType$: Observable<string>;
  searching$: Observable<boolean>;
  problemSearching$: Observable<boolean>;
  loadingReadableProblems$: Observable<boolean> = of(true);
  isFirstProblem$: Observable<boolean>;
  isLastProblem$: Observable<boolean>;
  isPreconditionError = false;
  daimonMode$: Observable<boolean>;
  paperMode$: Observable<boolean>;
  trialMode$: Observable<boolean>;
  hidePaperProblemBtn = true;
  navigation$: Observable<boolean>;
  subjectId$: Observable<string>;
  subjectId: string;
  //   thema: ThemePalette;
  menuType$: Observable<'article' | 'spellcheck'> = of('article');

  toolbarTitle = '問題詳細';

  signedInUser$: Observable<User>;
  user: User;
  bookmark: Bookmark;
  bookmarkSubjectProblems: BookmarkProblem[];
  initializedBookmark$ = this.initializedBookmarkSubject.asObservable();
  parentComponent = 'playlistProblemDetail';

  constructor(
    private store: Store<RootState>,
    private activatedRoute: ActivatedRoute,
    private meta: Meta,
    @Inject(WINDOW_OBJECT) private window: Window
  ) {}

  ngOnInit() {
    this.meta.updateTag(META_VIEWPORT_OTHER);
    this.store.dispatch(setBrowserTitle({ subTitle: this.toolbarTitle }));
    this.setUpUser();
    this.findPlaylistIfNeeded();
    this.setUpPlayList();
    this.setUpStaticData();
    this.setUpTrialMode();
    this.setUpNavigationAndPaperMode();
    this.setUpSubjectId();
    this.setUpThemeId();
    this.findPlaylistProblems();
    this.setUpProblemId();
    this.setUpProblemIds();
    this.setUpProblem();
    this.setUpTheme();
    this.setUpPlayListProblems();
    // this.setUpPlayListProblem();
    this.setUpReadableProblem();
    this.setUpSearching();
    this.setUpProblemPositionFlags();
    this.setUpBookmarks();
    this.setUpBrowserTitle();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
    this.subscriptions = [];
    this.store.dispatch(BookmarkActions.initializeAddBookmarkState());
    this.store.dispatch(BookmarkActions.initializeDeleteBookmarkState());
    this.store.dispatch(PlaylistActions.initializeEnglishPlaylistProblemsState());
    this.store.dispatch(PlaylistActions.initializeMathPlaylistProblemsState());
    this.store.dispatch(PlaylistActions.initializeNationalLanguagePlaylistProblemsState());
    this.store.dispatch(PlaylistActions.initializePhysicsPlaylistProblemsState());
    this.store.dispatch(PlaylistActions.initializeChemistryPlaylistProblemsState());
    this.store.dispatch(PlaylistActions.initializeBiologyPlaylistProblemsState());
    this.store.dispatch(PlaylistActions.initializeJapaneseHistoryPlaylistProblemsState());
    this.store.dispatch(PlaylistActions.initializeWorldHistoryPlaylistProblemsState());
    this.store.dispatch(PlaylistActions.initializeGeographyPlaylistProblemsState());
    this.store.dispatch(PlaylistActions.initializePoliticalEconomyPlaylistProblemsState());
    this.store.dispatch(PlaylistActions.initializePlaylistProblemIdsState());
  }

  private setUpUser() {
    this.signedInUser$ = this.store.select(getSignedInUser).pipe(filter<User>(it => it != null && it !== 'none'));
  }

  private findPlaylistIfNeeded() {
    this.store
      .select(PlaylistSelectors.getPlaylists)
      .pipe(take(1))
      .subscribe(playlists => {
        if (!playlists) this.store.dispatch(PlaylistActions.findPlaylists());
      });
  }

  private setUpPlayList() {
    this.playlist$ = combineLatest([
      this.store.select(PlaylistSelectors.getPlaylists).pipe(filter(it => it != null)),
      this.activatedRoute.paramMap.pipe(map(paramMap => paramMap.get('playlistId') || ''))
    ]).pipe(
      map(([playlists, playlistId]) => {
        const selectedPlaylist = playlists.find(it => it.playlistId === playlistId);
        this.playlistId = playlistId;
        if (!playlistId || !selectedPlaylist) {
          this.store.dispatch(
            dispatchAppError({
              source: this.LOG_SOURCE,
              error: GeneralError.customMessage(CustomErrorMessage.PLAYLIST_DETAIL_INVALID_PLAYLIST_ID)
            })
          );
          throwError(() => GeneralError.invalidArguments(`指定された playlistId は playlists に存在しません. playlistId: ${playlistId}`));
          return null;
        }
        this.playList = selectedPlaylist;
        this.playListName = `${this.playList.seriesName}　${this.playList.name}`;
        return selectedPlaylist;
      }),
      shareReplay(1)
    );
  }

  private setUpStaticData() {
    this.findStaticDataIfNeeded();

    this.staticMathData$ = this.store.select(getStaticMathData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticPhysicsData$ = this.store.select(getStaticPhysicsData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticChemistryData$ = this.store.select(getStaticChemistryData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticBiologyData$ = this.store.select(getStaticBiologyData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticEnglishData$ = this.store.select(getStaticEnglishData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticNationalLanguageData$ = this.store.select(getStaticNationalLanguageData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticJapaneseHistoryData$ = this.store.select(getStaticJapaneseHistoryData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticWorldHistoryData$ = this.store.select(getStaticWorldHistoryData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticGeographyData$ = this.store.select(getStaticGeographyData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticPoliticalEconomyData$ = this.store.select(getStaticPoliticalEconomyData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpTrialMode() {
    this.trialMode$ = this.store.select(getSignedInUser).pipe(
      filter<User>(it => it != null && it !== 'none'),
      map(signedInUser => (signedInUser.isTrial === true ? true : false))
    );
  }

  private setUpNavigationAndPaperMode() {
    this.navigation$ = of(true);
    this.daimonMode$ = of(true);
    this.paperMode$ = of(false);
  }

  private setUpProblemId() {
    this.problemId$ = this.activatedRoute.paramMap.pipe(
      map(paramMap => paramMap.get('problemId') || ''),
      shareReplay(1)
    );

    this.subscriptions.push(
      this.problemId$.subscribe(problemId => {
        this.isPreconditionError = problemId === '';
        if (!problemId) {
          const error = GeneralError.customMessage(CustomErrorMessage.PROBLEM_DETAIL_FAILED_PRECONDITION);
          this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error }));
        }
        this.problemId = problemId;
      })
    );
  }

  private setUpSubjectId() {
    this.subjectId$ = this.activatedRoute.paramMap.pipe(
      map(paramMap => paramMap.get('playlistId').slice(0, 2) || ''),
      shareReplay(1)
    );
    combineLatest([this.subjectId$])
      .pipe()
      .subscribe(([subjectId]) => (this.subjectId = subjectId));
  }

  private setUpThemeId() {
    this.themeId$ = this.activatedRoute.paramMap.pipe(
      map(paramMap => paramMap.get('themeId') || ''),
      shareReplay(1)
    );
    combineLatest([this.themeId$])
      .pipe()
      .subscribe(([themeId]) => (this.themeId = themeId));
  }

  private findPlaylistProblems() {
    combineLatest([this.playlist$])
      .pipe(take(1))
      .subscribe(([playlist]) => {
        if (playlist.subjectId === SubjectId.ENGLISH) {
          this.store.dispatch(PlaylistActions.findEnglishPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.MATH) {
          this.store.dispatch(PlaylistActions.findMathPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.NATIONAL_LANGUAGE) {
          this.store.dispatch(PlaylistActions.findNationalLanguagePlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.PHYSICS) {
          this.store.dispatch(PlaylistActions.findPhysicsPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.CHEMISTRY) {
          this.store.dispatch(PlaylistActions.findChemistryPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.BIOLOGY) {
          this.store.dispatch(PlaylistActions.findBiologyPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.JAPANESE_HISTORY) {
          this.store.dispatch(PlaylistActions.findJapaneseHistoryPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.WORLD_HISTORY) {
          this.store.dispatch(PlaylistActions.findWorldHistoryPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.GEOGRAPHY) {
          this.store.dispatch(PlaylistActions.findGeographyPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.POLITICAL_ECONOMY) {
          this.store.dispatch(PlaylistActions.findPoliticalEconomyPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
      });
  }

  private setUpProblemIds() {
    combineLatest([this.subjectId$, this.playlist$])
      .pipe(take(1))
      .subscribe(([subjectId, playlist]) => {
        this.store.dispatch(findPlaylistProblemIds({ subjectId, playlistId: playlist.playlistId }));
      });
    this.problemIds$ = this.store.select(getPlaylistProblemIds).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpTheme() {
    this.theme$ = combineLatest([this.themeId$, this.playlist$]).pipe(
      map(([themeId, playlist]) => {
        const selectedTheme = playlist.themes.find(theme => theme.id === themeId);
        return selectedTheme as Theme;
      })
    );

    this.subjectId$.subscribe(subjectId => {
      if (subjectId === SubjectId.ENGLISH) {
        this.setUpEnglishPlaylistTheme();
      } else if (subjectId === SubjectId.MATH) {
        this.setUpMathPlaylistTheme();
      } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
        this.setUpNationalLanguagePlaylistTheme();
      } else if (subjectId === SubjectId.PHYSICS) {
        this.setUpPhysicsPlaylistTheme();
      } else if (subjectId === SubjectId.CHEMISTRY) {
        this.setUpChemistryPlaylistTheme();
      } else if (subjectId === SubjectId.BIOLOGY) {
        this.setUpBiologyPlaylistTheme();
      } else if (subjectId === SubjectId.JAPANESE_HISTORY) {
        this.setUpJapaneseHistoryPlaylistTheme();
      } else if (subjectId === SubjectId.WORLD_HISTORY) {
        this.setUpWorldHistoryPlaylistTheme();
      } else if (subjectId === SubjectId.GEOGRAPHY) {
        this.setUpGeographyPlaylistTheme();
      } else if (subjectId === SubjectId.POLITICAL_ECONOMY) {
        this.setUpPoliticalEconomyPlaylistTheme();
      }
    });
  }

  private setUpPlayListProblems() {
    this.playListProblems$ = combineLatest([this.subjectId$]).pipe(
      switchMap(([subjectId]) => {
        if (subjectId === SubjectId.BIOLOGY) {
          return combineLatest([this.store.select(PlaylistSelectors.getBiologyPlaylistProblems).pipe(filter(it => it != null))]).pipe(
            switchMap(([problems]) =>
              this.store.select(PlaylistSelectors.getReadableBiologyPlaylistProblems(problems)).pipe(
                filter(it => it != null),
                map(readableProblems => readableProblems)
              )
            )
          );
        } else if (subjectId === SubjectId.CHEMISTRY) {
          return combineLatest([this.store.select(PlaylistSelectors.getChemistryPlaylistProblems).pipe(filter(it => it != null))]).pipe(
            switchMap(([problems]) =>
              this.store.select(PlaylistSelectors.getReadableChemistryPlaylistProblems(problems)).pipe(
                filter(it => it != null),
                map(readableProblems => readableProblems)
              )
            )
          );
        } else if (subjectId === SubjectId.PHYSICS) {
          return combineLatest([this.store.select(PlaylistSelectors.getPhysicsPlaylistProblems).pipe(filter(it => it != null))]).pipe(
            switchMap(([problems]) =>
              this.store.select(PlaylistSelectors.getReadablePhysicsPlaylistProblems(problems)).pipe(
                filter(it => it != null),
                map(readableProblems => readableProblems)
              )
            )
          );
        } else if (subjectId === SubjectId.MATH) {
          return combineLatest([this.store.select(PlaylistSelectors.getMathPlaylistProblems).pipe(filter(it => it != null))]).pipe(
            switchMap(([problems]) =>
              this.store.select(PlaylistSelectors.getReadableMathPlaylistProblems(problems)).pipe(
                filter(it => it != null),
                map(readableProblems => readableProblems)
              )
            )
          );
        } else if (subjectId === SubjectId.ENGLISH) {
          return combineLatest([this.store.select(PlaylistSelectors.getEnglishPlaylistProblems).pipe(filter(it => it != null))]).pipe(
            switchMap(([problems]) =>
              this.store.select(PlaylistSelectors.getReadableEnglishPlaylistProblems(problems)).pipe(
                filter(it => it != null),
                map(readableProblems => readableProblems)
              )
            )
          );
        } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
          return combineLatest([
            this.store.select(PlaylistSelectors.getNationalLanguagePlaylistProblems).pipe(filter(it => it != null))
          ]).pipe(
            switchMap(([problems]) =>
              this.store.select(PlaylistSelectors.getReadableNationalLanguagePlaylistProblems(problems)).pipe(
                filter(it => it != null),
                map(readableProblems => readableProblems)
              )
            )
          );
        } else if (subjectId === SubjectId.JAPANESE_HISTORY) {
          return combineLatest([
            this.store.select(PlaylistSelectors.getJapaneseHistoryPlaylistProblems).pipe(filter(it => it != null))
          ]).pipe(
            switchMap(([problems]) =>
              this.store.select(PlaylistSelectors.getReadableJapaneseHistoryPlaylistProblems(problems)).pipe(
                filter(it => it != null),
                map(readableProblems => readableProblems)
              )
            )
          );
        } else if (subjectId === SubjectId.WORLD_HISTORY) {
          return combineLatest([this.store.select(PlaylistSelectors.getWorldHistoryPlaylistProblems).pipe(filter(it => it != null))]).pipe(
            switchMap(([problems]) =>
              this.store.select(PlaylistSelectors.getReadableWorldHistoryPlaylistProblems(problems)).pipe(
                filter(it => it != null),
                map(readableProblems => readableProblems)
              )
            )
          );
        } else if (subjectId === SubjectId.GEOGRAPHY) {
          return combineLatest([this.store.select(PlaylistSelectors.getGeographyPlaylistProblems).pipe(filter(it => it != null))]).pipe(
            switchMap(([problems]) =>
              this.store.select(PlaylistSelectors.getReadableGeographyPlaylistProblems(problems)).pipe(
                filter(it => it != null),
                map(readableProblems => readableProblems)
              )
            )
          );
        } else if (subjectId === SubjectId.POLITICAL_ECONOMY) {
          return combineLatest([
            this.store.select(PlaylistSelectors.getPoliticalEconomyPlaylistProblems).pipe(filter(it => it != null))
          ]).pipe(
            switchMap(([problems]) =>
              this.store.select(PlaylistSelectors.getReadablePoliticalEconomyPlaylistProblems(problems)).pipe(
                filter(it => it != null),
                map(readableProblems => readableProblems)
              )
            )
          );
        }
      })
    );

    /*
    combineLatest([this.playListProblems$])
      .pipe()
      .subscribe(([problems]) => {
        console.log(problems);
      });
      */
  }

  // private setUpPlayListProblem() {
  //   this.playListProblem$ = combineLatest([this.playListProblems$, this.themeId$, this.problemId$]).pipe(
  //     map(([problems, themeId, problemId]) => {
  //       console.log('OK')
  //       return problems.find(problem => problem.themeId === themeId && problem.id === problemId);
  //     })
  //   );
  // }

  private setUpBiologyPlaylistTheme() {
    this.sciencePlaylistTheme$ = combineLatest([
      this.store.select(PlaylistSelectors.getBiologyPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$,
      this.theme$,
      this.problemId$
    ]).pipe(
      switchMap(([problems, playlist, currentTheme, problemId]) =>
        this.store
          .select(
            PlaylistSelectors.getReadableBiologyPlaylistProblems(
              problems.filter(it => it.themeId === currentTheme.id && it.id === problemId)
            )
          )
          .pipe(
            filter(it => it != null),
            map(readableProblems => {
              const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
                const themeProblems = readableProblems;
                return {
                  id: theme.id,
                  name: theme.name,
                  order: theme.order,
                  problemCount: themeProblems.length,
                  pdfPath: theme.pdfPath,
                  pdfDownloading: false,
                  problems: themeProblems
                };
              });
              return themesForDisplay.find(themeForDisplay => themeForDisplay.id === currentTheme.id);
            })
          )
      )
    );
  }

  private setUpChemistryPlaylistTheme() {
    this.sciencePlaylistTheme$ = combineLatest([
      this.store.select(PlaylistSelectors.getChemistryPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$,
      this.theme$,
      this.problemId$
    ]).pipe(
      switchMap(([problems, playlist, currentTheme, problemId]) =>
        this.store
          .select(
            PlaylistSelectors.getReadableChemistryPlaylistProblems(
              problems.filter(it => it.themeId === currentTheme.id && it.id === problemId)
            )
          )
          .pipe(
            filter(it => it != null),
            map(readableProblems => {
              const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
                const themeProblems = readableProblems;
                return {
                  id: theme.id,
                  name: theme.name,
                  order: theme.order,
                  problemCount: themeProblems.length,
                  pdfPath: theme.pdfPath,
                  pdfDownloading: false,
                  problems: themeProblems
                };
              });
              return themesForDisplay.find(themeForDisplay => themeForDisplay.id === currentTheme.id);
            })
          )
      )
    );
  }

  private setUpPhysicsPlaylistTheme() {
    this.sciencePlaylistTheme$ = combineLatest([
      this.store.select(PlaylistSelectors.getPhysicsPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$,
      this.theme$,
      this.problemId$
    ]).pipe(
      switchMap(([problems, playlist, currentTheme, problemId]) =>
        this.store
          .select(
            PlaylistSelectors.getReadablePhysicsPlaylistProblems(
              problems.filter(it => it.themeId === currentTheme.id && it.id === problemId)
            )
          )
          .pipe(
            filter(it => it != null),
            map(readableProblems => {
              const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
                const themeProblems = readableProblems;
                return {
                  id: theme.id,
                  name: theme.name,
                  order: theme.order,
                  problemCount: themeProblems.length,
                  pdfPath: theme.pdfPath,
                  pdfDownloading: false,
                  problems: themeProblems
                };
              });
              return themesForDisplay.find(themeForDisplay => themeForDisplay.id === currentTheme.id);
            })
          )
      )
    );
  }

  private setUpMathPlaylistTheme() {
    this.sciencePlaylistTheme$ = combineLatest([
      this.store.select(PlaylistSelectors.getMathPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$,
      this.theme$,
      this.problemId$
    ]).pipe(
      switchMap(([problems, playlist, currentTheme, problemId]) =>
        this.store
          .select(
            PlaylistSelectors.getReadableMathPlaylistProblems(problems.filter(it => it.themeId === currentTheme.id && it.id === problemId))
          )
          .pipe(
            filter(it => it != null),
            map(readableProblems => {
              const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
                const themeProblems = readableProblems;
                return {
                  id: theme.id,
                  name: theme.name,
                  order: theme.order,
                  problemCount: themeProblems.length,
                  pdfPath: theme.pdfPath,
                  pdfDownloading: false,
                  problems: themeProblems
                };
              });
              return themesForDisplay.find(themeForDisplay => themeForDisplay.id === currentTheme.id);
            })
          )
      )
    );
  }

  private setUpEnglishPlaylistTheme() {
    this.englishPlaylistTheme$ = combineLatest([
      this.store.select(PlaylistSelectors.getEnglishPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$,
      this.theme$,
      this.problemId$
    ]).pipe(
      switchMap(([problems, playlist, currentTheme, problemId]) =>
        this.store
          .select(
            PlaylistSelectors.getReadableEnglishPlaylistProblems(
              problems.filter(it => it.themeId === currentTheme.id && it.id === problemId)
            )
          )
          .pipe(
            filter(it => it != null),
            map(readableProblems => {
              const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableEnglishPlaylistProblem>>(theme => {
                const themeProblems = readableProblems;
                return {
                  id: theme.id,
                  name: theme.name,
                  order: theme.order,
                  problemCount: themeProblems.length,
                  pdfPath: theme.pdfPath,
                  pdfDownloading: false,
                  problems: themeProblems
                };
              });
              return themesForDisplay.find(themeForDisplay => themeForDisplay.id === currentTheme.id);
            })
          )
      )
    );
  }

  private setUpNationalLanguagePlaylistTheme() {
    this.nationalLanguagePlaylistTheme$ = combineLatest([
      this.store.select(PlaylistSelectors.getNationalLanguagePlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$,
      this.theme$,
      this.problemId$
    ]).pipe(
      switchMap(([problems, playlist, currentTheme, problemId]) =>
        this.store
          .select(
            PlaylistSelectors.getReadableNationalLanguagePlaylistProblems(
              problems.filter(it => it.themeId === currentTheme.id && it.id === problemId)
            )
          )
          .pipe(
            filter(it => it != null),
            map(readableProblems => {
              const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableNationalLanguagePlaylistProblem>>(theme => {
                const themeProblems = readableProblems;
                return {
                  id: theme.id,
                  name: theme.name,
                  order: theme.order,
                  problemCount: themeProblems.length,
                  pdfPath: theme.pdfPath,
                  pdfDownloading: false,
                  problems: themeProblems
                };
              });
              return themesForDisplay.find(themeForDisplay => themeForDisplay.id === currentTheme.id);
            })
          )
      )
    );
  }

  private setUpJapaneseHistoryPlaylistTheme() {
    this.japaneseHistoryPlaylistTheme$ = combineLatest([
      this.store.select(PlaylistSelectors.getJapaneseHistoryPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$,
      this.theme$,
      this.problemId$
    ]).pipe(
      switchMap(([problems, playlist, currentTheme, problemId]) =>
        this.store
          .select(
            PlaylistSelectors.getReadableJapaneseHistoryPlaylistProblems(
              problems.filter(it => it.themeId === currentTheme.id && it.id === problemId)
            )
          )
          .pipe(
            filter(it => it != null),
            map(readableProblems => {
              const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableHistoryPlaylistProblem>>(theme => {
                const themeProblems = readableProblems;
                return {
                  id: theme.id,
                  name: theme.name,
                  order: theme.order,
                  problemCount: themeProblems.length,
                  pdfPath: theme.pdfPath,
                  pdfDownloading: false,
                  problems: themeProblems
                };
              });
              return themesForDisplay.find(themeForDisplay => themeForDisplay.id === currentTheme.id);
            })
          )
      )
    );
  }

  private setUpWorldHistoryPlaylistTheme() {
    this.worldHistoryPlaylistTheme$ = combineLatest([
      this.store.select(PlaylistSelectors.getWorldHistoryPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$,
      this.theme$,
      this.problemId$
    ]).pipe(
      switchMap(([problems, playlist, currentTheme, problemId]) =>
        this.store
          .select(
            PlaylistSelectors.getReadableWorldHistoryPlaylistProblems(
              problems.filter(it => it.themeId === currentTheme.id && it.id === problemId)
            )
          )
          .pipe(
            filter(it => it != null),
            map(readableProblems => {
              const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableHistoryPlaylistProblem>>(theme => {
                const themeProblems = readableProblems;
                return {
                  id: theme.id,
                  name: theme.name,
                  order: theme.order,
                  problemCount: themeProblems.length,
                  pdfPath: theme.pdfPath,
                  pdfDownloading: false,
                  problems: themeProblems
                };
              });
              return themesForDisplay.find(themeForDisplay => themeForDisplay.id === currentTheme.id);
            })
          )
      )
    );
  }

  private setUpGeographyPlaylistTheme() {
    this.sciencePlaylistTheme$ = combineLatest([
      this.store.select(PlaylistSelectors.getGeographyPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$,
      this.theme$,
      this.problemId$
    ]).pipe(
      switchMap(([problems, playlist, currentTheme, problemId]) =>
        this.store
          .select(
            PlaylistSelectors.getReadableGeographyPlaylistProblems(
              problems.filter(it => it.themeId === currentTheme.id && it.id === problemId)
            )
          )
          .pipe(
            filter(it => it != null),
            map(readableProblems => {
              const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
                const themeProblems = readableProblems;
                return {
                  id: theme.id,
                  name: theme.name,
                  order: theme.order,
                  problemCount: themeProblems.length,
                  pdfPath: theme.pdfPath,
                  pdfDownloading: false,
                  problems: themeProblems
                };
              });
              return themesForDisplay.find(themeForDisplay => themeForDisplay.id === currentTheme.id);
            })
          )
      )
    );
  }

  private setUpPoliticalEconomyPlaylistTheme() {
    this.sciencePlaylistTheme$ = combineLatest([
      this.store.select(PlaylistSelectors.getPoliticalEconomyPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$,
      this.theme$,
      this.problemId$
    ]).pipe(
      switchMap(([problems, playlist, currentTheme, problemId]) =>
        this.store
          .select(
            PlaylistSelectors.getReadablePoliticalEconomyPlaylistProblems(
              problems.filter(it => it.themeId === currentTheme.id && it.id === problemId)
            )
          )
          .pipe(
            filter(it => it != null),
            map(readableProblems => {
              const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
                const themeProblems = readableProblems;
                return {
                  id: theme.id,
                  name: theme.name,
                  order: theme.order,
                  problemCount: themeProblems.length,
                  pdfPath: theme.pdfPath,
                  pdfDownloading: false,
                  problems: themeProblems
                };
              });
              return themesForDisplay.find(themeForDisplay => themeForDisplay.id === currentTheme.id);
            })
          )
      )
    );
  }

  private setUpProblem() {
    this.subjectId$.pipe(take(1)).subscribe(subjectId => {
      if (this.scienceIds.includes(subjectId)) {
        this.scienceProblem$ = this.store.select(getScienceProblem).pipe(
          filter(it => it != null),
          shareReplay(1)
        );
        this.problem$ = this.scienceProblem$.pipe(problem => problem);
      } else if (subjectId === SubjectId.ENGLISH) {
        this.englishProblem$ = this.store.select(getEnglishProblem).pipe(
          filter(it => it != null),
          shareReplay(1)
        );
        this.problem$ = this.englishProblem$.pipe(problem => {
          return problem;
        });
      } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
        this.nationalLanguageProblems$ = this.store.select(getNationalLanguageProblemsWithSameId).pipe(
          filter(it => it != null),
          shareReplay(1),
          map(problems => problems)
        );
        this.problem$ = this.nationalLanguageProblems$.pipe(map(problems => problems[0]));
      } else if (subjectId === SubjectId.JAPANESE_HISTORY || subjectId === SubjectId.WORLD_HISTORY) {
        this.historyProblem$ = this.store.select(getHistoryProblem).pipe(
          filter(it => it != null),
          shareReplay(1)
        );
        this.problem$ = this.historyProblem$.pipe(problem => problem);
      }
    });

    this.subscriptions.push(
      combineLatest([this.subjectId$, this.problemId$, this.problemIds$]).subscribe(([subjectId, problemId, problemIds]) => {
        if (!problemIds.includes(problemId)) {
          this.isPreconditionError = true;
          const error = GeneralError.customMessage(CustomErrorMessage.PROBLEM_DETAIL_FAILED_PRECONDITION);
          this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error }));
          return;
        }
        this.isPreconditionError = false;

        if (this.scienceIds.includes(subjectId)) {
          this.store.dispatch(findScienceProblem({ subjectId, problemId }));
        } else if (subjectId === SubjectId.ENGLISH) {
          this.store.dispatch(findEnglishProblem({ subjectId, problemId }));
        } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
          this.store.dispatch(findNationalLanguageProblemsById({ subjectId, problemId }));
        } else if (subjectId === SubjectId.JAPANESE_HISTORY || subjectId === SubjectId.WORLD_HISTORY) {
          this.store.dispatch(findHistoryProblem({ subjectId, problemId }));
        }
      })
    );
  }

  private setUpReadableProblem() {
    this.subjectId$.pipe(take(1)).subscribe(subjectId => {
      if (this.scienceIds.includes(subjectId)) {
        this.readableScienceProblems$ = combineLatest([this.scienceProblem$]).pipe(
          switchMap(([problem]) => {
            const selector =
              subjectId === SubjectId.MATH
                ? this.store.select(getReadableMathProblem(problem))
                : subjectId === SubjectId.PHYSICS
                ? this.store.select(getReadablePhysicsProblem(problem))
                : subjectId === SubjectId.CHEMISTRY
                ? this.store.select(getReadableChemistryProblem(problem))
                : subjectId === SubjectId.BIOLOGY
                ? this.store.select(getReadableBiologyProblem(problem))
                : subjectId === SubjectId.GEOGRAPHY
                ? this.store.select(getReadableGeographyProblem(problem))
                : this.store.select(getReadablePoliticalEconomyProblem(problem));
            return selector;
          }),
          map(problem => {
            this.loadingReadableProblems$ = of(false);
            return [problem];
          }),
          filter(it => it != null)
        );
      } else if (subjectId === SubjectId.ENGLISH) {
        this.readableEnglishProblems$ = combineLatest([this.englishProblem$]).pipe(
          switchMap(([problem]) => {
            const selector = this.store.select(getReadableEnglishProblem(problem));
            return selector;
          }),
          map(problem => {
            this.loadingReadableProblems$ = of(false);
            return [problem];
          }),
          filter(it => it != null)
        );
      } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
        this.readableNationalLanguageProblems$ = combineLatest([this.nationalLanguageProblems$]).pipe(
          switchMap(([problems]) => {
            const selector = this.store.select(getReadableNationalLanguageProblemsFromProblems(problems));
            return selector;
          }),
          map(problems => {
            this.loadingReadableProblems$ = of(false);
            return problems;
          }),
          filter(it => it != null)
        );
      } else if (subjectId === SubjectId.JAPANESE_HISTORY) {
        this.readableJapaneseHistoryProblems$ = combineLatest([this.historyProblem$]).pipe(
          switchMap(([problem]) => {
            const selector = this.store.select(getReadableJapaneseHistoryProblem(problem));
            return selector;
          }),
          map(problem => {
            this.loadingReadableProblems$ = of(false);
            return [problem];
          }),
          filter(it => it != null)
        );
      } else if (subjectId === SubjectId.WORLD_HISTORY) {
        this.readableWorldHistoryProblems$ = combineLatest([this.historyProblem$]).pipe(
          switchMap(([problem]) => {
            const selector = this.store.select(getReadableWorldHistoryProblem(problem));
            return selector;
          }),
          map(problem => {
            this.loadingReadableProblems$ = of(false);
            return [problem];
          }),
          filter(it => it != null)
        );
      }
    });
  }

  private setUpProblemPositionFlags() {
    const problemId$ = this.problemId$.pipe(filter(it => it !== ''));
    this.isFirstProblem$ = combineLatest([this.playListProblems$, this.problemId$, this.themeId$]).pipe(
      map(([problems, problemId, themeId]) => problems[0].themeId === themeId && problems[0].id === problemId)
    );

    this.isLastProblem$ = combineLatest([this.playListProblems$, this.problemId$, this.themeId$]).pipe(
      map(
        ([problems, problemId, themeId]) =>
          problems[problems.length - 1].themeId === themeId && problems[problems.length - 1].id === problemId
      )
    );
  }

  private setUpBookmarks() {
    combineLatest([this.subjectId$])
      .pipe(take(1))
      .subscribe(([subjectId]) => {
        this.store.dispatch(BookmarkActions.initializeFindBookmarkState());
        this.store
          .select(getSignedInUser)
          .pipe(filter<User>(it => it != null && it !== 'none'))
          .pipe(take(1))
          .subscribe(user => {
            this.user = user;
            this.store.dispatch(BookmarkActions.initializeFindBookmarkState());
            this.store.dispatch(BookmarkActions.findBookmark({ userId: user.id }));
            this.store
              .select(BookmarkSelectors.getBookmark)
              .pipe(
                filter(it => it != null),
                take(1)
              )
              .subscribe(bookmark => {
                this.bookmark = bookmark;
                this.bookmarkSubjectProblems = [];
                if (bookmark.problems !== undefined) {
                  this.bookmarkSubjectProblems = this.bookmark.problems.filter(problem => problem.subjectId === subjectId);
                }
                this.store.dispatch(BookmarkActions.initializeFindBookmarkState());

                this.initializedBookmarkSubject.next(true);
              });
          });
      });
  }

  private setUpBrowserTitle(menuType = 'article') {
    const subjects$: Observable<Subject[]> = this.store.select(StaticDataSelectors.getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.subjectId$.pipe(take(1)).subscribe(subjectId => {
      let readableProblems$: Observable<
        ReadableScienceProblem[] | ReadableEnglishProblem[] | ReadableNationalLanguageProblem[] | ReadableHistoryProblem[]
      >;
      if (this.scienceIds.includes(subjectId)) {
        readableProblems$ = this.readableScienceProblems$;
      } else if (subjectId === SubjectId.ENGLISH) {
        readableProblems$ = this.readableEnglishProblems$;
      } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
        readableProblems$ = this.readableNationalLanguageProblems$;
      } else if (subjectId === SubjectId.JAPANESE_HISTORY) {
        readableProblems$ = this.readableJapaneseHistoryProblems$;
      } else if (subjectId === SubjectId.WORLD_HISTORY) {
        readableProblems$ = this.readableWorldHistoryProblems$;
      }

      if (readableProblems$) {
        combineLatest([subjects$, readableProblems$.pipe(filter(it => it !== null)), this.paperMode$]).subscribe(([subjects, problems]) => {
          const university: string = problems[0].university;
          const year: number = problems[0].year;
          const subject: string = SubjectUtil.getName(subjects, subjectId);
          const type = menuType === 'article' ? '問題' : '研究・解答';
          const title = `${university}　${year}年度　${subject}　${type}`;
          this.store.dispatch(setBrowserTitle({ subTitle: `${title}`, problemDetailFlag: true }));
          setTimeout(() => {
            this.store.dispatch(setTitle({ title }));
          });
        });
      }
    });
  }

  private setUpSearching() {
    this.problemSearching$ = this.store.select(getProblemSearching).pipe(startWith(true));
    this.searching$ = combineLatest([this.problemSearching$]).pipe(
      map(([problemSearching]) => {
        return problemSearching;
      })
    );
  }

  private findStaticDataIfNeeded() {
    this.store
      .select(getFetchedDate)
      .pipe(take(1))
      .subscribe(fetchedDate => {
        if (!fetchedDate) {
          Log.debug(this.LOG_SOURCE, `static data が存在していないため取得します`);
          this.store.dispatch(findStaticData());
          return;
        }
        const cacheExpired = Dates.isCachedDateExpired(fetchedDate, STATIC_DATA_CACHE_DAYS);
        if (cacheExpired) {
          Log.debug(this.LOG_SOURCE, `cache 期間を超過したため再度 static data を取得します. fetchedDate: ${fetchedDate}`);
          this.store.dispatch(initializeStaticDataState());
          this.store.dispatch(findStaticData());
          return;
        }
        Log.debug(this.LOG_SOURCE, 'static data が取得済みのため何もしません');
      });
  }

  // showPaper() {
  //   this.problemId$.pipe(take(1)).subscribe(problemId => {
  //     const { queryParams } = this.activatedRoute.snapshot;
  //     const url = RoutingPathResolver.resolveProblemDetail(problemId);
  //     this.store.dispatch(openWindow({ url, extras: { queryParams: { ...queryParams, mode: 'paper' } } }));
  //   });
  // }

  showPrint() {
    const ua = new UAParser(this.window.navigator.userAgent).getResult();
    if (ua.browser.name.indexOf('Safari') > -1) {
      document.execCommand('print', false, null);
    } else {
      (window as any).print();
    }
  }

  changeMenuType(event) {
    this.menuType$ = of(event);
    this.menuType$.pipe(take(1)).subscribe(menuType => this.setUpBrowserTitle(menuType));
  }

  addBookmark(problemId: string) {
    Log.debug(this.LOG_SOURCE, 'お気に入りを登録します');
    this.initializedBookmarkSubject.next(false);
    this.store.dispatch(
      dispatchInfoMessage({
        message: `お気に入りを登録しています`
      })
    );

    const request: AddBookmarkData = {
      userId: this.user.id,
      subjectId: this.subjectId,
      problemId
    };

    Log.debug(this.LOG_SOURCE, 'add bookmark', request);
    this.store.dispatch(BookmarkActions.addBookmark(request));

    this.store
      .select(BookmarkSelectors.getAddBookmarkResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        Log.debug(this.LOG_SOURCE, `add bookmark result: `, result);
        if (result.success) {
          this.store.dispatch(
            dispatchInfoMessage({
              message: `お気に入りを登録しました`
            })
          );
        } else {
          Log.warn(this.LOG_SOURCE, `add bookmark error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
          this.store.dispatch(
            dispatchInfoMessage({
              message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
            })
          );
        }

        this.store.dispatch(BookmarkActions.initializeAddBookmarkState());
        this.setUpBookmarks();
      });
  }

  deleteBookmark(problemId: string) {
    Log.debug(this.LOG_SOURCE, 'お気に入りを解除します');
    this.initializedBookmarkSubject.next(false);
    this.store.dispatch(
      dispatchInfoMessage({
        message: `お気に入りを解除しています`
      })
    );

    const request: DeleteBookmarkData = {
      userId: this.user.id,
      subjectId: this.subjectId,
      problemId
    };

    Log.debug(this.LOG_SOURCE, 'delete bookmark', request);
    this.store.dispatch(BookmarkActions.deleteBookmark(request));

    this.store
      .select(BookmarkSelectors.getDeleteBookmarkResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        Log.debug(this.LOG_SOURCE, `delete bookmark result: `, result);
        if (result.success) {
          this.store.dispatch(
            dispatchInfoMessage({
              message: `お気に入りを解除しました`
            })
          );
        } else {
          Log.warn(this.LOG_SOURCE, `delete bookmark error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
          this.store.dispatch(
            dispatchInfoMessage({
              message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
            })
          );
        }

        this.store.dispatch(BookmarkActions.initializeDeleteBookmarkState());
        this.setUpBookmarks();
      });
  }

  setLoadingReadableProblems(param: boolean) {
    this.loadingReadableProblems$ = of(param);
  }
}
