import { Component, OnDestroy, OnInit } from '@angular/core';
import { enter } from '../../../../resources/animations';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../reducers';
import { ActivatedRoute } from '@angular/router';
import { navigate, setTitle } from '../../../../actions/core.actions';
import { commonIdSavePurchase } from '../../../../actions/common-id/common-id-auth.actions';
import { filter, take } from 'rxjs/operators';
import { getCommonIdSavePurchaseResult } from '../../../../selectors/common-id/common-id-auth.selectors';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';

@Component({
  selector: 'app-common-id-purchase-callback',
  templateUrl: './purchase-callback.component.html',
  styleUrls: ['./purchase-callback.component.scss'],
  animations: [enter]
})
export class CommonIdPurchaseCallbackComponent implements OnInit, OnDestroy {
  private LOG_SOURCE = this.constructor.name;
  private title = 'プラン購入処理中...';

  loaded$: Observable<boolean>;

  constructor(protected store: Store<RootState>, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));

    this.setUp();
  }

  setUp() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.session_id) {
        this.store.dispatch(commonIdSavePurchase({ condition: { stripeSessionId: params.session_id } }));
        this.store
          .select(getCommonIdSavePurchaseResult)
          .pipe(
            filter(it => it != null),
            take(1)
          )
          .subscribe(result => {
            // this.loaded$ = of(true);
            if (result) {
              this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdPurchaseComplete(), extras: { replaceUrl: true } }));
            }
          });
      } else {
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdPurchase() }));
      }
    });
  }

  ngOnDestroy() {}
}
