import { Injectable } from '@angular/core';
import { CommonIdPlaylistSearchCondition } from 'src/app/models/common-id/common-id-playlist-search-condition';
import { CommonIdPlaylistSearchSortType } from 'src/app/models/common-id/common-id-playlist-search-query-params';
import { PLAYLISTS_SUBJECT_ALL_ID, SubjectId } from 'src/app/resources/config';
import { CommonIdPlaylistSortType } from 'src/app/resources/common-id-config';

@Injectable({
  providedIn: 'root'
})
export class CommonIdPlaylistsRouterService {
  getConditionFromQueryParams(url: string): CommonIdPlaylistSearchCondition {
    const currentQueryParamsString = url.split('?')[1];
    return this.getSearchCondition(currentQueryParamsString);
  }

  private getSearchCondition(queryParamsString: string): CommonIdPlaylistSearchCondition {
    const queryParams = new URLSearchParams(queryParamsString);
    const subjectId = queryParams.get('subjectId');
    const sortType = queryParams.get('sortType');
    const free = queryParams.get('free');
    const tag = queryParams.get('tag');

    const subjectIdAfterChecked = this.checkSubjectIdQueryParam(subjectId);

    const searchCondition = this.setSearchConditionFromQueryParams(subjectIdAfterChecked, sortType, free, tag);
    return searchCondition;
  }

  private checkSubjectIdQueryParam(subjectId: string): string {
    if (subjectId === null) return subjectId;

    // クエリパラメタに不正な教科が指定された場合は、全ての教科のIDに置き換える
    const subjectIds = Object.values(SubjectId).map(subjectId => subjectId.toString());
    const result = subjectIds.includes(subjectId) ? subjectId : PLAYLISTS_SUBJECT_ALL_ID;

    return result;
  }

  private setSearchConditionFromQueryParams(
    subjectId: string,
    sortType: string,
    free: string,
    tag: string
  ): CommonIdPlaylistSearchCondition {
    let condition: CommonIdPlaylistSearchCondition = {};
    if (subjectId) condition = { subjectId };

    if (sortType === CommonIdPlaylistSearchSortType.PUBLISHED_DATE) {
      condition = { ...condition, sortType: CommonIdPlaylistSortType.PUBLISHED_DATE };
    } else if (sortType === CommonIdPlaylistSearchSortType.PLAYLIST_ID_ASC) {
      condition = { ...condition, sortType: CommonIdPlaylistSortType.PLAYLIST_ID_ASC };
    } else if (sortType === CommonIdPlaylistSearchSortType.PLAYLIST_ID_DESC) {
      condition = { ...condition, sortType: CommonIdPlaylistSortType.PLAYLIST_ID_DESC };
    } else if (sortType === CommonIdPlaylistSearchSortType.DURATION) {
      condition = { ...condition, sortType: CommonIdPlaylistSortType.DURATION };
    }

    if (free === 'true') condition = { ...condition, free: true };

    if (tag) condition = { ...condition, tag };

    return condition;
  }
}
