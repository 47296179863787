import { StaticCommonData } from '../models/static-common-data';
import { CommonSearchCondition, ReadableCommonSearchCondition } from '../models/common-search-condition';
import {
  READABLE_DATA_NONE,
  ENGLISH_LOWEST_WORD_COUNT,
  ENGLISH_LOWEST_WORD_COUNT_DISPLAY_NAME,
  ENGLISH_HIGHEST_WORD_COUNT_DISPLAY_NAME,
  ENGLISH_HIGHEST_WORD_COUNT,
  SortTypeDisplayName,
  SortType,
  NATIONAL_LANGUAGE_LOWEST_WORD_COUNT_STRING,
  NATIONAL_LANGUAGE_HIGHEST_WORD_COUNT_STRING,
  NATIONAL_LANGUAGE_LOWEST_WORD_COUNT_DISPLAY_NAME,
  NATIONAL_LANGUAGE_HIGHEST_WORD_COUNT_DISPLAY_NAME,
  THEME_HISTORY,
  FIELD_MIX,
  CATEGORY_DELIMITER,
  BookmarkSortType,
  BookmarkSortTypeDisplayName
} from '../resources/config';
import { AppError } from '../errors/app-error';
import { GeneralError } from '../errors/general-error';
import {
  Problem,
  ReadableProblem,
  SciencePlaylistProblem,
  ReadableSciencePlaylistProblem,
  EnglishPlaylistProblem,
  ReadableEnglishPlaylistProblem,
  NationalLanguageProblem,
  ReadableNationalLanguageProblem,
  NationalLanguagePlaylistProblem,
  ReadableNationalLanguagePlaylistProblem,
  HistoryPlaylistProblem,
  ReadableHistoryPlaylistProblem
} from '../models/problem';
import { StaticScienceData } from '../models/static-science-data';
import {
  ScienceProblem,
  ReadableScienceProblem,
  EnglishProblem,
  ReadableEnglishProblem,
  HistoryProblem,
  ReadableHistoryProblem
} from '../models/problem';
import { Log } from '../utils/log';
import { StaticEnglishData } from '../models/static-english-data';
import { EnglishSearchCondition, ReadableEnglishSearchCondition } from '../models/english-search-condition';
import { StaticHistoryData } from '../models/static-history-data';
import { StaticNationalLanguageData } from '../models/static-national-language-data';
import {
  NationalLanguageCategories,
  NationalLanguageCategory,
  NationalLanguageSearchCondition,
  ReadableNationalLanguageSearchCondition
} from '../models/national-language-search-condition';
import { HistorySearchCondition, ReadableHistorySearchCondition } from '../models/history-search-condition';

const ARRAY_VALUE_DELIMITER = ', ';
const PROBLEM_NUMBER_PREFIX = '大問';
const DEPARTMENT_CATEGORY_NONE = '指定なし';
const SEQUENTIAL_ID_DELIMITER = '-';

const LOG_SOURCE = 'ReadableDataMapper';

/**
 * NOTE:
 * もし mapping データがなくても error にしたくない場合は throw の部分を要修正
 */
export class ReadableDataMapper {
  static mapCommonSearchCondition(source: CommonSearchCondition, staticCommonData: StaticCommonData): ReadableCommonSearchCondition {
    try {
      const subject = ReadableDataMapper.getSubjectName(source.subjectId, staticCommonData);
      const hasExternalData = source.hasExternalData ? '掲載あり' : '';
      const hasWordData = source.hasWordData ? 'Wordファイルあり' : '';
      const hasData = hasExternalData || hasWordData ? [hasExternalData, hasWordData].filter(v => v).join(', ') : '-';
      const thinking = source.isThinking ? '思考力問題のみ' : READABLE_DATA_NONE;
      const levels = source.levels
        ? source.levels.map(l => ReadableDataMapper.getLevelDisplayName(l, staticCommonData)).join(ARRAY_VALUE_DELIMITER)
        : READABLE_DATA_NONE;
      const yearRange = `${source.startYear} 〜 ${source.endYear}`;
      const universityTypes = source.universityTypeIds
        ? source.universityTypeIds
            .map(univTypeId => ReadableDataMapper.getUniversityTypeName(univTypeId, staticCommonData))
            .join(ARRAY_VALUE_DELIMITER)
        : READABLE_DATA_NONE;
      const areas = source.areaIds
        ? source.areaIds.map(areaId => ReadableDataMapper.getAreaName(areaId, staticCommonData)).join(ARRAY_VALUE_DELIMITER)
        : READABLE_DATA_NONE;
      const universities = source.universityIds
        ? source.universityIds.map(univId => ReadableDataMapper.getUniversityName(univId, staticCommonData)).join(ARRAY_VALUE_DELIMITER)
        : READABLE_DATA_NONE;
      const departmentCategory = source.departmentCategoryId
        ? ReadableDataMapper.getDepartmentCategoryName(source.departmentCategoryId, staticCommonData)
        : DEPARTMENT_CATEGORY_NONE;

      const readable: ReadableCommonSearchCondition = {
        subject,
        thinking,
        levels,
        yearRange,
        universityTypes,
        areas,
        universities,
        departmentCategory,
        hasExternalData,
        hasWordData,
        hasData
      };

      return readable;
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(e);
    }
  }

  static mapEnglishSearchCondition(source: EnglishSearchCondition, staticEnglishData: StaticEnglishData): ReadableEnglishSearchCondition {
    Log.debug(LOG_SOURCE, `mapEnglishSearchCondition source: `, source);
    try {
      const fields = source.fieldIds
        ? source.fieldIds.map(fieldId => ReadableDataMapper.getEnglishFieldName(fieldId, staticEnglishData)).join(ARRAY_VALUE_DELIMITER)
        : READABLE_DATA_NONE;
      const subTypes = source.subTypeIds
        ? source.subTypeIds
            .map(subTypeId => ReadableDataMapper.getEnglishSubTypeName(subTypeId, staticEnglishData))
            .join(ARRAY_VALUE_DELIMITER)
        : READABLE_DATA_NONE;
      const longSentenceTypes = source.longSentenceTypeIds
        ? source.longSentenceTypeIds
            .map(typeId => ReadableDataMapper.getEnglishLongSentenceTypeName(typeId, staticEnglishData))
            .join(ARRAY_VALUE_DELIMITER)
        : READABLE_DATA_NONE;
      const longSentenceWordCountRange = source.longSentenceWordCount
        ? ReadableDataMapper.getEnglishWordCountRange(source.longSentenceWordCount)
        : READABLE_DATA_NONE;
      const longSentenceKeywords = source.longSentenceKeywords
        ? source.longSentenceKeywords.join(ARRAY_VALUE_DELIMITER)
        : READABLE_DATA_NONE;

      const readable: ReadableEnglishSearchCondition = {
        fields,
        subTypes,
        longSentenceTypes,
        longSentenceWordCountRange,
        longSentenceKeywords
      };

      return readable;
    } catch (e) {
      Log.error(LOG_SOURCE, `mapEnglishSearchCondition error: `, e);
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(e);
    }
  }

  static mapNationalLanguageSearchCondition(
    source: NationalLanguageSearchCondition,
    staticNationalLanguageData: StaticNationalLanguageData
  ): ReadableNationalLanguageSearchCondition {
    Log.debug(LOG_SOURCE, `mapNationalLanguageSearchCondition source: `, source);
    try {
      const subjects = source.subjectIds
        ? source.subjectIds
            .map(subjectId => ReadableDataMapper.getNationalLanguageSubjectName(subjectId, staticNationalLanguageData))
            .join(ARRAY_VALUE_DELIMITER)
        : READABLE_DATA_NONE;
      const wordCountRange = source.wordCount ? ReadableDataMapper.getNationalLanguageWordCountRange(source.wordCount) : READABLE_DATA_NONE;
      const categories = source.categories
        ? ReadableDataMapper.getNationalLanguageCategoryName(source.categories, staticNationalLanguageData)
        : ({
            contemporary: { contents: [SEQUENTIAL_ID_DELIMITER], genres: [SEQUENTIAL_ID_DELIMITER], fieldId: '1' },
            japaneseClassic: { contents: [SEQUENTIAL_ID_DELIMITER], genres: [SEQUENTIAL_ID_DELIMITER], fieldId: '2' },
            chineseClassic: { contents: [SEQUENTIAL_ID_DELIMITER], genres: [SEQUENTIAL_ID_DELIMITER], fieldId: '3' }
          } as NationalLanguageCategories);
      const keywords = source.keywords ? source.keywords.join(ARRAY_VALUE_DELIMITER) : READABLE_DATA_NONE;

      const readable: ReadableNationalLanguageSearchCondition = {
        subjects,
        fields: subjects,
        wordCountRange,
        categories,
        keywords
      };

      return readable;
    } catch (e) {
      Log.error(LOG_SOURCE, `mapNationalLanguageSearchCondition error: `, e);
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(e);
    }
  }

  static mapHistorySearchCondition(source: HistorySearchCondition): ReadableHistorySearchCondition {
    Log.debug(LOG_SOURCE, `mapHistorySearchCondition source: `, source);
    try {
      const keywords = source.keywords ? source.keywords.join(ARRAY_VALUE_DELIMITER) : READABLE_DATA_NONE;

      const readable: ReadableHistorySearchCondition = {
        keywords
      };

      return readable;
    } catch (e) {
      Log.error(LOG_SOURCE, `mapHistorySearchCondition error: `, e);
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(e);
    }
  }

  static getSortTypeDisplayName(sortType: string): string {
    switch (sortType) {
      case SortType.D_CODE:
        return SortTypeDisplayName.D_CODE;
      case SortType.UNIVERSITY_NAME:
        return SortTypeDisplayName.UNIVERSITY_NAME;
      case SortType.YEAR_ASC:
        return SortTypeDisplayName.YEAR_ASC;
      case SortType.YEAR_DESC:
        return SortTypeDisplayName.YEAR_DESC;
      case SortType.LEVEL_ASC:
        return SortTypeDisplayName.LEVEL_ASC;
      case SortType.LEVEL_DESC:
        return SortTypeDisplayName.LEVEL_DESC;
      default:
        Log.error(LOG_SOURCE, `不明な sortType です: ${sortType}`);
        return sortType;
    }
  }

  static getBookmarkSortTypeDisplayName(sortType: string): string {
    switch (sortType) {
      case BookmarkSortType.CREATED_AT_ASC:
        return BookmarkSortTypeDisplayName.CREATED_AT_ASC;
      case BookmarkSortType.CREATED_AT_DESC:
        return BookmarkSortTypeDisplayName.CREATED_AT_DESC;
      default:
        Log.error(LOG_SOURCE, `不明な bookmark sortType です: ${sortType}`);
        return sortType;
    }
  }

  /** Visible for testing */
  static getSubjectName(subjectId: string, staticCommonData: StaticCommonData): string {
    const subject = staticCommonData.subjects.find(it => it.id === subjectId);
    if (!subject) {
      Log.error(LOG_SOURCE, `指定された subject が存在しません. subjectId: ${subjectId}`);
      return subjectId;
    }
    return subject.name;
  }

  /** Visible for testing */
  static getLevelDisplayName(levelNumber: number, staticCommonData: StaticCommonData): string {
    const level = staticCommonData.levels.find(it => it.level === Number(levelNumber));
    if (!level) {
      Log.error(LOG_SOURCE, `指定された level が存在しません. levelNumber: ${levelNumber}`);
      return `${levelNumber}`;
    }
    return level.displayName;
  }

  /** Visible for testing */
  static getUniversityTypeName(univTypeId: string, staticCommonData: StaticCommonData): string {
    const univType = staticCommonData.universityTypes.find(it => it.id === univTypeId);
    if (!univType) {
      Log.error(LOG_SOURCE, `指定された universityType が存在しません. univTypeId: ${univTypeId}`);
      return univTypeId;
    }
    return univType.name;
  }

  /** Visible for testing */
  static getAreaName(areaId: string, staticCommonData: StaticCommonData): string {
    const area = staticCommonData.areas.find(it => it.id === areaId);
    if (!area) {
      Log.error(LOG_SOURCE, `指定された area が存在しません. areaId: ${areaId}`);
      return areaId;
    }
    return area.name;
  }

  /** Visible for testing */
  static getUniversityName(univId: string, staticCommonData: StaticCommonData): string {
    const univ = staticCommonData.universities.find(it => it.id === univId);
    if (!univ) {
      Log.error(LOG_SOURCE, `指定された university が存在しません. univId: ${univId}`);
      return univId;
    }
    return univ.name;
  }

  /** Visible for testing */
  static getDepartmentCategoryName(depCategoryId: string, staticCommonData: StaticCommonData): string {
    const depCategory = staticCommonData.departmentCategories.find(it => it.id === depCategoryId);
    if (!depCategory) {
      Log.error(LOG_SOURCE, `指定された departmentCategory が存在しません. univId: ${depCategoryId}`);
      return depCategoryId;
    }
    return depCategory.name;
  }

  static getEnglishWordCountRange(wordCount: { min: number; max: number }): string {
    const min = Number(wordCount.min) === ENGLISH_LOWEST_WORD_COUNT ? ENGLISH_LOWEST_WORD_COUNT_DISPLAY_NAME : `${wordCount.min}`;
    const max = Number(wordCount.max) === ENGLISH_HIGHEST_WORD_COUNT ? ENGLISH_HIGHEST_WORD_COUNT_DISPLAY_NAME : `${wordCount.max}`;
    return `${min} 〜 ${max}`;
  }

  static reduceNationalLanguageCategories(categories: string[], staticNationalLanguageData: StaticNationalLanguageData) {
    return categories.reduce<NationalLanguageCategory[]>((acc, it) => {
      const ids = it.split('-');
      const fieldId = ids[0];
      const unitId = ids[2];
      const isGenre = unitId[0] === '1';
      const unitName = staticNationalLanguageData.units.find(unit => unit.id === unitId).name;
      const index = acc.findIndex(a => a.fieldId === fieldId);
      if (index !== -1) {
        if (isGenre) {
          if (!acc[index].genres) acc[index].genres = [unitName];
          else if (!acc[index].genres.includes(unitName)) acc[index].genres.push(unitName);
        } else {
          if (!acc[index].contents) acc[index].contents = [unitName];
          else if (!acc[index].contents.includes(unitName)) acc[index].contents.push(unitName);
        }
      } else {
        if (isGenre) {
          acc.push({ contents: null, genres: [unitName], fieldId });
        } else {
          acc.push({ contents: [unitName], genres: null, fieldId });
        }
      }
      return acc;
    }, []);
  }

  static getNationalLanguageCategoryName(
    categories: string[],
    staticNationalLanguageData: StaticNationalLanguageData
  ): NationalLanguageCategories {
    const c = ReadableDataMapper.reduceNationalLanguageCategories(categories, staticNationalLanguageData).map(it => {
      const genreDataLength = staticNationalLanguageData.units.filter(unit => unit.parentFieldId === it.fieldId && unit.id[0] === '1')
        .length;
      it.genres = !it.genres ? [SEQUENTIAL_ID_DELIMITER] : it.genres.length === genreDataLength ? ['すべて'] : it.genres;
      const contentDataLength = staticNationalLanguageData.units.filter(unit => unit.parentFieldId === it.fieldId && unit.id[0] === '2')
        .length;
      it.contents = !it.contents ? [SEQUENTIAL_ID_DELIMITER] : it.contents.length === contentDataLength ? ['すべて'] : it.contents;
      return it;
    });
    return {
      contemporary: c.find(it => it.fieldId === '1') ?? {
        genres: [SEQUENTIAL_ID_DELIMITER],
        contents: [SEQUENTIAL_ID_DELIMITER],
        fieldId: '1'
      },
      japaneseClassic: c.find(it => it.fieldId === '2') ?? {
        genres: [SEQUENTIAL_ID_DELIMITER],
        contents: [SEQUENTIAL_ID_DELIMITER],
        fieldId: '2'
      },
      chineseClassic: c.find(it => it.fieldId === '3') ?? {
        genres: [SEQUENTIAL_ID_DELIMITER],
        contents: [SEQUENTIAL_ID_DELIMITER],
        fieldId: '3'
      }
    };
  }

  static getNationalLanguageWordCountRange(wordCount: { min: number; max: number }): string {
    const min =
      wordCount.min === parseInt(NATIONAL_LANGUAGE_LOWEST_WORD_COUNT_STRING, 10)
        ? NATIONAL_LANGUAGE_LOWEST_WORD_COUNT_DISPLAY_NAME
        : `${wordCount.min}`;
    const max =
      wordCount.max === parseInt(NATIONAL_LANGUAGE_HIGHEST_WORD_COUNT_STRING, 10)
        ? NATIONAL_LANGUAGE_HIGHEST_WORD_COUNT_DISPLAY_NAME
        : `${wordCount.max}`;
    return `${min} 〜 ${max}`;
  }

  // problem -----------------------------------------------------------------------

  static mapProblem(source: Problem, staticCommonData: StaticCommonData): ReadableProblem {
    const university = ReadableDataMapper.getUniversityName(source.universityId, staticCommonData);
    const thinking = source.isThinking ? '【思考力】\n' : '';
    const level = ReadableDataMapper.getLevelDisplayName(source.level, staticCommonData);
    const problemNumber = `${PROBLEM_NUMBER_PREFIX} ${source.problemNumber}`;

    const readable: ReadableProblem = {
      id: source.id,
      university,
      departments: source.departments,
      year: source.year,
      thinking,
      level,
      levelNumber: source.level,
      book: source.bookName,
      page: source.page,
      problemNumber
    };
    if (source.hasExternalData) readable.hasExternalData = source.hasExternalData;
    if (source.hasWordData) readable.hasWordData = source.hasWordData;
    if (source.examPaper) readable.examPaper = source.examPaper;
    if (source.fImage) readable.fImage = source.fImage;
    if (source.questionImages) readable.questionImages = source.questionImages;
    if (source.answerImages) readable.answerImages = source.answerImages;
    if (source.questionLayout) readable.questionLayout = source.questionLayout;
    if (source.answerLayout) readable.answerLayout = source.answerLayout;
    if (source.isOriginalProblem) readable.isOriginalProblem = source.isOriginalProblem;
    if (source.originalOverview) readable.originalOverview = source.originalOverview;
    if (source.originalSource) readable.originalSource = source.originalSource;
    if (source.showProblemNumberFlg) readable.showProblemNumberFlg = source.showProblemNumberFlg;

    return readable;
  }

  static mapEnglishProblem(
    source: EnglishProblem,
    staticCommonData: StaticCommonData,
    staticEnglishData: StaticEnglishData
  ): ReadableEnglishProblem {
    try {
      const common = ReadableDataMapper.mapProblem(source, staticCommonData);
      const categories = source.categories
        ? source.categories.map(cat => ReadableDataMapper.mapEnglishCategory(cat, staticEnglishData)).join('\n')
        : '';
      const simpleCategories = source.categories
        ? source.categories.map(cat => ReadableDataMapper.mapEnglishSimpleCategory(cat, staticEnglishData)).join('\n')
        : '';
      // 表示のときは field, subType をそれぞれ ', ' で連結し、 fields と subTypes は '/' で連結する
      const fields = source.fieldIds.map(fieldId => ReadableDataMapper.getEnglishFieldName(fieldId, staticEnglishData)).join(', ');
      const subTypes = source.subTypeIds
        .map(subTypeId => ReadableDataMapper.getEnglishSubTypeName(subTypeId, staticEnglishData))
        .join(', ');

      const readable: ReadableEnglishProblem = {
        ...common,
        categories,
        simpleCategories,
        fields,
        subTypes
      };

      if (source.longSentences && source.longSentences.length !== 0) {
        readable.longSentences = [...source.longSentences];
      }

      return readable;
    } catch (e) {
      Log.error(LOG_SOURCE, `mapEnglishProblem error: ${e}, cause: ${e.cause}`);
    }
  }

  static mapScienceProblem(
    source: ScienceProblem,
    staticCommonData: StaticCommonData,
    staticScienceData: StaticScienceData
  ): ReadableScienceProblem {
    try {
      const common = ReadableDataMapper.mapProblem(source, staticCommonData);
      const categories = source.categories
        ? source.categories.map(cat => ReadableDataMapper.mapScienceCategory(cat, staticScienceData)).join('\n')
        : '';
      const simpleCategories = source.categories
        ? source.categories.map(cat => ReadableDataMapper.mapScienceSimpleCategory(cat, staticScienceData)).join('\n')
        : '';

      const readable: ReadableScienceProblem = {
        ...common,
        categories,
        simpleCategories
      };

      return readable;
    } catch (e) {
      Log.error(LOG_SOURCE, `mapScienceProblem error: ${e}, cause: ${e.cause}`);
    }
  }

  static mapNationalLanguageProblem(
    source: NationalLanguageProblem,
    staticCommonData: StaticCommonData,
    staticNationalLanguageData: StaticNationalLanguageData
  ): ReadableNationalLanguageProblem {
    try {
      const common = ReadableDataMapper.mapProblem(source, staticCommonData);
      const categories = ReadableDataMapper.mapNationalLanguageFields(source.categories, staticNationalLanguageData);
      const filteredCategories = source.categories.filter(category => {
        // ジャンルのみ
        const splitIds = category.split(CATEGORY_DELIMITER);
        return splitIds[2].startsWith('2') ? false : true;
      });
      const simpleCategories = filteredCategories
        ? filteredCategories.map(cat => ReadableDataMapper.mapNationalLanguageSimpleCategory(cat, staticNationalLanguageData)).join('\n')
        : '';

      const unitGenre = ReadableDataMapper.getNationalLanguageUnitGenre(source.categories, staticNationalLanguageData);
      const readable: ReadableNationalLanguageProblem = {
        ...common,
        categories,
        simpleCategories,
        problemOrder: source.problemOrder,
        displayProblemOrder: source.displayProblemOrder,
        unitContents: source.unitContents,
        unitGenre,
        unitGenreDescription: source.unitGenreDescription,
        author: source.author,
        title: source.title,
        titleComplement: source.titleComplement,
        characterCount: source.characterCount,
        displayCharacterCount: source.displayCharacterCount,
        overview: source.overview,
        isCommonOverview: source.isCommonOverview
      };

      return readable;
    } catch (e) {
      Log.error(LOG_SOURCE, `mapNationalLanguageProblem error: ${e}, cause: ${e.cause}`);
    }
  }

  static mapHistoryProblem(
    source: HistoryProblem,
    staticCommonData: StaticCommonData,
    staticHistoryData: StaticHistoryData
  ): ReadableHistoryProblem {
    try {
      const common = ReadableDataMapper.mapProblem(source, staticCommonData);
      const readableCategories = source.categories
        ? source.categories.map(cat => ReadableDataMapper.mapHistoryCategory(cat, staticHistoryData))
        : [];
      const categories = readableCategories
        .filter(cat => cat.match('出題テーマ:'))
        .concat(readableCategories.filter(cat => !cat.match('出題テーマ:')))
        .join('\n');
      const readableSimpleCategories = source.categories
        ? source.categories.map(cat => ReadableDataMapper.mapHistorySimpleCategory(cat, staticHistoryData))
        : [];
      const simpleCategories = readableSimpleCategories.filter(cat => !cat.match('出題テーマ:')).join('\n');

      const readable: ReadableHistoryProblem = {
        ...common,
        categories,
        simpleCategories
      };

      return readable;
    } catch (e) {
      Log.error(LOG_SOURCE, `mapHistoryProblem error: ${e}, cause: ${e.cause}`);
    }
  }

  static mapEnglishPlaylistProblem(
    source: EnglishPlaylistProblem,
    staticCommonData: StaticCommonData,
    staticEnglishData: StaticEnglishData
  ): ReadableEnglishPlaylistProblem {
    try {
      const readableEnglishProblem = ReadableDataMapper.mapEnglishProblem(source, staticCommonData, staticEnglishData);
      const themeNumber = Number(source.themeId.substring(7));
      const sequentialId = `${themeNumber}${SEQUENTIAL_ID_DELIMITER}${source.orderInTheme}`;

      const readable: ReadableEnglishPlaylistProblem = {
        ...readableEnglishProblem,
        comment: source.comment,
        pdfPath: source.pdfPath || '',
        themeId: source.themeId,
        orderInTheme: source.orderInTheme,
        sequentialId
      };
      if (source.answerComment) readable.answerComment = source.answerComment;

      return readable;
    } catch (e) {
      Log.error(LOG_SOURCE, `mapEnglishPlaylistProblem error: ${e}, cause: ${e.cause}`);
    }
  }

  static mapSciencePlaylistProblem(
    source: SciencePlaylistProblem,
    staticCommonData: StaticCommonData,
    staticScienceData: StaticScienceData
  ): ReadableSciencePlaylistProblem {
    try {
      const readableScienceProblem = ReadableDataMapper.mapScienceProblem(source, staticCommonData, staticScienceData);
      const themeNumber = Number(source.themeId.substring(7));
      const sequentialId = `${themeNumber}${SEQUENTIAL_ID_DELIMITER}${source.orderInTheme}`;

      const readable: ReadableSciencePlaylistProblem = {
        ...readableScienceProblem,
        comment: source.comment,
        pdfPath: source.pdfPath || '',
        themeId: source.themeId,
        orderInTheme: source.orderInTheme,
        sequentialId
      };
      if (source.answerComment) readable.answerComment = source.answerComment;

      return readable;
    } catch (e) {
      Log.error(LOG_SOURCE, `mapSciencePlaylistProblem error: ${e}, cause: ${e.cause}`);
    }
  }

  static mapNationalLanguagePlaylistProblem(
    source: NationalLanguagePlaylistProblem,
    staticCommonData: StaticCommonData,
    staticNationalLanguageData: StaticNationalLanguageData
  ): ReadableNationalLanguagePlaylistProblem {
    try {
      const readableNationalLanguageProblem = ReadableDataMapper.mapNationalLanguageProblem(
        source,
        staticCommonData,
        staticNationalLanguageData
      );
      const themeNumber = Number(source.themeId.substring(7));
      const sequentialId = `${themeNumber}${SEQUENTIAL_ID_DELIMITER}${source.orderInTheme}`;

      const readable: ReadableNationalLanguagePlaylistProblem = {
        ...readableNationalLanguageProblem,
        comment: source.comment,
        pdfPath: source.pdfPath || '',
        themeId: source.themeId,
        orderInTheme: source.orderInTheme,
        sequentialId
      };
      if (source.answerComment) readable.answerComment = source.answerComment;

      return readable;
    } catch (e) {
      Log.error(LOG_SOURCE, `mapNationalLanguagePlaylistProblem error: ${e}, cause: ${e.cause}`);
    }
  }

  static mapHistoryPlaylistProblem(
    source: HistoryPlaylistProblem,
    staticCommonData: StaticCommonData,
    staticHistoryData: StaticHistoryData
  ): ReadableHistoryPlaylistProblem {
    try {
      const readableHistoryProblem = ReadableDataMapper.mapHistoryProblem(source, staticCommonData, staticHistoryData);
      const themeNumber = Number(source.themeId.substring(7));
      const sequentialId = `${themeNumber}${SEQUENTIAL_ID_DELIMITER}${source.orderInTheme}`;

      const readable: ReadableHistoryPlaylistProblem = {
        ...readableHistoryProblem,
        comment: source.comment,
        pdfPath: source.pdfPath || '',
        themeId: source.themeId,
        orderInTheme: source.orderInTheme,
        sequentialId
      };
      if (source.answerComment) readable.answerComment = source.answerComment;

      return readable;
    } catch (e) {
      Log.error(LOG_SOURCE, `mapHistoryPlaylistProblem error: ${e}, cause: ${e.cause}`);
    }
  }

  // english -----------------------------------------------------------------------

  static mapEnglishCategory(categoryIdString: string, staticEnglishData: StaticEnglishData): string {
    const source = categoryIdString.split('-');
    if (source.length !== 3) {
      Log.error(LOG_SOURCE, `category の形式が不正です: ${categoryIdString}`);
      return categoryIdString;
    }

    const [fieldId, unitId] = [source[1], source[2]];
    const fieldName = ReadableDataMapper.getEnglishBunyaFieldName(fieldId, staticEnglishData);
    const unitName = ReadableDataMapper.getEnglishBunyaUnitName(unitId, staticEnglishData);

    return `${fieldName} / ${unitName}`;
  }

  static mapEnglishSimpleCategory(categoryIdString: string, staticEnglishData: StaticEnglishData): string {
    const source = categoryIdString.split('-');
    if (source.length !== 3) {
      Log.error(LOG_SOURCE, `category の形式が不正です: ${categoryIdString}`);
      return categoryIdString;
    }

    const unitId = source[2];
    const unitName = ReadableDataMapper.getEnglishBunyaUnitName(unitId, staticEnglishData);

    return `${unitName}`;
  }

  static getEnglishFieldName(englishFieldId: string, staticEnglishData: StaticEnglishData): string {
    const field = staticEnglishData.fields.find(it => it.id === englishFieldId);
    if (!field) {
      Log.error(LOG_SOURCE, `指定された englishField が存在しません. englishFieldId: ${englishFieldId}`);
      return englishFieldId;
    }
    return field.name;
  }

  static getEnglishSubTypeName(subTypeId: string, staticEnglishData: StaticEnglishData): string {
    const subType = staticEnglishData.subTypes.find(it => it.id === subTypeId);
    if (!subType) {
      Log.error(LOG_SOURCE, `指定された englishSubType が存在しません. subTypeId: ${subTypeId}`);
      return subTypeId;
    }
    return subType.name;
  }

  static getEnglishLongSentenceTypeName(typeId: string, staticEnglishData: StaticEnglishData): string {
    const sentenceType = staticEnglishData.longSentenceTypes.find(it => it.id === typeId);
    if (!sentenceType) {
      Log.error(LOG_SOURCE, `指定された englishLongSentenceType が存在しません. longSentenceTypeId: ${typeId}`);
      return typeId;
    }
    return sentenceType.name;
  }

  // BtoCの分野検索用
  static getEnglishBunyaSubjectName(englishBunyaSubjectId: string, staticEnglishData: StaticEnglishData): string {
    const subject = staticEnglishData.bunyaSubjects.find(it => it.id === englishBunyaSubjectId);
    if (!subject) {
      Log.error(LOG_SOURCE, `指定された englishBunyaSubjectId が存在しません. englishBunyaSubjectId: ${englishBunyaSubjectId}`);
      return englishBunyaSubjectId;
    }
    return subject.name;
  }

  static getEnglishBunyaFieldName(englishBunyaFieldId: string, staticEnglishData: StaticEnglishData): string {
    const field = staticEnglishData.bunyaFields.find(it => it.id === englishBunyaFieldId);
    if (!field) {
      Log.error(LOG_SOURCE, `指定された englishBunyaField が存在しません. englishBunyaFieldId: ${englishBunyaFieldId}`);
      return englishBunyaFieldId;
    }
    return field.name;
  }

  static getEnglishBunyaUnitName(englishBunyaUnitId: string, staticEnglishData: StaticEnglishData): string {
    const unit = staticEnglishData.bunyaUnits.find(it => it.id === englishBunyaUnitId);
    if (!unit) {
      Log.error(LOG_SOURCE, `指定された englishBunyaUnit が存在しません. englishBunyaUnitId: ${englishBunyaUnitId}`);
      return englishBunyaUnitId;
    }
    return unit.name;
  }

  // science (math, physics, chemistry, biology, geography, political economy) -----------------------------

  /**
   * @param source: <subject-id>-<field-id>-<unit-id> 形式の文字列
   */
  static mapScienceCategory(categoryIdString: string, staticScienceData: StaticScienceData): string {
    const source = categoryIdString.split('-');
    if (source.length !== 3) {
      Log.error(LOG_SOURCE, `category の形式が不正です: ${categoryIdString}`);
      return categoryIdString;
    }

    const [subjectId, fieldId, unitId] = [source[0], source[1], source[2]];
    const subjectName = ReadableDataMapper.getScienceSubjectName(subjectId, staticScienceData);
    const fieldName = ReadableDataMapper.getScienceFieldName(fieldId, staticScienceData);
    const unitName = ReadableDataMapper.getScienceUnitName(unitId, staticScienceData);

    return `${subjectName} / ${fieldName} / ${unitName}`;
  }

  /**
   * @param source: <subject-id>-<field-id>-<unit-id> 形式の文字列
   */
  static mapScienceSimpleCategory(categoryIdString: string, staticScienceData: StaticScienceData): string {
    const source = categoryIdString.split('-');
    if (source.length !== 3) {
      Log.error(LOG_SOURCE, `category の形式が不正です: ${categoryIdString}`);
      return categoryIdString;
    }

    const unitId = source[2];
    const unitName = ReadableDataMapper.getScienceUnitName(unitId, staticScienceData);

    return unitName;
  }

  /** Visible for testing */
  static getScienceSubjectName(subjectId: string, staticScienceData: StaticScienceData): string {
    const subject = staticScienceData.subjects.find(it => it.id === subjectId);
    if (!subject) {
      Log.error(LOG_SOURCE, `指定された subject が存在しません. subjectId: ${subjectId}`);
      return subjectId;
    }
    return subject.name;
  }

  /** Visible for testing */
  static getScienceFieldName(fieldId: string, staticScienceData: StaticScienceData): string {
    const field = staticScienceData.fields.find(it => it.id === fieldId);
    if (!field) {
      Log.error(LOG_SOURCE, `指定された field が存在しません. fieldId: ${fieldId}`);
      return fieldId;
    }
    return field.name;
  }

  /** Visible for testing */
  static getScienceUnitName(unitId: string, staticScienceData: StaticScienceData): string {
    const unit = staticScienceData.units.find(it => it.id === unitId);
    if (!unit) {
      Log.error(LOG_SOURCE, `指定された unit が存在しません. unitId: ${unitId}`);
      return unitId;
    }
    return unit.name;
  }

  // national language --------------------------------------------------------

  static mapNationalLanguageCategory(categoryIdString: string, staticNationalLanguageData: StaticNationalLanguageData): string {
    const source = categoryIdString.split('-');
    if (source.length !== 3) {
      Log.error(LOG_SOURCE, `category の形式が不正です: ${categoryIdString}`);
      return categoryIdString;
    }

    const [fieldId, unitId] = [source[1], source[2]];
    const fieldName = ReadableDataMapper.getHistoryFieldName(fieldId, staticNationalLanguageData);
    const unitName = ReadableDataMapper.getHistoryUnitName(unitId, staticNationalLanguageData);

    return `${fieldName} / ${unitName}`;
  }

  static mapNationalLanguageSimpleCategory(categoryIdString: string, staticNationalLanguageData: StaticNationalLanguageData): string {
    const source = categoryIdString.split('-');
    if (source.length !== 3) {
      Log.error(LOG_SOURCE, `category の形式が不正です: ${categoryIdString}`);
      return categoryIdString;
    }

    const unitId = source[2];
    const unitName = ReadableDataMapper.getHistoryUnitName(unitId, staticNationalLanguageData);

    return unitName;
  }

  static mapNationalLanguageFields(categoryIds: string[], staticNationalLanguageData: StaticNationalLanguageData): string {
    return categoryIds
      .reduce((acc, it) => {
        const source = it.split('-');
        if (source.length !== 3) {
          Log.error(LOG_SOURCE, `category の形式が不正です: ${it}`);
          return acc;
        }

        const fieldId = source[1];
        const fieldName = ReadableDataMapper.getNationalLanguageSubjectName(fieldId, staticNationalLanguageData);
        if (acc.includes(fieldName)) return acc;
        acc.push(fieldName);
        return acc;
      }, [])
      .join(', ');
  }

  static getNationalLanguageSubjectName(subjectId: string, staticNationalLanguageData: StaticNationalLanguageData): string {
    const subject = staticNationalLanguageData.subjects.find(it => it.id === subjectId);
    if (!subject) {
      Log.error(LOG_SOURCE, `指定された subjectId が存在しません. subjectId: ${subjectId}`);
      return subjectId;
    }
    return subject.name;
  }

  static getNationalLanguageUnitGenre(categoryIds: string[], staticNationalLanguageData: StaticNationalLanguageData): string {
    const unitId = categoryIds.map(it => it.split('-')[2]).find(it => it[0] === '1');
    const unit = staticNationalLanguageData.units.find(it => it.id === unitId);
    if (!unit) {
      Log.error(LOG_SOURCE, `指定された unitId が存在しません. unitId: ${unitId}`);
      return unitId;
    }
    return unit.name;
  }

  static getNationalLanguageUnitName(unitId: string, staticNationalLanguageData: StaticNationalLanguageData): string {
    const unit = staticNationalLanguageData.units.find(it => it.id === unitId);
    if (!unit) {
      Log.error(LOG_SOURCE, `指定された unit が存在しません. unitId: ${unitId}`);
      return unitId;
    }
    return unit.name;
  }

  // history (japanese history) --------------------------------------------------------

  static mapHistoryCategory(categoryIdString: string, staticHistoryData: StaticHistoryData): string {
    const source = categoryIdString.split('-');
    if (source.length !== 3) {
      Log.error(LOG_SOURCE, `category の形式が不正です: ${categoryIdString}`);
      return categoryIdString;
    }

    const [fieldId, unitId] = [source[1], source[2]];
    const fieldName = ReadableDataMapper.getHistoryFieldName(fieldId, staticHistoryData);
    const unitName = ReadableDataMapper.getHistoryUnitName(unitId, staticHistoryData);

    if (fieldName.match(`^${THEME_HISTORY}$`)) return `<b>出題テーマ:</b> ${unitName}`;
    if (fieldName.match(`^${FIELD_MIX}$`)) return unitName;

    return `${fieldName} / ${unitName}`;
  }

  static mapHistorySimpleCategory(categoryIdString: string, staticHistoryData: StaticHistoryData): string {
    const source = categoryIdString.split('-');
    if (source.length !== 3) {
      Log.error(LOG_SOURCE, `category の形式が不正です: ${categoryIdString}`);
      return categoryIdString;
    }

    const [fieldId, unitId] = [source[1], source[2]];
    const fieldName = ReadableDataMapper.getHistoryFieldName(fieldId, staticHistoryData);
    const unitName = ReadableDataMapper.getHistoryUnitName(unitId, staticHistoryData);

    if (fieldName.match(`^${THEME_HISTORY}$`)) return `<b>出題テーマ:</b> ${unitName}`;
    if (fieldName.match(`^${FIELD_MIX}$`)) return unitName;

    return unitName;
  }

  /** Visible for testing */
  static getHistorySubjectName(subjectId: string, staticHistoryData: StaticHistoryData): string {
    const subject = staticHistoryData.subjects.find(it => it.id === subjectId);
    if (!subject) {
      Log.error(LOG_SOURCE, `指定された subject が存在しません. subjectId: ${subjectId}`);
      return subjectId;
    }
    return subject.name;
  }

  /** Visible for testing */
  static getHistoryFieldName(fieldId: string, staticHistoryData: StaticHistoryData): string {
    const field = staticHistoryData.fields.find(it => it.id === fieldId);
    if (!field) {
      Log.error(LOG_SOURCE, `指定された field が存在しません. fieldId: ${fieldId}`);
      return fieldId;
    }
    return field.name;
  }

  /** Visible for testing */
  static getHistoryUnitName(unitId: string, staticHistoryData: StaticHistoryData): string {
    const unit = staticHistoryData.units.find(it => it.id === unitId);
    if (!unit) {
      Log.error(LOG_SOURCE, `指定された unit が存在しません. unitId: ${unitId}`);
      return unitId;
    }
    return unit.name;
  }
}
