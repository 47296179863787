import { DepartmentCategory } from '../models/common-data';

export const COMMON_PAGE_TITLE = '旺文社 入試正解デジタル';

export const COMMON_ID_EXPIRED_DAYS = 1;
export const COMMON_ID_SEARCH_YEARS = ['2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015'];
export const COMMON_ID_FREE_YEARS = ['2024', '2023'];
export const COMMON_ID_YEAR_LABEL_FOR_UNPAID_USERS = '（プレミアム会員限定）';

export enum COMMON_ID_REGION {
  HOKKAIDO_TOHOKU = '01',
  KANTO = '02',
  KOSHINETSU = '03',
  HOKURIKU = '04',
  TOKAI = '05',
  KINKI = '06',
  CHUGOKU = '07',
  SHIKOKU = '08',
  KYUSYU_OKINAWA = '09'
}

export const COMMON_ID_PREFECTURES = [
  { 'id': '01', 'name': '北海道', 'region': COMMON_ID_REGION.HOKKAIDO_TOHOKU },
  { 'id': '02', 'name': '青森', 'region': COMMON_ID_REGION.HOKKAIDO_TOHOKU },
  { 'id': '03', 'name': '岩手', 'region': COMMON_ID_REGION.HOKKAIDO_TOHOKU },
  { 'id': '04', 'name': '宮城', 'region': COMMON_ID_REGION.HOKKAIDO_TOHOKU },
  { 'id': '05', 'name': '秋田', 'region': COMMON_ID_REGION.HOKKAIDO_TOHOKU },
  { 'id': '06', 'name': '山形', 'region': COMMON_ID_REGION.HOKKAIDO_TOHOKU },
  { 'id': '07', 'name': '福島', 'region': COMMON_ID_REGION.HOKKAIDO_TOHOKU },
  { 'id': '08', 'name': '茨城', 'region': COMMON_ID_REGION.KANTO },
  { 'id': '09', 'name': '栃木', 'region': COMMON_ID_REGION.KANTO },
  { 'id': '10', 'name': '群馬', 'region': COMMON_ID_REGION.KANTO },
  { 'id': '11', 'name': '埼玉', 'region': COMMON_ID_REGION.KANTO },
  { 'id': '12', 'name': '千葉', 'region': COMMON_ID_REGION.KANTO },
  { 'id': '13', 'name': '東京', 'region': COMMON_ID_REGION.KANTO },
  { 'id': '14', 'name': '神奈川', 'region': COMMON_ID_REGION.KANTO },
  { 'id': '15', 'name': '新潟', 'region': COMMON_ID_REGION.KOSHINETSU },
  { 'id': '16', 'name': '富山', 'region': COMMON_ID_REGION.HOKURIKU },
  { 'id': '17', 'name': '石川', 'region': COMMON_ID_REGION.HOKURIKU },
  { 'id': '18', 'name': '福井', 'region': COMMON_ID_REGION.HOKURIKU },
  { 'id': '19', 'name': '山梨', 'region': COMMON_ID_REGION.KOSHINETSU },
  { 'id': '20', 'name': '長野', 'region': COMMON_ID_REGION.KOSHINETSU },
  { 'id': '21', 'name': '岐阜', 'region': COMMON_ID_REGION.TOKAI },
  { 'id': '22', 'name': '静岡', 'region': COMMON_ID_REGION.TOKAI },
  { 'id': '23', 'name': '愛知', 'region': COMMON_ID_REGION.TOKAI },
  { 'id': '24', 'name': '三重', 'region': COMMON_ID_REGION.TOKAI },
  { 'id': '25', 'name': '滋賀', 'region': COMMON_ID_REGION.KINKI },
  { 'id': '26', 'name': '京都', 'region': COMMON_ID_REGION.KINKI },
  { 'id': '27', 'name': '大阪', 'region': COMMON_ID_REGION.KINKI },
  { 'id': '28', 'name': '兵庫', 'region': COMMON_ID_REGION.KINKI },
  { 'id': '29', 'name': '奈良', 'region': COMMON_ID_REGION.KINKI },
  { 'id': '30', 'name': '和歌山', 'region': COMMON_ID_REGION.KINKI },
  { 'id': '31', 'name': '鳥取', 'region': COMMON_ID_REGION.CHUGOKU },
  { 'id': '32', 'name': '島根', 'region': COMMON_ID_REGION.CHUGOKU },
  { 'id': '33', 'name': '岡山', 'region': COMMON_ID_REGION.CHUGOKU },
  { 'id': '34', 'name': '広島', 'region': COMMON_ID_REGION.CHUGOKU },
  { 'id': '35', 'name': '山口', 'region': COMMON_ID_REGION.CHUGOKU },
  { 'id': '36', 'name': '徳島', 'region': COMMON_ID_REGION.SHIKOKU },
  { 'id': '37', 'name': '香川', 'region': COMMON_ID_REGION.SHIKOKU },
  { 'id': '38', 'name': '愛媛', 'region': COMMON_ID_REGION.SHIKOKU },
  { 'id': '39', 'name': '高知', 'region': COMMON_ID_REGION.SHIKOKU },
  { 'id': '40', 'name': '福岡', 'region': COMMON_ID_REGION.KYUSYU_OKINAWA },
  { 'id': '41', 'name': '佐賀', 'region': COMMON_ID_REGION.KYUSYU_OKINAWA },
  { 'id': '42', 'name': '長崎', 'region': COMMON_ID_REGION.KYUSYU_OKINAWA },
  { 'id': '43', 'name': '熊本', 'region': COMMON_ID_REGION.KYUSYU_OKINAWA },
  { 'id': '44', 'name': '大分', 'region': COMMON_ID_REGION.KYUSYU_OKINAWA },
  { 'id': '45', 'name': '宮崎', 'region': COMMON_ID_REGION.KYUSYU_OKINAWA },
  { 'id': '46', 'name': '鹿児島', 'region': COMMON_ID_REGION.KYUSYU_OKINAWA },
  { 'id': '47', 'name': '沖縄', 'region': COMMON_ID_REGION.KYUSYU_OKINAWA }
];

export const COMMON_TEST_PREFECTURE_NAME = '共テ';
export const COMMON_TEST_UNIVERSITY_ID = 'D9999';

export const DEFAULT_DEPARTMENT_CATEGORY: DepartmentCategory = { id: '0', name: '指定なし' };
export const SELECT_ANSWERED_DIALOG_HEIGHT = '600px';
export const SELECT_ANSWERED_DIALOG_WITDH = '500px';
export const COMPLETE_ANSWERED_DIALOG_WIDTH = '400px';
export const DESCRIPTION_PREMIUM_DIALOG_HEIGHT = '490px';
export const DESCRIPTION_PREMIUM_DIALOG_WITDH = '600px';
export const PAPER_INFORMATION_DIALOG_HEIGHT = '400px';
export const PAPER_INFORMATION_DIALOG_WIDTH = '500px';
export const CHANGE_ACTIVE_ACCOUNT_DIALOG_WIDTH = '500px';
export const DIALOG_ZERO_PADDING_PANEL_CLASS = 'dialog-zero-padding-panel-class';
export const UPDATE_ACCOUNT_DIALOG_WIDTH = '600px';
export const UPDATE_ACCOUNT_DIALOG_HEIGHT = '650px';

export const COMMON_ID_ACCOUNTS_PAGE_SIZE_OPTIONS = [20, 50, 100];

export const COMMON_ID_DEFAULT_USER_PLACEHOLDER = 'ユーザーIDで絞り込む';
export const COMMON_ID_DEFAULT_EMAIL_PLACEHOLDER = 'メールアドレスで絞り込む';
export const COMMON_ID_ACCOUNT_NO_DATA_INDICATIOR = '-';

export enum PurchaseStatus {
  PURCHASED = 1,
  PENDING = 2
}

export const PURCHASE_STATUS_NAMES = [
  { id: PurchaseStatus.PURCHASED, name: '購入済み' },
  { id: PurchaseStatus.PENDING, name: '支払い待ち' }
];

export enum PurchaseMethod {
  CARD = 1,
  CONVENIENT = 2
}

export const PURCHASE_METHOD_NAMES = [
  { id: PurchaseMethod.CARD, name: 'クレジットカード' },
  { id: PurchaseMethod.CONVENIENT, name: 'コンビニ決済' }
];

export enum PurchasePlan {
  BIANNUAL = 1,
  ANNUAL = 2,
  SHORT_TERM_3MONTHS = 3,
  ADMIN_FOR_CHECK = 9999
}

export const PURCHASE_PLAN_NAMES = [
  { id: PurchasePlan.BIANNUAL, name: '6ヶ月プラン', prefix: '入試正解デジタルプレミアム' },
  { id: PurchasePlan.ANNUAL, name: '12ヶ月プラン', prefix: '入試正解デジタルプレミアム' },
  { id: PurchasePlan.SHORT_TERM_3MONTHS, name: '短期集中3ヶ月プラン', prefix: '入試正解デジタルプレミアム' },
  { id: PurchasePlan.ADMIN_FOR_CHECK, name: '確認用プラン', prefix: '' }
];

export enum ACCOUNT_STATUS_ACTIVE {
  ACTIVE = 1,
  NOT_ACTIVE = 2
}
// アカウント一覧の絞り込み用
export const ACCOUNT_FILTER_ACCOUNT_STATUS = [
  { id: 0, name: 'すべて' },
  { id: ACCOUNT_STATUS_ACTIVE.ACTIVE, name: '有効', isActive: true },
  { id: ACCOUNT_STATUS_ACTIVE.NOT_ACTIVE, name: '無効', isActive: false }
];
export const ACCOUNT_FILTER_PURCHASE_STATUS = [{ id: 0, name: 'すべて' }, ...PURCHASE_STATUS_NAMES];
export const ACCOUNT_FILTER_PURCHASE_PLAN = [{ id: 0, name: 'すべて' }, ...PURCHASE_PLAN_NAMES];

export const CONTACT_GOOGLE_FORM_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSfadbm5bakruDMSq-NyzldGKDEBMnLolTAVQkJkwVtu6q_ndw/viewform?usp=pp_url&entry.905373565=<user-id>&entry.1021285810=<user-agent>';
export const REQUEST_GOOGLE_FORM_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSet7GQ7Ap-0MDk56cY57T74lIUAINEwv91aIwlBtMuIkYGNtw/viewform?usp=pp_url&entry.423010608=<user-id>';

export const FROM_ANSWERED_PROBLEMS = 'answered-problems';

export const EXCLUDE_PLAYLIST_TAGS = ['英語', '数学', '国語', '物理', '化学', '生物', '日本史', '世界史', '地理', '政治・経済'];

export const COOKIE_NAME_SSC_ID = 'ssc_id';

export enum CommonIdPlaylistSortType {
  PUBLISHED_DATE = 'PUBLISHED_DATE',
  PLAYLIST_ID_ASC = 'PLAYLIST_ID_ASC',
  PLAYLIST_ID_DESC = 'PLAYLIST_ID_DESC',
  DURATION = 'DURATION'
}

export enum CommonIdPlaylistSortTypeDisplayName {
  PUBLISHED_DATE = '更新日時（新しい順）',
  PLAYLIST_ID_ASC = 'シリーズ順（ID昇順）',
  PLAYLIST_ID_DESC = 'シリーズ順（ID降順）',
  DURATION = '目安時間（少ない順）'
}
