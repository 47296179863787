<div @enter *ngIf="(loaded$ | async) === true">
  <app-content-row>
    <app-wrapper size="lg" isFitWithSmallScreen="true">
      <!-- ▼ TODO: 戻るボタン -->
      <app-content-row>
        <div class="flex items-center gap-x-2">
          <app-button-location-back (backClick)="goBack()">戻る</app-button-location-back>
        </div>
      </app-content-row>
      <!-- △ TODO: 戻るボタン -->
      <app-card-content>
        <app-content-row>
          <app-wrapper size="fluid">
            <div class="flex flex-col md:flex-row items-start w-full gap-4 md:gap-6">
              <div class="md:mb-0 grow">
                <div class="py-2">
                  <div role="heading" class="flex flex-col gap-1 mb-6">
                    <div class="flex playlist-detail-title">
                      {{ (playlist$ | async).seriesName }}
                    </div>
                    <div class="flex playlist-detail-title-sub">
                      {{ (playlist$ | async).name }}
                    </div>
                  </div>
                  <div class="flex flex-wrap items-center gap-4 mb-4">
                    <div class="playlist-detail-subject-label">
                      <app-label-subject [subjectLabelStyle]="subjectLabelStyle" [subjectLabelName]="subjectLabelName"></app-label-subject>
                    </div>
                    <p class="playlist-detail-num">
                      <span
                        >問題数：<b>{{ (playlist$ | async).problemCount }}問</b></span
                      >
                      <span
                        >目安時間：<b>{{ (playlist$ | async).duration }}</b></span
                      >
                    </p>
                  </div>
                  <p class="leading-6 playlist-detail-description" [innerHTML]="(playlist$ | async).description"></p>
                  <div class="mt-4 flex flex-wrap gap-2">
                    <app-playlist-tag-button *ngFor="let tag of (playlist$ | async).tags" (click)="clickTag(tag)">{{
                      tag
                    }}</app-playlist-tag-button>
                  </div>
                </div>
              </div>
              <div class="playlist-detail-image">
                <img src="/assets/images/playlists-images/{{ playlistId }}/{{ playlistId }}@2x.jpg" />
              </div>
            </div>
          </app-wrapper>
        </app-content-row>
        <app-wrapper size="fluid">
          <hr />
          <app-content-row>
            <div class="md:flex items-center">
              <div class="md:w-3/4 text-right md:text-left mb-4 md:mb-0">更新日：{{ (playlist$ | async).contentUpdatedDate }}</div>
              <div class="grid-cols-2 grid gap-3 flex playlist-detail-button">
                <app-button-toggle-playlist-bookmark
                  #savePlaylistBtn
                  *ngIf="!isPlaylistBookmarked"
                  [isPlaylistBookmarked]="false"
                  [canSpinner]="true"
                  (bookmarkClick)="clickPlaylistBookmarkButton()"
                  class="button-toggle-paper-bookmark font-normal"
                >
                </app-button-toggle-playlist-bookmark>
                <app-button-toggle-playlist-bookmark
                  #savePlaylistBtn
                  *ngIf="isPlaylistBookmarked"
                  [isPlaylistBookmarked]="true"
                  [canSpinner]="true"
                  (bookmarkClick)="clickPlaylistBookmarkButton()"
                  class="button-toggle-paper-bookmark font-normal"
                >
                </app-button-toggle-playlist-bookmark>

                <!-- 見た目はdisabledだが、クリックできるボタン -->
                <button
                  mat-flat-button
                  class="font-normal bg-gray-50"
                  [class.mat-button-disabled]="!isPremiumUser"
                  (click)="selectAnswered()"
                  disableRipple="!isPremiumUser"
                >
                  <mat-icon>check_circle</mat-icon>解答済み
                </button>
              </div>
            </div>
          </app-content-row>
        </app-wrapper>
      </app-card-content>
    </app-wrapper>
  </app-content-row>

  <app-wrapper size="lg" isFitWithSmallScreen="true">
    <div *ngIf="(loaded$ | async) === true">
      <ng-container *ngFor="let theme of englishPlaylistThemes$ | async">
        <ng-container *ngTemplateOutlet="problems; context: { $implicit: theme }"></ng-container>
      </ng-container>
      <ng-container *ngFor="let theme of mathPlaylistThemes$ | async">
        <ng-container *ngTemplateOutlet="problems; context: { $implicit: theme }"></ng-container>
      </ng-container>
      <ng-container *ngFor="let theme of nationalLanguagePlaylistThemes$ | async">
        <ng-container *ngTemplateOutlet="problems; context: { $implicit: theme }"></ng-container>
      </ng-container>
      <ng-container *ngFor="let theme of physicsPlaylistThemes$ | async">
        <ng-container *ngTemplateOutlet="problems; context: { $implicit: theme }"></ng-container>
      </ng-container>
      <ng-container *ngFor="let theme of chemistryPlaylistThemes$ | async">
        <ng-container *ngTemplateOutlet="problems; context: { $implicit: theme }"></ng-container>
      </ng-container>
      <ng-container *ngFor="let theme of biologyPlaylistThemes$ | async">
        <ng-container *ngTemplateOutlet="problems; context: { $implicit: theme }"></ng-container>
      </ng-container>
      <ng-container *ngFor="let theme of japaneseHistoryPlaylistThemes$ | async">
        <ng-container *ngTemplateOutlet="problems; context: { $implicit: theme }"></ng-container>
      </ng-container>
      <ng-container *ngFor="let theme of worldHistoryPlaylistThemes$ | async">
        <ng-container *ngTemplateOutlet="problems; context: { $implicit: theme }"></ng-container>
      </ng-container>
      <ng-container *ngFor="let theme of geographyPlaylistThemes$ | async">
        <ng-container *ngTemplateOutlet="problems; context: { $implicit: theme }"></ng-container>
      </ng-container>
      <ng-container *ngFor="let theme of politicalEconomyPlaylistThemes$ | async">
        <ng-container *ngTemplateOutlet="problems; context: { $implicit: theme }"></ng-container>
      </ng-container>
    </div>

    <app-content-row *ngIf="(playlist$ | async).searchUrls !== null">
      <div class="border border-border-line"></div>
    </app-content-row>

    <app-content-row *ngIf="(playlist$ | async).searchUrls !== null">
      <app-card-content [showSeparator]="true">
        <app-card-content-head>
          <mat-icon class="card-content-head-icon">manage_search</mat-icon>
          おすすめの分野検索
          <div class="card-content-head-util" *ngIf="!isPremiumUser">
            <app-button-subject-name
              subject="プレミアム会員限定"
              isPremium="true"
              isArrow="true"
              class="text-xs ml-4 align-middle md:align-bottom"
            ></app-button-subject-name>
          </div>
        </app-card-content-head>
        <app-wrapper size="fluid">
          <app-content-row>
            <div>
              この問題セットをすべて解答した方向け。さらにレベルアップしたい方におすすめの分野検索結果を紹介します。
            </div>
            <div class="mt-4 md:mt-6 grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
              <div *ngFor="let searchUrl of (playlist$ | async).searchUrls">
                <button
                  mat-ripple
                  class="search-by-categories-recommendation-button"
                  (click)="goToSearchByCategories(searchUrl.queryParams)"
                  [disabled]="!isPremiumUser"
                >
                  <mat-icon class="list-mark">search</mat-icon>
                  <span>{{ searchUrl.title }}</span>
                  <mat-icon class="arrow">keyboard_arrow_right</mat-icon>
                </button>
              </div>
            </div>
          </app-content-row>
        </app-wrapper>
      </app-card-content>
    </app-content-row>
  </app-wrapper>
</div>

<ng-template #problems let-theme>
  <div class="playlist-items">
    <app-content-row>
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
            <mat-panel-title>
              <div class="panel-header">
                <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }}問)</div>
                <span class="spacer"></span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <button
            class="flex playlist-items-list items-center"
            (click)="goPlaylistProblemDetail(playlistId, theme.id, problem.id)"
            *ngFor="let problem of theme.problems"
          >
            <!-- ▼ サムネイル SP時のみ縮尺を少し大きく (ratio)-->
            <app-thumb-preview-problem
              class="w-fit self-baseline md:hidden"
              [problemId]="problem.id"
              ratio="0.3"
              [isPremiumMemberOnly]="!isPremiumUser && !isFreePlaylistId"
              [hasExternalData]="problem.hasExternalData"
            ></app-thumb-preview-problem>
            <app-thumb-preview-problem
              class="w-fit self-baseline hidden md:block"
              [problemId]="problem.id"
              ratio="0.5"
              [isPremiumMemberOnly]="!isPremiumUser && !isFreePlaylistId"
              [hasExternalData]="problem.hasExternalData"
            ></app-thumb-preview-problem>
            <!-- △ サムネイル -->
            <div class="playlist-detail-content ml-3 md:ml-8">
              <div class="playlist-detail-content-labels">
                <p class="playlist-detail-content-num">{{ theme.order }}-{{ problem.orderInTheme }}</p>
                <app-label-level-with-stars [level]="problem.levelNumber" levelLabelName="{{ problem.level }}"></app-label-level-with-stars>
                <app-label-answered *ngIf="problem.answered"></app-label-answered>
              </div>
              <p class="playlist-detail-content-issue">
                <ng-container *ngIf="problem.isOriginalProblem">
                  {{ problem.originalOverview }}
                  <ng-container *ngIf="problem.showProblemNumberFlg">
                    {{ problem.problemNumber }}
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!problem.isOriginalProblem">
                  {{ problem.university }} {{ problem.year }}年度 {{ problem.problemNumber }}
                </ng-container>
              </p>
              <p class="playlist-detail-content-subject" [innerHTML]="problem.simpleCategories.split('\n').join(', ')"></p>
              <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
          </button>
        </mat-expansion-panel>
      </mat-accordion>
    </app-content-row>
  </div>
</ng-template>

<div class="progress-48" *ngIf="(loaded$ | async) !== true">
  <mat-spinner [diameter]="48"></mat-spinner>
</div>
