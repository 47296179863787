import { Subject } from '../../models/common-data';
import { SUBJECT_NAMES_FOR_COMMON_TEST } from '../../resources/common-id/subject-names-for-common-test';
import { EXCLUDE_YEARS } from '../../resources/common-id-config';

export class CommonIdSubjectUtil {
  static getSubjectNameForCommonTest(paperId: string, subjects: Subject[]): string {
    // 教科名がリストにある場合はリストより教科名を取得
    const subjectName = SUBJECT_NAMES_FOR_COMMON_TEST[paperId];
    if (subjectName) return subjectName;

    // 教科名がリストにない場合は通常の教科名を設定
    const subjectId = paperId.substring(6, 8);
    const subject = subjects.find(obj => obj.id === subjectId);
    if (subject) return subject.name;

    return subjectId;
  }

  static isExcludeYear(subjectId: string, year: string): boolean {
    const targetYear = year.length === 2 ? parseInt(`20${year}`, 10) : parseInt(year, 10);
    const excludeYearsBySubjectId = EXCLUDE_YEARS.filter(excludeYear => excludeYear.subjectId === subjectId);
    if (excludeYearsBySubjectId.length > 0 && targetYear <= excludeYearsBySubjectId[0].year) {
      return true;
    }
    return false;
  }
}
