import { Injectable } from '@angular/core';
import { PlaylistSearchCondition } from 'src/app/models/playlist-search-condition';
import { PlaylistSearchSortType } from 'src/app/models/playlist-search-query-params';
import { PlaylistSortType } from 'src/app/resources/config';

@Injectable({
  providedIn: 'root'
})
export class PlaylistsRouterService {
  getConditionFromQueryParams(url: string): PlaylistSearchCondition {
    const currentQueryParamsString = url.split('?')[1];
    return this.getSearchCondition(currentQueryParamsString);
  }

  private getSearchCondition(queryParamsString: string): PlaylistSearchCondition {
    const queryParams = new URLSearchParams(queryParamsString);
    const subjectId = queryParams.get('subjectId');
    const sortType = queryParams.get('sortType');
    const tag = queryParams.get('tag');

    const searchCondition = this.setSearchConditionFromQueryParams(subjectId, sortType, tag);
    return searchCondition;
  }

  private setSearchConditionFromQueryParams(subjectId: string, sortType: string, tag: string): PlaylistSearchCondition {
    let condition: PlaylistSearchCondition = {};
    if (subjectId) condition = { subjectId };

    if (sortType === PlaylistSearchSortType.PUBLISHED_DATE) {
      condition = { ...condition, sortType: PlaylistSortType.PUBLISHED_DATE };
    } else if (sortType === PlaylistSearchSortType.PLAYLIST_ID_ASC) {
      condition = { ...condition, sortType: PlaylistSortType.PLAYLIST_ID_ASC };
    } else if (sortType === PlaylistSearchSortType.PLAYLIST_ID_DESC) {
      condition = { ...condition, sortType: PlaylistSortType.PLAYLIST_ID_DESC };
    }

    if (tag) condition = { ...condition, tag };

    return condition;
  }
}
