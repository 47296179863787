<section class="tracking-normal">
  <h2 class="text-center mb-8 md:mb-12 text-2xl md:text-3xl text-black font-semibold tracking-widest">ご購入の流れ</h2>
  <app-overflow-wrapper-x>
    <div class="flex gap-8">
      <section class="w-full shrink text-sm min-w-[280px]">
        <img
          class="rounded-xl"
          src="/assets/images/lp/student/purchase-process-step-1@2x.jpg"
          alt="Step1: プランを選択"
          width="562"
          height="366"
        />
        <div class="mt-4 px-2">
          <h3 class="font-bold text-base mb-2 tracking-wider">
            <span class="text-lg text-yellow-gold-darker">01.</span>
            プランを選択
          </h3>
          <p>
            ログイン後ご希望のプランを選択します。 （外部の<strong>Stripeサイト</strong>に移動します。）
          </p>
          <p class="text-xs">※ログインには「旺文社まなびID」へのご登録（無料）が必要です。</p>
        </div>
      </section>
      <section class="w-full shrink text-sm min-w-[280px]">
        <img
          class="rounded-xl"
          src="/assets/images/lp/student/purchase-process-step-2@2x.jpg"
          alt="Step1: プランを選択"
          width="562"
          height="366"
        />
        <div class="mt-4 px-2">
          <h3 class="font-bold text-base mb-2 tracking-wider">
            <span class="text-lg text-yellow-gold-darker">02.</span>
            購入情報入力
            <small class="text-xs font-normal">（保護者様ご入力箇所）</small>
          </h3>
          <p>
            <strong class="font-bold">■ クレジットカード支払い</strong><br />
            <span class="text-sm">メールアドレス（領収書送信先）とカード番号を入力してください。</span>
          </p>
          <p>
            <strong class="font-bold">■ コンビニ支払い</strong><br />
            <span class="text-sm">支払いコードと確認番号を受け取ります。 その後、コンビニエンスストアでお支払いください。</span>
          </p>
        </div>
      </section>
      <section class="w-full shrink text-sm min-w-[280px]">
        <img
          class="rounded-xl"
          src="/assets/images/lp/student/purchase-process-step-3@2x.jpg"
          alt="Step1: プランを選択"
          width="562"
          height="366"
        />
        <div class="mt-4 px-2">
          <h3 class="font-bold text-base mb-2 tracking-wider">
            <span class="text-lg text-yellow-gold-darker">03.</span>
            ご利用を開始
          </h3>
          <p>購入完了後から<strong class="font-bold">「入試正解デジタルプレミアム」</strong>をお使いいただけます。</p>
        </div>
      </section>
    </div>
  </app-overflow-wrapper-x>
</section>

<div class="mt-10 border border-border-line bg-gray-50/50 rounded-xl p-4 md:p-6">
  <h4 class="text-center text-base font-bold mb-4">お支払いについて</h4>
  <ul class="space-y-1 leading-relaxed text-xs tracking-normal">
    <li class="relative pl-4">
      <span class="absolute left-0">・</span>
      お支払い方法は「クレジットカード」または「コンビニ」決済に対応しています。
    </li>
    <li class="relative pl-4">
      <span class="absolute left-0">・</span>
      ご利用できるクレジットカードの種類は、Visa / Mastercard / JCB / American Express です。
    </li>
    <li class="relative pl-4">
      <span class="absolute left-0">・</span>
      ご利用できるコンビニエンスストアは、ファミリーマート / ローソン / ミニストップ / セイコーマートです。
    </li>
    <li class="relative pl-4">
      <span class="absolute left-0">・</span>
      未成年者によるクレジットカードのご購入の場合は、保護者が保護者名義のカードにてお申し込みください。
    </li>
    <li class="relative pl-4">
      <span class="absolute left-0">・</span>
      当サイトではストライプジャパン株式会社 が提供する決済システム「Stripe」を利用しています。
    </li>
    <li class="relative pl-4 leading-relaxed">
      <span class="absolute left-0">・</span>
      ご入力いただいたカード情報等は当サイトのサーバーを経由せず、SSL暗号化通信で Stripe
      の決済システムに直接登録され、ストライプジャパン株式会社にて厳重に管理され安全性を確保しております。
    </li>
  </ul>
</div>
