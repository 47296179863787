export interface PlaylistSearchQueryParams {
  subjectId?: string;
  sortType?: PlaylistSearchSortType;
  tag?: string;
}

export enum PlaylistSearchSortType {
  PUBLISHED_DATE = 'publishedDate',
  PLAYLIST_ID_ASC = 'playlistId-asc',
  PLAYLIST_ID_DESC = 'playlistId-desc'
}
