<div class="grid grid-cols-1 grid-cols-2 md:grid-cols-3 gap-4 md:gap-6">
  <app-playlist-card-item
    *ngFor="let playlist of playlists$ | async"
    [title]="playlist.seriesName"
    [subTitle]="playlist.name"
    [subjectId]="playlist.subjectId"
    [disabledAsNotPremium]="isPremiumUser === false && playlist.freeFlg !== true"
    [playlistId]="playlist.playlistId"
    [tags]="playlist.tags"
    [problemCount]="playlist.problemCount"
    [duration]="playlist.duration"
    [isBtoC]="true"
    (goPlaylistDetail)="goPlaylistDetail(playlist.playlistId)"
  ></app-playlist-card-item>
</div>
