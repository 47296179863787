import { PlaylistTheme, RawPlaylist, Playlist } from '../models/playlist';
import { AppError } from '../errors/app-error';
import { GeneralError } from '../errors/general-error';

export class PlaylistMapper {
  static mapThemesFromJsonString(str: string): PlaylistTheme[] {
    try {
      const themes: PlaylistTheme[] = JSON.parse(str);
      return themes;
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`playlist.themes の map に失敗しました: ${e}`);
    }
  }

  static mapPlaylistFromRaw(raw: RawPlaylist): Playlist {
    try {
      const tags: string[] = raw.tags !== null ? JSON.parse(raw.tags) : null;
      const themes = PlaylistMapper.mapThemesFromJsonString(raw.themes);
      const playlist: Playlist = {
        playlistId: raw.playlistId,
        subjectId: raw.subjectId,
        seriesName: raw.seriesName,
        name: raw.name,
        description: raw.description,
        problemCount: raw.problemCount,
        publishedDate: raw.publishedDate,
        contentUpdatedDate: raw.contentUpdatedDate,
        yearRange: raw.yearRange,
        creator: raw.creator,
        pending: raw.pending,
        duration: raw.duration,
        priorityFlg: raw.priorityFlg,
        tags,
        enablePdfDownload: raw.enablePdfDownload,
        themes
      };
      return playlist;
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`playlist の map に失敗しました: ${e}`);
    }
  }
}
