import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, of, combineLatest, Subscription } from 'rxjs';
import { filter, shareReplay, map, startWith, take } from 'rxjs/operators';

// Redux
import { navigate, setTitle } from 'src/app/actions/core.actions';
import { setBrowserTitle } from 'src/app/actions/core.actions';
import { findPlaylists } from 'src/app/actions/playlist.actions';
import { getCurrentDateTime } from 'src/app/actions/current-date-time.actions';
import { RootState } from 'src/app/reducers';
import { getPlaylists } from 'src/app/selectors/playlist.selectors';
import * as StaticDataSelectors from 'src/app/selectors/static-data.selectors';
import { getCurrentDateTime as getCurrentDateTimeSelector } from 'src/app/selectors/current-date-time.selectors';
import { getSignedInUser } from 'src/app/selectors/auth.selectors';

// models
import { Subject } from 'src/app/models/common-data';
import { CurrentDateTime } from 'src/app/models/current-date-time';
import { Playlist } from 'src/app/models/playlist';
import { PlaylistSearchCondition } from 'src/app/models/playlist-search-condition';
import { User } from 'src/app/models/user';

// utils
import { DataUtil } from 'src/app/utils/data-util';
import { Dates } from 'src/app/utils/dates';

// components & services
import { PlaylistsRouterService } from 'src/app/services/playlists-router-service';

// mapper
import { PlaylistSearchQueryParamsMapper } from 'src/app/mappers/playlist-search-query-params-mapper';

// config
import { EXCLUDE_PLAYLIST_TAGS } from 'src/app/resources/common-id-config';
import { PLAYLISTS_SUBJECT_ALL_ID, PlaylistSortType } from 'src/app/resources/config';
import { enter } from 'src/app/resources/animations';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';

@Component({
  selector: 'app-common-id-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.scss'],
  animations: [enter]
})
export class PlaylistsComponent implements OnInit, OnDestroy, AfterViewInit {
  private LOG_SOURCE = this.constructor.name;
  private title = 'おすすめ問題セット';
  private routerSubscription: Subscription;

  SUBJECT_ALL_ID = PLAYLISTS_SUBJECT_ALL_ID;

  signedInUser$: Observable<User>;
  playlists$: Observable<Playlist[]>;
  subjects$: Observable<Subject[]>;
  initializedSubjectId$: Observable<string>;
  loaded$: Observable<boolean>;
  currentDateTime: CurrentDateTime;
  currentDateTimeString: string;
  currentDateTime$: Observable<CurrentDateTime>;

  playlistsTags$: Observable<string[]>;

  currentUrl: string;
  selectedSearchCondition: PlaylistSearchCondition;

  constructor(
    private store: Store<RootState>,
    private router: Router,
    private location: Location,
    private playlistsRouterService: PlaylistsRouterService
  ) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));

    this.loaded$ = of(false);
    this.setUpUser();
    this.setUpSubjects();

    // URL全体の変更を監視 - 変更があるたびに、クエリパラメタから検索条件を取得し、その条件で検索結果を表示
    combineLatest([this.subjects$, this.initializedSubjectId$])
      .pipe(take(1))
      .subscribe(([subjects, initializedSubjectId]) => {
        this.routerSubscription = this.router.events.subscribe(() => {
          if (this.currentUrl !== this.router.url) {
            this.currentUrl = this.router.url;
            const condition = this.playlistsRouterService.getConditionFromQueryParams(this.currentUrl);
            // 教科IDのチェック - 表示不可の教科IDが指定された場合は、おすすめ問題のトップページに戻る
            if (condition.subjectId !== '99' && !subjects.some(subject => subject.id === condition.subjectId)) {
              this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlaylists() }));
            }
            this.selectedSearchCondition = this.setUpSelectedSearchCondition(condition, initializedSubjectId);
            this.setUpPlaylists(this.selectedSearchCondition);
          }
        });
      });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  goBack() {
    this.location.back();
  }

  setUpPlaylists(condition: PlaylistSearchCondition) {
    this.store.dispatch(findPlaylists());

    this.store.dispatch(getCurrentDateTime());
    const currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    const playlists$ = this.store.select(getPlaylists).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    combineLatest([currentDateTime$, this.signedInUser$]).subscribe(([currentDateTime, signedInUser]) => {
      this.currentDateTime = currentDateTime;
      this.currentDateTimeString = Dates.stdDateStringFromIso(this.currentDateTime?.dateTime);

      this.playlists$ = playlists$.pipe(
        map(playlists => {
          const filteredPlaylists = this.filterPlaylists(playlists, condition.subjectId, signedInUser.visibleSubjectIds);
          const filteredAndSortedPlaylists = this.sortPlaylists(filteredPlaylists, this.selectedSearchCondition.sortType);

          // タグ一覧に表示するタグを取得
          if (condition.subjectId === this.SUBJECT_ALL_ID) {
            // 全科目の場合は上位20件に絞り込む
            this.playlistsTags$ = of(this.getTags(filteredAndSortedPlaylists).slice(0, 20));
          } else {
            this.playlistsTags$ = of(this.getTags(filteredAndSortedPlaylists));
          }

          if (condition.tag) {
            // 指定されたタグで検索結果をさらに絞り込み
            const result = filteredAndSortedPlaylists.filter(playlist =>
              condition.tag === '' ? true : playlist.tags.find(playlistTag => playlistTag === condition.tag) !== undefined
            );
            return result;
          } else {
            return filteredAndSortedPlaylists;
          }
        })
      );

      this.loaded$ = this.playlists$.pipe(
        map(it => it != null),
        startWith(false)
      );
    });
  }

  clickTag(tag: string) {
    this.selectedSearchCondition.tag = tag;
    const queryParams = PlaylistSearchQueryParamsMapper.encodePlaylistSearchCondition(this.selectedSearchCondition);
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlaylists(), extras: { queryParams } }));
  }

  private setUpUser() {
    this.signedInUser$ = this.store.select(getSignedInUser).pipe(filter<User>(it => it != null));
  }

  private setUpSubjects() {
    this.subjects$ = this.store.select(StaticDataSelectors.getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    combineLatest([this.subjects$, this.signedInUser$])
      .pipe(take(1))
      .subscribe(([subjects, signedInUser]) => {
        if (subjects.length > 0) {
          const result = [];
          subjects.forEach(subject => {
            if (signedInUser.visibleSubjectIds.includes(subject.id)) {
              result.push(subject);
            }
          });

          this.subjects$ = of(result);
          this.initializedSubjectId$ = result.length === 1 ? of(result[0].id) : of(this.SUBJECT_ALL_ID);
        }
      });
  }

  private setUpSelectedSearchCondition(condition: PlaylistSearchCondition, initializedSubjectId: string): PlaylistSearchCondition {
    if (condition === undefined || Object.keys(condition).length === 0) {
      const initializedCondition = this.getInitializedSearchCondition(initializedSubjectId);
      return initializedCondition;
    }

    return condition;
  }

  private getInitializedSearchCondition(initializedSubjectId): PlaylistSearchCondition {
    const condition: PlaylistSearchCondition = {
      subjectId: initializedSubjectId,
      sortType: PlaylistSortType.PUBLISHED_DATE
    };
    return condition;
  }

  private sortPlaylists(playlists: Playlist[], sortType: PlaylistSortType): Playlist[] {
    let sortedPlaylists: Playlist[] = playlists;

    if (sortType === PlaylistSortType.PUBLISHED_DATE) {
      sortedPlaylists = [...playlists].sort((a, b) => {
        // 更新日時の降順のみでソート
        if (a.publishedDate < b.publishedDate) return 1;
        if (a.publishedDate > b.publishedDate) return -1;
        // 更新日時が同じなら、科目順にソート
        if (a.playlistId < b.playlistId) return -1;
        if (a.playlistId > b.playlistId) return 1;
        return 0;
      });
    } else if (sortType === PlaylistSortType.PLAYLIST_ID_ASC) {
      sortedPlaylists = DataUtil.sortObjectArrayBySpecificKey(playlists, 'playlistId');
    } else if (sortType === PlaylistSortType.PLAYLIST_ID_DESC) {
      sortedPlaylists = DataUtil.sortObjectArrayBySpecificKey(playlists, 'playlistId', 'desc');
    }
    return sortedPlaylists;
  }

  private filterPlaylists(playlists: Playlist[], subjectId: string, visibleSubjectIds: string[]): Playlist[] {
    // 教科ごとにデータを絞り込み
    const playlistsFilteredBySubject =
      subjectId === this.SUBJECT_ALL_ID
        ? playlists.filter(playlist => visibleSubjectIds.includes(playlist.subjectId))
        : playlists.filter(playlist => playlist.subjectId === subjectId);
    return playlistsFilteredBySubject;
  }

  private getTags(playlists: Playlist[]): string[] {
    const playlistsAllTags = playlists
      .map(playlist => {
        return playlist.tags;
      })
      .flat();

    // 紐づく問題数の多い順にタグを並び替え
    const sortedTagsByFrequency = this.sortByFrequency(playlistsAllTags);

    // 重複データの削除
    const tagsWithoutDupulicates = sortedTagsByFrequency.filter((item, index) => playlistsAllTags.indexOf(item) === index);

    // 除外データの削除
    const playlistsTags = tagsWithoutDupulicates.filter(tag => !EXCLUDE_PLAYLIST_TAGS.includes(tag));

    return playlistsTags;
  }

  private sortByFrequency(arr: string[]): string[] {
    // 出現回数をカウントするオブジェクトを作成
    const frequency: { [key: string]: number } = {};
    arr.forEach(value => {
      frequency[value] = (frequency[value] || 0) + 1;
    });

    // 出現回数が多い順にソート
    arr.sort((a, b) => {
      // 出現回数が同じ場合は文字コード順
      if (frequency[a] === frequency[b]) {
        return a.localeCompare(b);
      }
      return frequency[b] - frequency[a];
    });

    return arr;
  }
}
