<button class="playlist-card-item" matRipple [attr.data-disabledAsNotPremium]="disabledAsNotPremium" (click)="onCardClick()">
  <div *ngIf="disabledAsNotPremium" class="icon-premium-crown" matTooltip="プレミアム会員向けの問題セットです" matTooltipPosition="above">
    <app-icon-premium class="text-lg"></app-icon-premium>
  </div>
  <div class="playlist-card-item-image" *ngIf="isBtoC">
    <img src="/assets/images/playlists-images/{{ playlistId }}/{{ playlistId }}@2x.jpg" width="1280" height="720" />
  </div>
  <div class="playlist-card-item-image" *ngIf="!isBtoC">
    <img src="/assets/images/playlists-images/{{ playlistId }}/{{ playlistId }}@2x.jpg" width="1280" height="720" />
  </div>
  <div class="playlist-card-item-content">
    <div class="playlist-card-item-subject-label">
      <app-label-subject [subjectLabelStyle]="subjectLabelStyle" [subjectLabelName]="subjectLabelName"></app-label-subject>
    </div>
    <p class="playlist-card-item-title">{{ title }}</p>
    <p class="playlist-card-item-title-sub">{{ subTitle }}</p>
    <div class="playlist-card-item-spacer"></div>
    <div class="playlist-card-item-meta">
      <div>
        問題数：<span>{{ problemCount }}問</span>
      </div>
      <div *ngIf="duration !== ''">
        目安時間：<span>{{ duration }}</span>
      </div>
    </div>
    <div class="playlist-card-item-tag flex md:hidden flex-wrap gap-1 playlist-card-item-tag-sp">
      <app-playlist-tag-label *ngFor="let tag of tags | slice: 0:4">{{ tag }}</app-playlist-tag-label>
      <app-playlist-tag-label *ngIf="tags.length > 4" noHash="true">...</app-playlist-tag-label>
    </div>
    <div class="playlist-card-item-tag hidden md:flex flex-wrap gap-1 playlist-card-item-tag-pc">
      <app-playlist-tag-label *ngFor="let tag of tags">{{ tag }}</app-playlist-tag-label>
    </div>
  </div>
</button>
