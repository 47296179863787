import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-page-refresh',
  templateUrl: './button-page-refresh.component.html',
  styleUrls: ['./button-page-refresh.component.scss']
})
export class ButtonPageRefreshComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
