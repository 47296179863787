import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { RootState } from '../../../reducers';
import * as StaticDataSelectors from '../../../selectors/static-data.selectors';
import { SubjectUtil } from '../../../utils/subject-util';

type PlaylistId = string;

@Component({
  selector: 'app-playlist-card-item',
  templateUrl: './playlist-card-item.component.html',
  styleUrls: ['./playlist-card-item.component.scss']
})
export class PlaylistCardItemComponent implements OnInit {
  constructor(private store: Store<RootState>) {}

  @Input() title: string;
  @Input() subTitle: string;
  @Input() subjectId: string;
  @Input() disabledAsNotPremium: boolean;
  @Input() playlistId: string;
  @Input() tags: string[];
  @Input() problemCount: number;
  @Input() duration: string;
  @Input() isBtoC: boolean;

  @Output() goPlaylistDetail = new EventEmitter<PlaylistId>();

  subjectLabelStyle: string;
  subjectLabelName: string;

  ngOnInit() {
    this.store
      .select(StaticDataSelectors.getSubject)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(subjects => {
        this.subjectLabelStyle = SubjectUtil.getSubjectLabelStyle(this.subjectId);
        this.subjectLabelName = SubjectUtil.getName(subjects, this.subjectId);
      });
  }

  onCardClick() {
    this.goPlaylistDetail.emit();
  }
}
