import { Input, Component, OnInit } from '@angular/core';
import { SubjectId } from '../../../resources/config';
import { environment } from '../../../../environments/environment';
import { ProblemImageUtil } from '../../../utils/problem-image-util';
import { CommonIdSubjectUtil } from '../../../utils/common-id/common-id-subject-util';

@Component({
  selector: 'app-thumb-preview-problem',
  templateUrl: './thumb-preview-problem.component.html',
  styleUrls: ['./thumb-preview-problem.component.scss']
})
export class ThumbPreviewProblemComponent implements OnInit {
  /**
   * isPremiumMemberOnly: プレミアムメンバー限定の問題を隠して表示するかどうか
   */
  @Input() isPremiumMemberOnly: boolean;
  /**
   * ratio: 0〜1の範囲で指定する
   */
  @Input() ratio: number;
  /**
   * 大問ID
   */
  @Input() problemId: string;
  /**
   *
   */
  @Input() hasExternalData: boolean | undefined;
  /**
   * 画像のsrc
   */
  imageSrc: string;
  imageRatio: number;
  /**
   * 画像の水平方向開始位置
   */
  startPosX: 'left' | 'right' = 'left';

  /**
   * 画像サイズの基準にする方向
   */
  baseSizeDir: 'width' | 'height';

  constructor() {}

  ngOnInit() {
    const year = this.problemId.slice(0, 2);
    const subjectId = this.problemId.slice(6, 8);
    if (this.hasExternalData === undefined || CommonIdSubjectUtil.isExcludeYear(subjectId, year)) {
      this.imageSrc = '/assets/images/preview-problem-thumb-expired@2x.png';
      this.imageRatio = 1;
    } else if (this.isPremiumMemberOnly) {
      this.imageSrc = '/assets/images/preview-problem-thumb-premium-only@2x.png';
      this.imageRatio = 1;
    } else {
      // 23年度以降は Retina 対応されているため
      const isRetina = parseInt(year, 10) > 22 ? true : false;
      const paperId = this.problemId.slice(0, 10);
      const daimonId = this.problemId.slice(10, 12);
      const fileName = `${paperId}_q${daimonId}_01${isRetina ? '@2x' : ''}.gif`;
      this.imageSrc = environment.cloudfrontHost + ProblemImageUtil.getPath(fileName, false);
      this.imageRatio = this.ratio;
    }

    // 国語のみ右寄せで表示
    if (this.problemId.slice(6, 8) === SubjectId.NATIONAL_LANGUAGE) {
      this.startPosX = 'right';
      this.baseSizeDir = 'height';
    } else {
      this.startPosX = 'left';
      this.baseSizeDir = 'width';
    }
  }
}
