import { PlaylistSearchCondition } from 'src/app/models/playlist-search-condition';
import { PlaylistSearchQueryParams, PlaylistSearchSortType } from 'src/app/models/playlist-search-query-params';
import { PlaylistSortType } from 'src/app/resources/config';

export class PlaylistSearchQueryParamsMapper {
  static encodePlaylistSearchCondition(condition: PlaylistSearchCondition): PlaylistSearchQueryParams {
    let params: PlaylistSearchQueryParams = {};
    if (condition.subjectId) params = { subjectId: condition.subjectId };

    if (condition.sortType === PlaylistSortType.PUBLISHED_DATE) {
      params = { ...params, sortType: PlaylistSearchSortType.PUBLISHED_DATE };
    } else if (condition.sortType === PlaylistSortType.PLAYLIST_ID_ASC) {
      params = { ...params, sortType: PlaylistSearchSortType.PLAYLIST_ID_ASC };
    } else if (condition.sortType === PlaylistSortType.PLAYLIST_ID_DESC) {
      params = { ...params, sortType: PlaylistSearchSortType.PLAYLIST_ID_DESC };
    }

    if (condition.tag) params = { ...params, tag: condition.tag };

    return params;
  }
}
