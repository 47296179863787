<app-main-layout-spacer>
  <app-sign-out-header></app-sign-out-header>

  <div class="flex flex-col h-full justify-center">
    <app-wrapper size="xs">
      <div [@fadeInOut]="!loading">
        <app-content-row>
          <app-card-content>
            <app-wrapper size="fluid">
              <app-content-row>
                <div>
                  <header class="mb-6">
                    <h1 class="text-center text-lg font-bold">ログイン</h1>
                  </header>
                  <form [formGroup]="signInForm">
                    <mat-form-field appearance="outline">
                      <mat-label>Email</mat-label>
                      <input matInput formControlName="email" />
                      <mat-error *ngIf="signInForm.get('email').hasError('required')">入力してください</mat-error>
                      <mat-error *ngIf="signInForm.get('email').hasError('email')">Email 形式で入力してください</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                      <mat-label>Password</mat-label>
                      <input matInput formControlName="password" [type]="passwordVisible ? 'text' : 'password'" />
                      <mat-error *ngIf="signInForm.get('password').hasError('required')">入力してください</mat-error>
                    </mat-form-field>
                    <div class="mb-4">
                      <mat-checkbox color="primary" [checked]="passwordVisible" (change)="changePasswordVisibility($event)">
                        パスワードを表示する
                      </mat-checkbox>
                    </div>
                    <button class="h-12" mat-flat-button color="primary" (click)="signIn()" type="submit">サインイン</button>
                  </form>
                  <div class="mt-6 -ml-2" [@fadeInOut]="!loading">
                    <div class="mt-2">
                      <app-link-with-arrow (click)="showPasswordResetView('initial')" class="[&_.link-with-arrow-label]:text-md"
                        >初回サインインの方はこちら</app-link-with-arrow
                      >
                    </div>
                    <div class="mt-2">
                      <app-link-with-arrow (click)="showPasswordResetView('reset')" class="[&_.link-with-arrow-label]:text-md"
                        >パスワードをお忘れの方はこちら</app-link-with-arrow
                      >
                    </div>
                    <!--
                    <button mat-button (click)="showPasswordResetView()">
                      初回サインインの方 / パスワードをお忘れの方 はこちら
                    </button>
                    -->
                  </div>
                </div>
              </app-content-row>
            </app-wrapper>
          </app-card-content>
        </app-content-row>
        <app-content-row>
          <button mat-flat-button class="bg-gray-30 h-12 block w-full hover:bg-white" (click)="gotoTop()">
            <mat-icon class="text-primary">mail</mat-icon>
            新規導入のご相談
          </button>
        </app-content-row>
      </div>

      <div class="mt-6 mb-8" [@fadeInOut]="!loading">
        <div class="text-center">
          <app-link-with-arrow
            class="[&_.link-with-arrow-label]:text-md [&_.link-with-arrow]:text-left inline-block"
            (click)="gotoStudentLogin()"
          >
            <span class="text-sm md:text-md"> <span class="font-bold">高校生・大学受験生（個人利用）</span>のログインはこちら</span>
          </app-link-with-arrow>
        </div>
      </div>

      <div class="progress-48" [@fadeInOut]="loading">
        <mat-spinner [diameter]="48"></mat-spinner>
      </div>
    </app-wrapper>
  </div>

  <app-footer></app-footer>
</app-main-layout-spacer>
