<app-dialog-basic dialogTitle="購入時のご注意" dialogIcon="error">
  <div class="-mx-6 -mt-4">
    <img src="./assets/images/info-notes-on-purchase@2x.png" alt="購入時のご注意" />
  </div>

  <p class="mt-4 text-md">
    プランの購入は外部の決済サービスStripeを利用します。Stripeでのお支払い完了後、
    <strong class="font-bold text-primary">
      「購入完了」画面が表示されるまでブラウザを閉じないでください。
    </strong>
    もし途中で閉じてしまった場合、プランが正しく適用されないことがあります。
  </p>
  <app-dialog-basic-footer>
    <button mat-stroked-button (click)="cancel()" [disabled]="">キャンセル</button>
    <button mat-flat-button color="primary" (click)="getStripeCheckoutUrl()" [disabled]="">購入画面へ</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
