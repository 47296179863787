<app-card-content [showSeparator]="true">
  <app-card-content-head>
    <mat-icon class="card-content-head-icon">{{ matIcon }}</mat-icon>
    {{ contentTitle }}
  </app-card-content-head>
  <app-wrapper size="fluid">
    <form [formGroup]="playlistForm">
      <app-content-row>
        <div class="row-sort-area flex items-center gap-4 flex-wrap md:flex-nowrap">
          <div class="grow md:grow-0">
            <mat-form-field class="mb-0 w-full md:w-48" appearance="outline" floatLabel="always">
              <mat-label>科目</mat-label>
              <div class="nb-0">
                <mat-select class="mb-0" formControlName="subjectId" (selectionChange)="onChangeSubject()">
                  <mat-option *ngFor="let subject of subjectOption" [value]="subject.id">
                    {{ subject.name }}
                  </mat-option>
                </mat-select>
              </div>
            </mat-form-field>
          </div>
          <div class="grow md:grow-0">
            <mat-form-field class="mb-0 w-full md:w-48" appearance="outline" floatLabel="always">
              <mat-label>並べ替え</mat-label>
              <div class="nb-0">
                <mat-select class="mb-0" formControlName="sort" (selectionChange)="onChangeSort()">
                  <mat-option *ngFor="let sort of sortOptions" [value]="sort.type">{{ sort.displayName }}</mat-option>
                </mat-select>
              </div>
            </mat-form-field>
          </div>
        </div>
      </app-content-row>
    </form>
  </app-wrapper>
</app-card-content>
