import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Store } from '@ngrx/store';

// Redux
import { setTitle, navigate } from 'src/app/actions/core.actions';
import { setBrowserTitle } from 'src/app/actions/core.actions';
import { RootState } from 'src/app/reducers';

// models
import { Subject } from 'src/app/models/common-data';
import { PlaylistSearchCondition } from 'src/app/models/playlist-search-condition';

// config
import {
  PLAYLISTS_SUBJECT_ALL_ID,
  PLAYLISTS_SUBJECT_ALL_NAME,
  PlaylistSortType,
  PlaylistSortTypeDisplayName
} from 'src/app/resources/config';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';

// mapper
import { PlaylistSearchQueryParamsMapper } from './../../../mappers/playlist-search-query-params-mapper';

interface SortOption {
  type: PlaylistSortType;
  displayName: PlaylistSortTypeDisplayName;
}

@Component({
  selector: 'app-playlists-search-condition',
  templateUrl: './playlists-search-condition.component.html',
  styleUrls: ['./playlists-search-condition.component.scss']
})
export class PlaylistsSearchConditionComponent implements OnInit, OnChanges {
  private LOG_SOURCE = this.constructor.name;
  private title = 'おすすめ問題検索条件欄';

  matIcon: string;
  contentTitle: string;
  subjectOption: Subject[] = [];
  playlistForm: UntypedFormGroup;

  defaultSortOption: SortOption;
  sortOptions: SortOption[] = [];

  isFreeComesFirstChecked = true;

  @Input() subjects: Subject[];
  @Input() selectedSearchCondition: PlaylistSearchCondition;

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
  }

  ngOnChanges() {
    this.setUpForms();
    this.setUpIconAndTitle();
    this.setUpSubjectOption();
    this.setUpSortOptions();
  }

  onChangeSubject() {
    this.selectedSearchCondition.subjectId = this.playlistForm.get('subjectId').value;
    this.addQueryParam();
  }

  onChangeSort() {
    const sortOptionValue = this.playlistForm.get('sort').value;
    this.selectedSearchCondition.sortType = PlaylistSortType[sortOptionValue];
    this.addQueryParam();
  }

  private setUpForms() {
    this.playlistForm = new UntypedFormGroup({
      subjectId: new UntypedFormControl(),
      sort: new UntypedFormControl()
    });
  }

  private setUpIconAndTitle() {
    if (this.selectedSearchCondition.tag) {
      this.matIcon = 'tag';
      this.contentTitle = this.selectedSearchCondition.tag;
    } else {
      this.matIcon = 'view_module';
      this.contentTitle = 'おすすめ問題セット';
    }
  }

  private setUpSubjectOption() {
    if (this.subjects.length === 1) {
      this.subjectOption = this.subjects;
    } else {
      // 全ての科目の選択肢を追加する
      const allSubjectOption: Subject[] = [{ id: PLAYLISTS_SUBJECT_ALL_ID, name: PLAYLISTS_SUBJECT_ALL_NAME }];
      this.subjectOption = allSubjectOption.concat(this.subjects);
    }

    if (this.selectedSearchCondition && this.selectedSearchCondition.subjectId) {
      this.playlistForm.get('subjectId').setValue(this.selectedSearchCondition.subjectId);
    } else {
      this.playlistForm.get('subjectId').setValue(PLAYLISTS_SUBJECT_ALL_ID);
    }
  }

  private setUpSortOptions() {
    this.defaultSortOption = { type: PlaylistSortType.PUBLISHED_DATE, displayName: PlaylistSortTypeDisplayName.PUBLISHED_DATE };
    this.sortOptions = [
      this.defaultSortOption,
      { type: PlaylistSortType.PLAYLIST_ID_ASC, displayName: PlaylistSortTypeDisplayName.PLAYLIST_ID_ASC },
      { type: PlaylistSortType.PLAYLIST_ID_DESC, displayName: PlaylistSortTypeDisplayName.PLAYLIST_ID_DESC }
    ];

    if (this.selectedSearchCondition && this.selectedSearchCondition.sortType) {
      this.playlistForm.get('sort').setValue(this.selectedSearchCondition.sortType);
    } else {
      this.selectedSearchCondition.sortType = this.defaultSortOption.type;
      this.playlistForm.get('sort').setValue(this.defaultSortOption.type);
    }
  }

  private addQueryParam() {
    let condition: PlaylistSearchCondition = {
      subjectId: this.selectedSearchCondition.subjectId,
      sortType: this.selectedSearchCondition.sortType
    };

    if (this.selectedSearchCondition.tag) condition = { ...condition, tag: this.selectedSearchCondition.tag };

    const queryParams = PlaylistSearchQueryParamsMapper.encodePlaylistSearchCondition(condition);

    this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlaylists(), extras: { queryParams } }));
  }
}
