import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

// Redux
import { setTitle } from 'src/app/actions/core.actions';
import { setBrowserTitle } from 'src/app/actions/core.actions';
import { RootState } from 'src/app/reducers';

@Component({
  selector: 'app-playlists-tag-buttons',
  templateUrl: './playlists-tag-buttons.component.html',
  styleUrls: ['./playlists-tag-buttons.component.scss']
})
export class PlaylistsTagButtonsComponent implements OnInit {
  private LOG_SOURCE = this.constructor.name;
  private title = 'おすすめ問題セットタグ一覧';

  @Input() playlistsTags$: Observable<string[]>;
  @Output() tagClick = new EventEmitter<string>();

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
  }

  clickTag(tag: string) {
    this.tagClick.emit(tag);
  }
}
