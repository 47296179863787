<div class="main-layout-spacer flex flex-col h-screen">
  <!-- app-sign-out-header はこの位置に出力 -->
  <ng-content select="app-sign-out-header"></ng-content>

  <!-- app-sign-in-header はこの位置に出力 -->
  <ng-content select="app-sign-in-header"></ng-content>

  <!-- app-common-id-sign-in-header はこの位置に出力 -->
  <ng-content select="app-common-id-sign-in-header"></ng-content>

  <!-- mainコンテンツは高さを維持し、footerを支える -->
  <main class="grow">
    <ng-content></ng-content>
  </main>

  <!-- app-footer はこの位置に出力 -->
  <ng-content select="app-footer"></ng-content>

  <!-- app-common-id-footer はこの位置に出力 -->
  <ng-content select="app-common-id-footer"></ng-content>

  <!-- app-common-id-bottom-toast-banner はこの位置に出力 -->
  <div data-no-print><ng-content select="app-common-id-bottom-toast-banner"></ng-content></div>

  <!-- app-bottom-toast-banner はこの位置に出力 -->
  <ng-content select="app-bottom-toast-banner"></ng-content>
</div>
