export interface CommonIdFaq {
  id: string;
  categoryId: number;
  title: string;
  body: string;
  lpFlag: boolean;
  planFlag: boolean;
  helpFlag: boolean;
  isExpanded: boolean;
}

export const COMMON_ID_FAQS: CommonIdFaq[] = [
  {
    id: 'faq-1',
    categoryId: 1,
    title: '推奨環境を教えてください。',
    body: `
      PC・タブレット・スマートフォン のいずれの端末からもご利用いただけます。<br>
      当社が推奨しているブラウザは、Google chrome　最新版、Microsoft Edge　最新版、Safari　最新版になります。<br>
      ※上記推奨環境以外では、正常に機能しない、または正常に表示されない場合があります。
    `,
    lpFlag: true,
    planFlag: true,
    helpFlag: true,
    isExpanded: false
  },
  {
    id: 'faq-2',
    categoryId: 1,
    title: '複数端末で利用することはできますか。',
    body: `
      できます。1つのアカウントをスマートフォンやPCなど複数端末で利用することが可能です。
    `,
    lpFlag: true,
    planFlag: true,
    helpFlag: true,
    isExpanded: false
  },
  {
    id: 'faq-3',
    categoryId: 1,
    title: '高校3年生以外でも利用することはできますか。',
    body: `
      はい、どなたでもご利用いただけます。
    `,
    lpFlag: true,
    planFlag: false,
    helpFlag: false,
    isExpanded: false
  },
  {
    id: 'faq-4',
    categoryId: 1,
    title: 'ログインのアカウント、パスワードを忘れました。',
    body: `
    「旺文社まなびID」の<a href="https://id.obunsha.co.jp/help/" target="_blank">「お問い合わせ・よくある質問」</a>ページにある「ログインについて」をご確認ください。
    `,
    lpFlag: true,
    planFlag: false,
    helpFlag: false,
    isExpanded: false
  },
  {
    id: 'faq-5',
    categoryId: 1,
    title: '入試正解デジタルを退会したいです。',
    body: `
      「入試正解デジタル」のみを退会することはできません。<br>
      「旺文社まなびID」の「退会」手続きをする必要がありますが、旺文社まなびIDの連携サービスすべてが使えなくなりますのであらかじめご了承ください。<br>
      なお、プレミアム会員契約中の退会においても、退会が完了するとプレミアムの機能のご利用ができなくなりますのでご注意ください。<br><br>

      「旺文社まなびID」の<a href="https://id.obunsha.co.jp/help/" target="_blank">「お問い合わせ・よくある質問」</a>ページにある「退会・利用停止について」をご確認ください。
    `,
    lpFlag: false,
    planFlag: false,
    helpFlag: true,
    isExpanded: false
  },
  {
    id: 'faq-6',
    categoryId: 1,
    title: '入試正解デジタルを、塾や学校の授業で利用できますか。',
    body: `
      できません。ユーザーが個人として使用する範囲に限り、本サービスを利用できるものとします。詳しくは利用規約をご覧ください。なお、塾や学校の授業でご利用いただく場合は、<a href="/lp">『入試正解デジタル for School』</a>の導入をご検討ください。
    `,
    lpFlag: true,
    planFlag: true,
    helpFlag: true,
    isExpanded: false
  },
  {
    id: 'faq-7',
    categoryId: 2,
    title: '過去問の掲載大学は、すべての年度、学部、科目が掲載されているのでしょうか？',
    body: `
      入試正解デジタルは、当社が刊行する「全国大学入試問題正解」の過去問、解答・解説を掲載しているため、すべての年度、学部、科目が掲載されているとは限りません。<br><br>

      各大学の過去問掲載状況は、著作権等の関係で各年度、学部、科目それぞれによって異なる場合がございます。<br>
      特定の入試問題の閲覧を主目的としてプレミアムプランに加入される場合は、ご購入前に当該入試問題の掲載の有無をお調べくださいますようお願いいいたします。<br><br>

      ※関連するFAQ<a data-href="#faq-8">「希望の大学の過去問が掲載されているか調べる方法を教えてください」</a>も合わせてご覧ください。
    `,
    lpFlag: true,
    planFlag: true,
    helpFlag: true,
    isExpanded: false
  },
  {
    id: 'faq-8',
    categoryId: 2,
    title: '希望の大学の過去問が掲載されているか調べる方法を教えてください。',
    body: `
      ご希望の問題が掲載されているかをご確認いただくには、無料会員にご登録の上、<a href="/c/search-univ">「大学から探す」</a>にて該当の大学と年度を選択し、検索してください。検索結果に表示される問題は掲載がございます。<br><br>

      検索しても表示されない問題は、入試正解デジタルでの掲載がありませんので、プレミアム会員になられても閲覧できません。<br><br>

      ※（プレミアム会員限定）の表記がある年度も、問題の検索はできます。プレミアムプランをご購入いただく前に、上記の方法でご希望の問題の掲載有無をご確認ください。
    `,
    lpFlag: true,
    planFlag: true,
    helpFlag: true,
    isExpanded: false
  },
  {
    id: 'faq-9',
    categoryId: 2,
    title: '希望の入試問題が掲載されていません。',
    body: `
      本サービスでは、一般入試の過去問を掲載していますが、全ての入試問題を掲載しているものではございません。また、推薦型・総合型選抜等は掲載していません。<br>
      ご希望の入試問題が掲載されているかどうかは、<a href="/c/search-univ">「大学から探す」</a>より該当の大学 を検索してご確認ください。
    `,
    lpFlag: true,
    planFlag: true,
    helpFlag: true,
    isExpanded: false
  },
  {
    id: 'faq-10',
    categoryId: 2,
    title: '入試の「年度」の意味を教えてください。',
    body: `
      当サービスで表記している入試の「年度」とは、その年度に入学する学生用の入試であることを意味します。<br>
      たとえば2023年度入試とは、2023年4月に入学した学生が受けた試験です。
    `,
    lpFlag: true,
    planFlag: true,
    helpFlag: true,
    isExpanded: false
  },
  {
    id: '',
    categoryId: 2,
    title: '2025年度入試の過去問の搭載時期はいつですか。',
    body: `
      2025年度入試の問題と解答は、2025年12月頃に追加搭載予定です。
    `,
    lpFlag: true,
    planFlag: true,
    helpFlag: true,
    isExpanded: false
  },
  {
    id: 'faq-11',
    categoryId: 3,
    title: 'プレミアム会員になるとどんな問題が利用できるようになりますか。',
    body: `
      無料会員の状態で検索した時に、プレミアム会員限定のマークがついている問題が閲覧できるようになります。<br>
      検索して表示されない問題は、入試正解デジタルでの掲載がありませんので、プレミアム会員になられても閲覧できません。<br>
      プレミアムをご購入いただく前に、ご希望の入試問題が掲載されているかどうかは、<a href="/c/search-univ">「大学から探す」</a>より該当の大学を検索して、掲載有無をご確認ください。<br><br>

      ※関連するFAQ<a data-href="#faq-8">「希望の大学の過去問が掲載されているか調べる方法を教えてください」</a>も合わせてご覧ください。
    `,
    lpFlag: true,
    planFlag: true,
    helpFlag: true,
    isExpanded: false
  },
  {
    id: 'faq-12',
    categoryId: 3,
    title: 'プレミアム会員はいつから使えるようになりますか。',
    body: `
      クレジットカード決済の場合、決済完了後、すぐにご利用ができます。<br><br>

      コンビニ決済の場合、コンビニにてお支払い完了後ご利用いただけます。<br>
      万が一、購入情報が反映されない場合は<a href="/c/payments">「購入履歴と利用期限」</a>をご確認いただくか<br>
      ブラウザの更新を行ってください。
    `,
    lpFlag: true,
    planFlag: true,
    helpFlag: true,
    isExpanded: false
  },
  {
    id: 'faq-13',
    categoryId: 3,
    title: 'プランが複数ありますが、利用できる機能に違いはありますか。',
    body: `
      期間の違いだけで、お使いいただける機能に違いはありません。
    `,
    lpFlag: true,
    planFlag: true,
    helpFlag: true,
    isExpanded: false
  },
  {
    id: 'faq-14',
    categoryId: 3,
    title: 'コンビニ決済の手順を詳しく教えてください。',
    body: `
      入試正解デジタルの購入画面で「コンビニ決済」を選択いただき、メールアドレス、お名前などを入力して「支払う」ボタンをクリックしてください。<br>
      対応する各コンビニの、「支払い情報」と「支払い手順」が表示されますのでご確認の上、コンビニで現金にてお支払いください。<br>
      お支払い完了後に、プレミアム機能の利用が可能になります。<br><br>

      ※万が一、購入情報が反映されない場合は<a href="/c/payments">「購入履歴と利用期限」</a>をご確認いただくかブラウザの更新を行ってください。<br><br>

      ※コンビニ決済の支払期限は7日以内ですので、お忘れのないようにご注意ください。決済期限を過ぎますと自動キャンセルとなり、ご購入いただくことができません。恐れ入りますが、改めて最初から購入をお試しください。<br><br>

      ※現在対応しているコンビニは、日本全国のファミリーマート、ローソン、ミニストップ、セイコーマートです。
    `,
    lpFlag: true,
    planFlag: true,
    helpFlag: true,
    isExpanded: false
  },
  {
    id: 'faq-15',
    categoryId: 3,
    title: 'プレミアムを契約中に、別の有料プランへ変更はできますか。（例えば、6か月プランを契約中に12か月プランへ変更するなど）',
    body: `
      契約期間中に、別のプランに変更はできません。<br>
      契約中のプランが終了後、あらためて別のプランをご購入ください。
    `,
    lpFlag: false,
    planFlag: true,
    helpFlag: true,
    isExpanded: false
  },
  {
    id: 'faq-16',
    categoryId: 3,
    title: 'プレミアムの解約や返金はできますか。',
    body: `
      原則、途中解約による返金はお受けできません。恐れ入りますが、内容を十分ご確認の上、お申し込みいただきますようお願いいたします。<br>
      なお、途中で退会などご利用を停止されても、本サービスの利用にあたりお支払いいただいた料金の返金等はいたしかねますのでご了承ください。
    `,
    lpFlag: true,
    planFlag: true,
    helpFlag: true,
    isExpanded: false
  },
  {
    id: 'faq-17',
    categoryId: 3,
    title: '購入完了後の領収書メールが届きません。',
    body: `
      下記の原因が考えられます。<br><br>

      ・登録の際の入力ミスで、間違ったアドレスを入力した（大文字、小文字など）<br>
      ・メールソフトの迷惑メールフォルダに振り分けられている<br>
      ・メールの管理会社、セキュリティソフト等によって迷惑メールと判断され、受信されない<br><br>

      再度メールの受信をご確認いただき、お手元にない場合は、<a href="<contact-url>" target="_blank">お問い合わせフォーム</a>からご連絡ください。
    `,
    lpFlag: false,
    planFlag: true,
    helpFlag: true,
    isExpanded: false
  },
  {
    id: 'faq-18',
    categoryId: 3,
    title: 'プレミアムの契約状況はどこから確認ができますか。',
    body: `
      ログイン後、画面右上のニックネームを選択し、メニュー一覧の中の「購入履歴と利用期限」からご確認いただけます。
    `,
    lpFlag: false,
    planFlag: true,
    helpFlag: true,
    isExpanded: false
  },
  {
    id: 'faq-19',
    categoryId: 3,
    title: 'プレミアムを退会したいのですが、手続きは必要ですか。',
    body: `
      プレミアムの途中退会はできません。<br>
      プレミアムは利用期間が終了しましたら自動的にプレミアムが終了して無料会員になります。
    `,
    lpFlag: false,
    planFlag: true,
    helpFlag: true,
    isExpanded: false
  },
  {
    id: 'faq-20',
    categoryId: 3,
    title: 'プレミアムの契約が終了すると、マイリストに登録していた過去問はどうなりますか',
    body: `
      マイリストに過去問のリストは残りますが、プレミアムのみが閲覧できる過去問ページの閲覧はできなくなります。
    `,
    lpFlag: false,
    planFlag: false,
    helpFlag: true,
    isExpanded: false
  }
];
// COMMON_ID_FAQS.forEach((faq, index) => (faq.id = `faq-${index + 1}`));
