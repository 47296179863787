<div class="complete-answered-dialog">
  <app-dialog-basic>
    <div class="text-center complete-answered-dialog-inner pb-4">
      <img src="/assets/images/lp/student/logo-manabi-id-2line.svg" alt=" 旺文社 まなびiD" width="70" height="33" />
      <p class="mt-7">
        会員登録は、旺文社のサービス共通で利用できる<br />
        統合IDサービス「旺文社まなびID(無料)」で行います。
      </p>
      <button mat-flat-button class="mt-4 md:mt-6" color="primary" (click)="gotoSearch()">
        新規登録・ログイン
      </button>

      <div class="mt-14">
        <div class="text-center">
          <app-link-with-arrow
            class="[&_.link-with-arrow-label]:text-md [&_.link-with-arrow]:text-left inline-block"
            (click)="close(); gotoTeacherLogin()"
          >
            <span class="text-sm md:text-md"> <span class="font-bold">学校・塾の先生（団体利用）</span>のログインはこちら</span>
          </app-link-with-arrow>
        </div>
      </div>
    </div>
  </app-dialog-basic>
  <app-dialog-basic-footer class="description-premium-dialog-footer">
    <button mat-stroked-button (click)="close()">閉じる</button>
  </app-dialog-basic-footer>
</div>
